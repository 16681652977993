export const Colors = {
    yellow: "#fbba00",
    plumPie: "#741635",
    orange: "#ff5200",
    white: "#ffffff",
    black: "#000000",
    silver: "#bebebe",
    lightGray: "#f2f2f2",
    dimGray: "#707070",
    seaweedGreen: "#62bb25",
    errorRed: "#ff0000",
    red: "#e50000",
    secondary: "#19857b",
    primary: "#556cd6",
};
