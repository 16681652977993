import React, { FunctionComponent, ReactElement } from "react";

const FlyerLinkDescription: FunctionComponent = (): ReactElement => {
    return (
        <p className="mt-2">
            In unserem <strong className="text-orange">Angebotsprospekt</strong> finden Sie
            <br />
            jede Woche eine{" "}
            <strong>
                große Vielfalt an
                <br />
                Produkten
            </strong>
            , mit denen Sie beim
            <br />
            Einkaufen kräftig sparen können!
        </p>
    );
};

export default FlyerLinkDescription;
