import React, { FunctionComponent, ReactElement } from "react";
import PaginationButton from "./PaginationButton";

interface NewsPaginationProps {
    getPrevNews: () => void;
    getNextNews: () => void;
    hasPrevNews: boolean;
    hasNextNews: boolean;
}

const Pagination: FunctionComponent<NewsPaginationProps> = (props): ReactElement => {
    return (
        <div className="row justify-content-center justify-content-lg-end my-3">
            <div className="col-12 col-lg-4 d-flex justify-content-center">
                <PaginationButton enabled={props.hasPrevNews} onClick={props.getPrevNews} buttonDirection="left" />
                <PaginationButton enabled={props.hasNextNews} buttonDirection="right" onClick={props.getNextNews} />
            </div>
        </div>
    );
};

export default Pagination;
