/**
 * Uses the RegExp.test expression to test if the string starts with a number. If this is the case true will be returned.
 * @param str
 * @returns
 */
const startsWithNumber = (str: string): boolean => {
    return /^\d/.test(str);
};

/**
 * Returns the number which is at the beginning of the string. If there is no number null will be returned.
 * @param str
 * @returns
 */
const getStartNumberOfString = (str: string): number => {
    if (startsWithNumber(str)) {
        return Number(str.match(/^\d+/)[0]);
    }
    return null;
};

/**
 * Compare two strings and return -n, 0 or +n where n is an element of real
 * Numbers at the beginning of the string have a higer priority than letters.
 * @param a is a string which will be compared to b
 * @param b
 * @returns Returns a negative value if the first string has a higher priority than the second string which it is compared to.
 *          A positiv value will be returned when the second string has a higer priroty and zero will be returned if the two strings are equal.
 */
export const compareOrderPosition = (a: string, b: string): number => {
    // a and b are have numbers at the beginning
    if (startsWithNumber(a) && startsWithNumber(b)) {
        return getStartNumberOfString(a) - getStartNumberOfString(b);
    }

    // only a has a number at the beginning (a has higher priority)
    if (startsWithNumber(a) && !startsWithNumber(b)) {
        return -1;
    }

    // only b has a number at the beginning (b has higher priority)
    if (!startsWithNumber(a) && startsWithNumber(b)) {
        return 1;
    }
    // If they have no numbers at the beginning the strings will be compared
    return a.localeCompare(b);
};
