import TebonusInfoIcon from "components/_graphics/TebonusInfoIcon";
import React, { FunctionComponent, ReactElement } from "react";
import { Colors } from "styles";

export const EmptyTable: FunctionComponent = (): ReactElement => {
    return (
        <div className="col-11 col-md-12 col-lg-10 col-xl-8 mt-5">
            <div className="d-flex align-items-center font-subheading-regular font-dimmGray text-focus-in">
                <TebonusInfoIcon className="mr-2" htmlColor={Colors.orange} fill={Colors.orange} stroke={Colors.orange} />
                Schade, Sie haben in diesem Monat keine Punkte gesammelt!
            </div>
            <div className="d-flex align-items-center">
                <p className="font-table-heading font-silver text-focus-in">
                    Scannen Sie an der Kasse einfach die digitale tebonus Karte der{" "}
                    <a
                        href="https://www.tegut.com/tebonus.html#c78294"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="link-orange-underlined"
                    >
                        tebonus App
                    </a>{" "}
                    oder Ihre Karte aus dem Starterpaket, um ganz einfach Punkte zu sammeln, einzulösen und zu sparen.
                </p>
            </div>
        </div>
    );
};
