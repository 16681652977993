import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import React, { FunctionComponent, ReactElement } from "react";
import { LockCardModal } from "./LockCardModal";
import { OrderNewCardModal } from "./OrderNewCardModal";

export enum OpenModal {
    NONE,
    ORDER_NEW_CARD,
    LOCK_CARD,
}

interface PhysicalCardModalsProps {
    openModal: OpenModal;
    isLoading: boolean;
    isCardStillActive: boolean;
    lockOrderPhysicalCard: (withNewCard: boolean) => void;
    closeModal: () => void;
}

export const PhysicalCardModals: FunctionComponent<PhysicalCardModalsProps> = (props): ReactElement => {
    function getOrderNewCardBodyText(): string {
        return props.isCardStillActive
            ? "Durch die Bestellung einer neuen tebonus Karte wird Ihre alte Karte automatisch gesperrt."
            : "Durch die Bestellung einer neuen tebonus Karte erhalten Sie eine Karte auf dem Postweg. Sobald Sie die Karte erhalten haben, können Sie wieder Punkte sammeln.";
    }

    switch (props.openModal) {
        case OpenModal.ORDER_NEW_CARD:
            return (
                <OrderNewCardModal
                    bodyText={getOrderNewCardBodyText()}
                    orderPhysicalCard={() => props.lockOrderPhysicalCard(true)}
                    closeModal={props.closeModal}
                />
            );
        case OpenModal.LOCK_CARD:
            return <LockCardModal lockPhysicalCard={() => props.lockOrderPhysicalCard(false)} closeModal={props.closeModal} />;
        case OpenModal.NONE:
            return props.isLoading && <ModalConfirmInfo showLoadingIndicator={true} />;
        default:
            return null;
    }
};
