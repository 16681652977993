import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { CommonButtonTitles, logAnalyticsSelectContent } from "utils/FirebaseAnalytics";
import { NewsSlide } from "types";
import DashboardNewsItem from "components/News/DashboardNewsItem";
import ContentPanel from "components/News/ContentPanel";
import { AppState } from "../../store/configureStore";
import { DataCarousel, DataCarouselProps } from "../../components/Shared/DataCarousel";
import { TebonusArrowSize, TebonusArrowType } from "../../components/_graphics";
import FlyerContentBanner from "../../assets/images/Angebote_Content.png";
import FlyerLinkDescription from "../../components/News/FlyerLinkDescription";

const NewsDashboard: FunctionComponent = (): ReactElement => {
    const INITIAL_NEWS_SLIDER_POSITION = 1;

    const newsState = useSelector((state: AppState) => state.newsReducer);
    const newsSlider = React.createRef<Slider>();
    const [slides, setSlides] = useState<NewsSlide[]>([]);
    const [positionState, setPositionState] = useState<number>(undefined);

    useEffect(() => {
        initSlides();
    }, []);

    useEffect(() => {
        initSlides();
    }, [newsState.isLoading]);

    useEffect(() => {
        if (positionState) {
            const newsTitle =
                positionState !== INITIAL_NEWS_SLIDER_POSITION
                    ? newsState.news[positionState - INITIAL_NEWS_SLIDER_POSITION - 1].title
                    : "tegut... Angebote der Woche";

            logAnalyticsSelectContent({
                content_type: CommonButtonTitles.news.newsSlide,
                item_id: newsTitle,
                position: positionState,
            });
        }
    }, [positionState]);

    const initSlides = () => {
        setSlides([
            {
                contentPanel: {
                    link: "https://www.tegut.com/angebote-produkte/angebote.html?utm_source=tbapp&utm_medium=banner&utm_campaign=&utm_term=Flugblatt&utm_content=#c66663",
                    title: "Jede Woche aufs Neue sparen!",
                    subHeader: <FlyerLinkDescription />,
                    image: FlyerContentBanner,
                },
            },
            ...newsState.news.map(n => {
                return { news: n };
            }),
        ]);
    };

    const getPrevSliderPosition = () => {
        return positionState === undefined || positionState <= INITIAL_NEWS_SLIDER_POSITION ? slides.length + 1 : positionState;
    };

    const getNextSliderPosition = () => {
        if (positionState === undefined) {
            return INITIAL_NEWS_SLIDER_POSITION;
        } else if (positionState >= slides.length) {
            return INITIAL_NEWS_SLIDER_POSITION - 1;
        }

        return positionState;
    };

    // properties of the carousel
    const carouselProps = {
        arrows: false,
        infinity: true,
        customArrows: true,
        customArrowsProps: {
            arrowType: TebonusArrowType.ROUND,
            arrowSize: TebonusArrowSize.SMALL,
            className: "icon-accent-wrapper icon-accent-orange",
            prevSliderRequested() {
                newsSlider?.current?.slickPrev();
                const currentPosition = getPrevSliderPosition();
                setPositionState(currentPosition - 1);
            },
            nextSliderRequested() {
                newsSlider?.current?.slickNext();
                const currentPosition = getNextSliderPosition();
                setPositionState(currentPosition + 1);
            },
        },
        arrowsOutside: false,
        className: "w-100",
        slidesToScroll: 1,
        slidesToShowAllBreakpoints: 1,
        elementNumber: slides.length,
        tabSupportIdentifier: "news-slide-",
    } as DataCarouselProps;

    return (
        <div className="h-100">
            {/* Only show news if there are any slides */}
            {slides && (
                <DataCarousel ref={newsSlider} {...carouselProps}>
                    {slides.map((item, index) => {
                        return item.news ? (
                            <DashboardNewsItem news={item.news} slideIndex={index} slides={slides} />
                        ) : (
                            item.contentPanel && <ContentPanel slideIndex={index} contentPanel={item.contentPanel} />
                        );
                    })}
                </DataCarousel>
            )}
        </div>
    );
};

export default NewsDashboard;
