import { Reducer } from "redux";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ArticleCategory, AxiosRequestError, BaseType, ErrorStatusCode, ReduxBaseType } from "types";
import RequestStatus from "utils/RequestStatus";
import { getAppSetting } from "utils/AppSettings";

const REACT_APP_DATASERVICE_URL = getAppSetting("REACT_APP_DATASERVICE_URL");

export const GET_ARTICLE_CATEGORIES = "tebonus/articles/GET_ARTICLE_CATEGORIES";
export const GET_ARTICLE_CATEGORIES_SUCCESS = "tebonus/articles/GET_ARTICLE_CATEGORIES_SUCCESS";
export const GET_ARTICLE_CATEGORIES_FAIL = "tebonus/articles/GET_ARTICLE_CATEGORIES_FAIL";

interface GetArticleResponse {
    categories: ArticleCategory[];
    lastUpdate: number;
}

interface GetArticleCategoriesAction {
    type: typeof GET_ARTICLE_CATEGORIES;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetArticleCategoriesSuccessAction {
    type: typeof GET_ARTICLE_CATEGORIES_SUCCESS;
    payload: AxiosResponse<GetArticleResponse>;
}

interface GetArticleCategoriesFailAction {
    type: typeof GET_ARTICLE_CATEGORIES_FAIL;
    error: AxiosRequestError;
}

export interface ArticleCategoriesState extends BaseType, ReduxBaseType {
    categories: ArticleCategory[];
    lastUpdate: number;
}

export type KnownAction = GetArticleCategoriesAction | GetArticleCategoriesSuccessAction | GetArticleCategoriesFailAction;

export type ErrorAction = GetArticleCategoriesFailAction;

const INITIAL_STATE: ArticleCategoriesState = {
    categories: [],
    lastUpdate: 0,
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
};

export const reducer: Reducer<ArticleCategoriesState> = (
    state = INITIAL_STATE,
    action: KnownAction
): ArticleCategoriesState => {
    switch (action.type) {
        case GET_ARTICLE_CATEGORIES: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
            };
        }

        case GET_ARTICLE_CATEGORIES_SUCCESS: {
            const { categories } = action.payload.data;

            return {
                ...state,
                isLoading: false,
                categories,
                lastUpdate: Date.now(),
                errorCode: ErrorStatusCode.noError,
            };
        }

        case GET_ARTICLE_CATEGORIES_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
            };
        }

        default:
            return state;
    }
};

export const actionCreators = {
    getArticleCategories: (): GetArticleCategoriesAction =>
        <GetArticleCategoriesAction>{
            type: GET_ARTICLE_CATEGORIES,
            payload: {
                request: {
                    method: "GET",
                    url: `${REACT_APP_DATASERVICE_URL}api/GetOnlineArticleCategories/true`,
                },
            },
        },
};

export type ArticleCategoryActions = typeof actionCreators;
