import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import React, { FunctionComponent, ReactElement } from "react";

interface LoadingModalProps {
    bodyText?: string;
}

const LoadingModal: FunctionComponent<LoadingModalProps> = (props): ReactElement => {
    return <ModalConfirmInfo bodyText={props.bodyText} showLoadingIndicator={true} />;
};

export default LoadingModal;
