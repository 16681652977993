import { BasicQueryStringUtils, LocationLike, StringMap } from "@openid/appauth";

/**
 *  Class represents URL Parameter Parser to get query params
 * @category HashQueryStringUtils
 * @see{@link https://dev.to/kdhttps/appauth-js-integration-in-react-1m3e}
 */
export class NoHashQueryStringUtils extends BasicQueryStringUtils {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parse(input: LocationLike, _useHash: boolean | undefined): StringMap {
        return super.parse(input, false /*never use hash*/);
    }
}
