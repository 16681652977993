import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { ActivePromotionList, Promotion } from "types";
import { filterActiveAndActivatedPromotions } from "store/Promotions";
import { DataCarousel, DataCarouselProps } from "components/Shared/DataCarousel";
import { TebonusArrowSize, TebonusArrowType } from "components/_graphics";
import Slider from "react-slick";
import DashboardProductTile, { ProductStatus, TilePlacement } from "../../Shared/DashboardProductTile";

interface ProductTilesProps {
    arePromotionsLocked: boolean;
    isLoading: boolean;
    monthPromotions: Promotion[];
}

export const PromotionProductCarousel: FunctionComponent<ProductTilesProps> = (props): ReactElement => {
    const NUMBER_EMPTY_TILES = 7;
    const promotionsSlider = React.createRef<Slider>();

    const [filteredMonthPromotions, setFilteredMonthPromotions] = useState<ActivePromotionList>();

    const carouselSettings = {
        arrows: false,
        infinity: true,
        animationSpeed: 600,
        customArrows: true,
        slidesToShowAllBreakpoints: 3,
        customArrowsProps: {
            arrowType: TebonusArrowType.ROUND,
            arrowSize: TebonusArrowSize.MEDIUM,
            className: "icon-accent-wrapper icon-accent-orange",
            prevSliderRequested() {
                promotionsSlider?.current?.slickPrev();
            },
            nextSliderRequested() {
                promotionsSlider?.current?.slickNext();
            },
        },
        elementNumber: filteredMonthPromotions?.promotions.length ?? 0,
    } as DataCarouselProps;

    useEffect(() => {
        setFilteredMonthPromotions(filterActiveAndActivatedPromotions(props.monthPromotions));
    }, [props.monthPromotions]);

    return (
        <div className="row align-items-center my-auto pt-0 h-100 px-2">
            {/* if the promotions are locked the arrows on the side are disabled */}
            <div className="my-auto col-12">
                <DataCarousel ref={promotionsSlider} {...carouselSettings} customArrows={props.arePromotionsLocked}>
                    {props.arePromotionsLocked
                        ? filteredMonthPromotions &&
                          filteredMonthPromotions.promotions.map(item => (
                              <div className="my-4 d-flex justify-content-center" key={item.tegutPromotionIdentifier}>
                                  <DashboardProductTile
                                      tilePlacement={TilePlacement.dashboardPromotions}
                                      title={item.promotionTitle}
                                      image={item.image}
                                      advantageAmount={item.advantageAmount}
                                      advantage={item.advantage}
                                      productStatus={ProductStatus.isActivated}
                                  />
                              </div>
                          ))
                        : Array.from(Array(NUMBER_EMPTY_TILES).keys()).map(item => (
                              <div className="my-4 d-flex justify-content-center" key={item}>
                                  <DashboardProductTile
                                      tilePlacement={TilePlacement.dashboardPromotions}
                                      productStatus={props.isLoading ? ProductStatus.isLoading : ProductStatus.isEmpty}
                                  />
                              </div>
                          ))}
                </DataCarousel>
            </div>
        </div>
    );
};
