import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import React, { FunctionComponent, ReactElement } from "react";

interface OrderNewCardModalProps {
    bodyText: string;
    orderPhysicalCard: () => void;
    closeModal: () => void;
}

export const OrderNewCardModal: FunctionComponent<OrderNewCardModalProps> = (props): ReactElement => {
    return (
        <ModalConfirmInfo
            titleText="Neue Karte bestellen"
            bodyText={props.bodyText}
            button1Text="Karte bestellen"
            button1function={props.orderPhysicalCard}
            button2Text="Abbrechen"
            button2function={props.closeModal}
        />
    );
};
