import React, { FunctionComponent, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { CommonButtonTitles } from "utils/FirebaseAnalytics";
import AnalyzeOptInModal from "components/AnalyzeOptInModal";
import { Article, ErrorStatusCode } from "../../../types";
import { ArticleMultiplierState, INITIAL_STATE } from "../../../store/ArticleMultipliers";
import ModalConfirmInfo from "../../_overlays/ConfirmationModal";
import LeavePageModal from "../../LeavePageModal";
import { MultiplierStatus } from "../MultiplierTile";
import SaveMultipliersModal from "./SaveMultipliersModal";
import LoadingModal from "./LoadingModal";
import ConnectionErrorModal from "./ConnectionErrorModal";

export enum ModalTypes {
    NONE,
    CONFIRM,
    RETRYCONFIRMATION,
    ERROR,
}

interface ModalProps {
    currentArticleMultipliers: ArticleMultiplierState;
    openModal: ModalTypes;
    setOpenModal: (modalType: ModalTypes) => void;
    selectedProductsAmount: number;
    multiplierStatus: MultiplierStatus;
    emptySlotsAmount: number;
    saveArticleMultipliers: () => void;
    changeArticleMultipliers: (modalType: ModalTypes) => void;
    articleMultipliers?: Article[];
    isCheckingInternetConnection: boolean;
    isAnalyzeOptInVisible: boolean;
    hasOptInError: boolean;
    errorRetryButtonFunction: () => void;
    errorCancelButtonFunction: () => void;
    handleOptInAgreement: () => void;
    setHasOptInError: (hasError: boolean) => void;
    hideOptInModal: () => void;
}

const MultiplierModals: FunctionComponent<ModalProps> = props => {
    const [prevMultipliers, setPrevMultipliers] = useState<ArticleMultiplierState>(INITIAL_STATE);

    useEffect(() => {
        checkWhichModalShouldBeOpened(props.currentArticleMultipliers);
        setPrevMultipliers(props.currentArticleMultipliers);
    }, [props.currentArticleMultipliers]);

    const location = useLocation();

    function hasUserUnsavedChanges(): boolean {
        if (props.multiplierStatus === MultiplierStatus.INITIAL && props.selectedProductsAmount !== 0) {
            return true;
        }
        if (props.multiplierStatus === MultiplierStatus.ENABLED && props.selectedProductsAmount > 0) {
            return true;
        }
        return false;
    }

    function shouldLeavePageModalBeShown(): boolean {
        return hasUserUnsavedChanges() && location.pathname.toLowerCase() !== "/ArticleFinder".toLowerCase();
    }

    // when multipliers are setting
    function checkWhichModalShouldBeOpened(articleMultipliers: ArticleMultiplierState): void {
        // case: multipliers successfully set and error while setting multipliers
        if (prevMultipliers.isSettingMultiplier && !articleMultipliers.isSettingMultiplier) {
            if (articleMultipliers.errorCode === ErrorStatusCode.noError) {
                props.setOpenModal(ModalTypes.NONE);
                return;
            }

            // error occured in saving the promotions
            props.setOpenModal(ModalTypes.RETRYCONFIRMATION);
        }
    }

    function isLoadingModalVisible(): boolean {
        return (
            props.currentArticleMultipliers.isSettingMultiplier ||
            props.currentArticleMultipliers.isLoading ||
            props.isCheckingInternetConnection
        );
    }

    function getLoadingModalText(): string {
        if (props.currentArticleMultipliers.isSettingMultiplier) {
            return "Ihre Lieblingsprodukte werden gespeichert.";
        }

        if (props.currentArticleMultipliers.isLoading) {
            return "Ihre Lieblingsprodukte werden geladen.";
        }

        return undefined;
    }

    return (
        <div>
            <LeavePageModal
                shownWhen={shouldLeavePageModalBeShown()}
                discardButtonTitle={CommonButtonTitles.multiplier.discardMultiplier}
                returnButtonTitle={CommonButtonTitles.multiplier.returnToMultiplier}
                ignoringMessagePath="/ArticleFinder"
            />
            {/* info modal when user wants to select article multipliers (button click)  */}
            {props.openModal === ModalTypes.CONFIRM && (
                <SaveMultipliersModal
                    title={`Fast fertig! Bitte prüfen Sie Ihre Auswahl für den ${dayjs().format("MMMM")}:`}
                    subtitle={`Ihre Auswahl können Sie am 1. ${dayjs().add(1, "months").format("MMMM")} wieder anpassen.`}
                    buttonPositiveText="Auswahl speichern"
                    buttonNegativeText="Auswahl ändern"
                    onButtonPositiveClick={() => props.saveArticleMultipliers()}
                    onButtonNegativeClick={() => props.changeArticleMultipliers(ModalTypes.NONE)}
                    selectedMultipliers={props.articleMultipliers}
                />
            )}
            {/* retry saving article multipliers */}
            {props.openModal === ModalTypes.RETRYCONFIRMATION && (
                <ModalConfirmInfo
                    titleText="Etwas ist schiefgelaufen"
                    bodyText="Bei ihrer Aktion ist leider ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
                    button1Text="Erneut versuchen"
                    button1function={() => {
                        props.saveArticleMultipliers();
                    }}
                    button2Text="Abbrechen"
                    button2function={() => props.setOpenModal(ModalTypes.NONE)}
                />
            )}
            {props.hasOptInError && (
                <ConnectionErrorModal
                    retryButtonFunction={props.errorRetryButtonFunction}
                    cancelButtonFunction={props.errorCancelButtonFunction}
                />
            )}
            {props.isAnalyzeOptInVisible && (
                <AnalyzeOptInModal
                    key="analysisOptInModal"
                    handleAgreement={props.handleOptInAgreement}
                    setHasOptInError={props.setHasOptInError}
                    onCloseButtonClick={props.hideOptInModal}
                />
            )}
            {isLoadingModalVisible() && <LoadingModal bodyText={getLoadingModalText()} />}
        </div>
    );
};

export default MultiplierModals;
