import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

/**
 * Scrolls to the top of the page every time the path changes (on mobile view).
 *
 */
const ScrollToTop: React.FC = () => {
    if (!isMobile) {
        return null;
    }

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    }, [pathname]);

    return null;
};

export default ScrollToTop;
