import React, { FunctionComponent, ReactElement, useState } from "react";

import CheckIcon from "@material-ui/icons/Check";
import CloseSharp from "@material-ui/icons/CloseSharp";
import EditIcon from "@material-ui/icons/Edit";
import { TebonusInfoIcon, TebonusSingleHeart } from "../_graphics";
import { TegutBadge } from "../_tegut/TegutBadge";
import { Colors } from "../../styles";
import defaultProduct from "../../assets/icons/products/default-camera-icon.svg";

export enum MultiplierStatus {
    INITIAL,
    ENABLED,
    LOCKED,
    EMPTY,
}

interface MultiplierTileProps {
    multiplierStatus: MultiplierStatus;
    isOnDetailsPage: boolean;
    imagePath?: string;
    advantageAmount?: number;
    title?: string;
    description?: string;
    openArticleFinder?: () => void;
    hasTileSelectedItem?: boolean;
}

const MultiplierTile: FunctionComponent<MultiplierTileProps> = props => {
    const TILE_STYLE = props.isOnDetailsPage ? "multiplierDetailsTile" : "emptyMultipierTileDashboard";
    const CHECK_BADGE_STYLE = props.isOnDetailsPage ? "badge-check-detail-fav" : "badge-check-dashboard-fav";
    const CHECK_ICON_STYLE = props.isOnDetailsPage ? "check-icon-detail-fav" : "check-icon-fav";
    const MULTIPLYER_BADGE_STYLE = props.isOnDetailsPage ? "badge-multiplier-details" : "badge-multiplier-dashboard";
    const MULTIPLYER_BADGE_POINTS = props.isOnDetailsPage
        ? "badge-advantageAmount-points-detail-fav"
        : "badge-advantageAmount-points-dashboard-fav";
    const MULTIPLYER_BADGE_TEXT = props.isOnDetailsPage
        ? "badge-advantageAmount-text-detail-fav"
        : "badge-advantageAmount-text-dashboard-fav";
    const IMAGE_SIZE = props.isOnDetailsPage ? "img-product-detail-fav" : "img-product-fav";
    const EMPTY_BADGE_STYLE = props.isOnDetailsPage ? "img-empty-badge-detail-fav" : "img-empty-badge-fav";

    const [showBack, setShowBack] = useState(false);

    const handleClicked = () => {
        return setShowBack(!showBack);
    };

    function getBadgeText() {
        return <>{props.advantageAmount}×</>;
    }

    function getMultiplierDetailBadge(): ReactElement {
        return (
            <TegutBadge className={MULTIPLYER_BADGE_STYLE} bgColor={Colors.plumPie}>
                <div>
                    <div className={MULTIPLYER_BADGE_POINTS}>{getBadgeText()}</div>
                    <div className={MULTIPLYER_BADGE_TEXT}>Punkte</div>
                </div>
            </TegutBadge>
        );
    }
    /**
     * Determines whether an orange icon for editing the article multipliers shall be displayed.
     * This is the case if and only if the user is on the details page and the article multiplilers
     * are editable / selectable.
     * @returns true if an orange icono shall be rendered, false otherwise
     */
    function shouldShowOrangeBadge() {
        return (
            props.isOnDetailsPage &&
            (props.multiplierStatus === MultiplierStatus.ENABLED || props.multiplierStatus === MultiplierStatus.INITIAL)
        );
    }

    function showEmptyMultiplierTile() {
        return (
            <div className={props.isOnDetailsPage ? "emptyMultiplierTileDetails" : "emptyMultipierTileDashboard"}>
                <div className="d-flex justify-content-center align-items-center h-100">
                    <TegutBadge className={EMPTY_BADGE_STYLE} bgColor={Colors.white}>
                        <TebonusSingleHeart preserveAspectRatio={true} color={Colors.lightGray} />
                    </TegutBadge>
                </div>
            </div>
        );
    }

    function showMultiplierTile() {
        return (
            <div className={`flip-card ${TILE_STYLE} ${showBack && "showBack"}`}>
                <div className="card card-front">
                    {/* green check mark icon, shown when multipliers are locked */}
                    <div className="d-flex justify-content-end">
                        {/* badge with green checkmark, is only shown when the multiplliers are selected and saved */}
                        {props.multiplierStatus === MultiplierStatus.LOCKED && (
                            <TegutBadge className={`${CHECK_BADGE_STYLE} tile-inactive`} bgColor={`${Colors.seaweedGreen}`}>
                                <CheckIcon className={CHECK_ICON_STYLE} />
                            </TegutBadge>
                        )}

                        {/* orange icons (pencil or plus) */}
                        {shouldShowOrangeBadge() && (
                            <button className="btn-badge-select" onClick={() => props.openArticleFinder()}>
                                <TegutBadge className="badge-check-detail-fav" bgColor={Colors.orange} aria-label="Anpassen">
                                    {props.multiplierStatus === MultiplierStatus.ENABLED ||
                                    (props.multiplierStatus === MultiplierStatus.INITIAL && props.hasTileSelectedItem) ? (
                                        <EditIcon className={CHECK_ICON_STYLE} />
                                    ) : (
                                        <span className={CHECK_ICON_STYLE}>+</span>
                                    )}
                                </TegutBadge>
                            </button>
                        )}
                    </div>

                    {/* product image */}
                    {/* if a product image is available, it is displayed, otherwise the heart
                        icon is displayed (this is for example the case when the user has never
                        selected multipliers before) */}
                    <div
                        className={`d-flex align-items-center justify-content-center parent-overlapping ${IMAGE_SIZE}
                        ${props.multiplierStatus !== MultiplierStatus.LOCKED && "cursor-pointer"}`}
                        onClick={
                            props.multiplierStatus !== MultiplierStatus.LOCKED ? () => props.openArticleFinder() : undefined
                        }
                        aria-label="Produktbild"
                    >
                        {props.imagePath ? (
                            <img
                                alt={props.title ? props.title : "Produktbild"}
                                src={props.imagePath}
                                onError={({ currentTarget }) => {
                                    currentTarget.src = defaultProduct;
                                }}
                                width="100%"
                                height="100%"
                                style={{ objectFit: "contain" }}
                                loading="lazy"
                            />
                        ) : (
                            <TegutBadge className={EMPTY_BADGE_STYLE} bgColor={Colors.white}>
                                <TebonusSingleHeart preserveAspectRatio={true} color={Colors.lightGray} />
                            </TegutBadge>
                        )}
                        {/* points badge */}
                        {getMultiplierDetailBadge()}
                    </div>
                    {props.isOnDetailsPage && (
                        <div className="mt-2">
                            <div className="promotion-title truncate-text text-left">{!!props.title && props.title}</div>

                            {/* Info Button */}
                            <div className="info-btn">
                                <button
                                    className="btn-badge-select"
                                    onClick={handleClicked}
                                    hidden={!props.description}
                                    aria-label="Mehr Informationen"
                                >
                                    <TebonusInfoIcon htmlColor={Colors.orange} fontSize="small" />
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                <div className="card card-back">
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn-badge-select"
                            onClick={handleClicked}
                            aria-label="Schließen"
                            tabIndex={showBack ? 0 : -1}
                        >
                            <CloseSharp className="icon-accent-wrapper icon-accent-orange"></CloseSharp>
                        </button>
                    </div>
                    <div className="promotion-title text-left w-100 table-scroll-bar">
                        {!!props.description && props.description}
                    </div>
                </div>
            </div>
        );
    }

    return props.multiplierStatus === MultiplierStatus.EMPTY ? showEmptyMultiplierTile() : showMultiplierTile();
};

export default MultiplierTile;
