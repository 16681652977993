import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const TebonusInfoIcon: React.FC<SvgIconProps> = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 42 42" className="svg-info-icon">
            {/* filled circle path for hovered InfoIcon from styleguide */}
            <path
                d="M18.311,42a16.652,16.652,0,0,1-9.981-2.91C4.28,36.253,1.93,31.9.708,24.977L.571,24.2C-.65,17.279.07,12.384,2.909,8.333S10.1,1.927,17.023.707L17.8.569c6.922-1.22,11.821-.5,15.871,2.339s6.4,7.189,7.625,14.114l.138.772c1.222,6.929.5,11.823-2.339,15.875s-7.183,6.4-14.113,7.623l-.774.136"
                transform="translate(0 0.001)"
                className="svg-info-icon-base"
            />
            <path
                d="M18.311,42a16.652,16.652,0,0,1-9.981-2.91C4.28,36.253,1.93,31.9.708,24.977L.571,24.2C-.65,17.279.07,12.384,2.909,8.333S10.1,1.927,17.023.707L17.8.569c6.922-1.22,11.821-.5,15.871,2.339s6.4,7.189,7.625,14.114l.138.772c1.222,6.929.5,11.823-2.339,15.875s-7.183,6.4-14.113,7.623l-.774.136a34.084,34.084,0,0,1-5.892.57M18.148,2.567l-.772.136C11.023,3.823,7.073,5.918,4.569,9.5s-3.122,8-2,14.356l.135.774c1.122,6.352,3.216,10.3,6.792,12.8s8,3.123,14.357,2l.772-.136c6.358-1.123,10.307-3.216,12.807-6.789s3.123-8,2-14.359l-.138-.772c-1.12-6.354-3.214-10.3-6.791-12.807s-8-3.124-14.357-2"
                transform="translate(0 0)"
            />
            <path
                d="M15.573,25.883a2.044,2.044,0,0,1-2.058-2.07,2.063,2.063,0,0,1,.6-1.472,2.124,2.124,0,0,1,2.942.018,2.07,2.07,0,0,1-1.482,3.524m0-2.7a.685.685,0,0,0-.461.207.588.588,0,0,0,.005.857.6.6,0,0,0,.9.011.658.658,0,0,0,.2-.442.665.665,0,0,0-.191-.438.682.682,0,0,0-.454-.195"
                transform="translate(5.427 -12.625)"
                strokeWidth="1"
                className="svg-info-icon-stroke"
            />
            <path
                d="M611.911,191.5v15.4"
                transform="translate(-590.91 -172.581)"
                stroke={props.htmlColor}
                strokeLinecap="round"
                strokeWidth="2"
                className="svg-info-icon-stroke"
            />
        </SvgIcon>
    );
};

export default TebonusInfoIcon;
