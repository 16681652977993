import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { TegutBadge } from "components/_tegut/TegutBadge";
import CheckIcon from "@material-ui/icons/Check";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseSharp from "@material-ui/icons/CloseSharp";
import { Colors } from "styles";
import { SearchProps } from "types";

interface DropDownMenuProps {
    title: string;
    options: SearchProps[];
    selectedItems: SearchProps[];
    onOpen?: () => void;
    onClose?: () => void;
    setSelectedItems: (value: SearchProps[]) => void;
    // number of height/ width or css string
    height: number | string;
    width: number | string;
}

/**
 * Displays a drop down menu with the tegut style.
 * It needs bootstrap to work.
 * @param props
 * @returns
 */
export const DropDownMenu: FunctionComponent<DropDownMenuProps> = props => {
    const [isExpanded, setIsExpanded] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleMouseEvent);
        document.addEventListener("wheel", handleMouseEvent);

        return () => {
            document.removeEventListener("mousedown", handleMouseEvent);
            document.removeEventListener("wheel", handleMouseEvent);
        };
    }, []);

    const handleMouseEvent = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsExpanded(false);
        }
    };

    const setIsDropDownExpanded = (): void => {
        setIsExpanded(!isExpanded);
        props.onOpen();
    };

    /**
     * Reset the list with the checked items
     */
    const removeAllSelectedItems = () => {
        if (props.selectedItems.length > 0) {
            props.setSelectedItems([]);
        }

        props.onClose();
    };

    /**
     * Add or remove the item from the list depending on if it is selected or not
     * @param index
     */
    const updateSelectedItems = (item: SearchProps) => {
        if (isChecked(item)) {
            // remove item from list
            props.setSelectedItems(props.selectedItems.filter(i => i.id !== item.id));
        } else {
            // add item to list
            props.setSelectedItems([...props.selectedItems, item]);
        }
    };

    /**
     * Check if the item was selected in the drop down menu
     * @param item
     * @returns
     */
    const isChecked = (item: SearchProps): boolean => {
        return props.selectedItems.find(i => i.id === item.id) ? true : false;
    };

    return (
        <div
            ref={ref}
            className="d-flex justify-content-center align-items-start"
            style={{ position: "relative", width: props.width, height: props.height }}
        >
            <div className="d-flex flex-column align-items-start dropDownContainer" style={{ position: "absolute", zIndex: 5 }}>
                <div
                    className="d-flex align-items-center cursor-pointer w-100 px-1"
                    onClick={() => setIsDropDownExpanded()}
                    style={{ height: props.height }}
                    aria-label={isExpanded ? "Schließen" : "Erweitern"}
                >
                    <div className={`px-2 font-unitText-Bold ${isExpanded && "font-orange"}`}>{props.title}</div>
                    <button className="ml-auto btn p-0" aria-label="Erweitern">
                        <ExpandMoreIcon className="icon-accent-wrapper icon-accent-orange" />
                    </button>
                </div>

                {/* Expanded conntent */}
                <div
                    className={`px-1 ${isExpanded ? "d-flex" : "d-none"} flex-column table-scroll-bar overflow-auto w-100`}
                    style={{ maxHeight: "50vh" }}
                >
                    {/* Reset items */}
                    <button className="d-flex my-1 btn p-0 w-100 align-items-center" onClick={() => removeAllSelectedItems()}>
                        <div className="dropDownIconSpace">
                            <CloseSharp style={{ color: Colors.dimGray, fontSize: "1.25rem" }}></CloseSharp>
                        </div>
                        <div className="dropDownText">zurücksetzen</div>
                    </button>

                    {/* Options */}
                    {props.options.map((option, index) => {
                        return (
                            <button
                                key={index}
                                className="btn p-0 d-flex mb-1 w-100 align-items-center"
                                onClick={() => updateSelectedItems(option)}
                            >
                                <div className="dropDownIconSpace">
                                    {isChecked(option) ? (
                                        <TegutBadge className="dropDownBadge" bgColor={`${Colors.seaweedGreen}`}>
                                            <CheckIcon className="dropDownBadgeIcon" style={{ color: Colors.white }} />
                                        </TegutBadge>
                                    ) : (
                                        <TegutBadge className="dropDownBadge" bgColor={`${Colors.white}`}></TegutBadge>
                                    )}
                                </div>
                                <div className="dropDownText">{option.name}</div>
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default DropDownMenu;
