import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import React, { FunctionComponent, ReactElement } from "react";
import { CustomerScoreTransaction, IncentiveType } from "types";
import { useAppSelector } from "utils/hooks";
import { MarketState } from "store/Markets";
import { AppState } from "store/configureStore";
import dayjs from "dayjs";
import { ExpandedPurchaseDesktop } from "./ExpandedPurchaseDesktop";
import { ExpandedPurchaseMobile } from "./ExpandedPurchaseMobile";

interface ScoreTransactionProps {
    key: number;
    isColumnOpened: boolean;
    transaction: CustomerScoreTransaction;
    updateOpenedColsList: () => void;
}

export const ScoreTransaction: FunctionComponent<ScoreTransactionProps> = (props): ReactElement => {
    const EXCEPTIONS_TEXT = `* Vorteile werden nicht gewährt für den Kauf von Tabakwaren, Verlagserzeugnissen,
    Handyguthaben, Geschenkgutscheinen, sonstigen Wertkarten, Tchibo-Artikeln, Lotterie-Produkten sowie auf Postdienstleistungen,
    Pfandbeträge und alle weiteren entsprechend gekennzeichneten Leistungen.`;

    const marketState: MarketState = useAppSelector((state: AppState) => state.marketsReducer);

    const getCalculatedScore = (): number => {
        return props.transaction.totalPointsAfterTransaction - props.transaction.totalPointsBeforeTransaction;
    };

    const getScoreString = (): string => {
        const calculatedScore = getCalculatedScore();
        if (calculatedScore > 0) {
            return `+ ${calculatedScore}`;
        } else if (calculatedScore < 0) {
            return `- ${Math.abs(calculatedScore)}`;
        }

        return `${calculatedScore}`;
    };

    const isTransactionDirectBenefit = (): boolean => {
        return (
            props.transaction.incentiveDetails.length === 1 &&
            props.transaction.basePointsEarned === 0 &&
            props.transaction.incentiveDetails.some(x => IncentiveType[x.incentiveEarnType] === IncentiveType.direct_benefit)
        );
    };

    /**
     * Returns the the transaction time in the correct format depending on the screen size and if the column is opened
     * @param transactionDateUtc
     * @param isColumnOpened
     * @param isMobile
     * @returns
     */
    const getTransactionDateTime = (isColumnOpened: boolean, isMobile: boolean): string => {
        const { transactionDateUtc } = props.transaction;
        if (isColumnOpened) {
            return `${dayjs(transactionDateUtc)?.format("HH:mm")} Uhr`;
        }
        if (isMobile) {
            return dayjs(transactionDateUtc)?.format("DD.MM.YYYY");
        }
        // desktop
        return dayjs(transactionDateUtc)?.format("DD. MMMM YYYY");
    };

    /**
     * Find the name of the store in the markets state and return it.
     * If no store could be found null will be returned.
     * @param storeToFind
     * @returns the name of the store or null if it could not find any.
     */
    const getStoreName = (): string => {
        if (props.transaction.store == null) {
            return null;
        }
        const filteredMarket = marketState.storeList.find(market => {
            return market.tegutStoreIdentifier === props.transaction.store;
        });

        return filteredMarket?.name;
    };

    return (
        <tbody key={props.key} className="font-table-body slide-row-left">
            <tr
                className={`${props.isColumnOpened ? "bg-transparentGray " : ""}${
                    !isTransactionDirectBenefit() && "cursor-pointer"
                }`}
                onClick={!isTransactionDirectBenefit() ? () => props.updateOpenedColsList() : undefined}
            >
                {/* Date desktop */}
                <td className="d-none d-md-table-cell">{getTransactionDateTime(false, false)}</td>
                {/* Date mobile */}
                <td className="d-md-none">{getTransactionDateTime(false, true)}</td>
                {/* Transaction type */}
                <td>{props.transaction.reason}</td>
                {/* Points */}
                <td className={`${getCalculatedScore() > 0 ? "text-success" : "font-silver"}`}>
                    <div className="d-none d-md-block">{getScoreString()} Punkte</div>
                    <div className="d-md-none">{getScoreString()} Pkt.</div>
                </td>
                {/* Expand arrow */}
                <td className="text-right">
                    {!isTransactionDirectBenefit() && (
                        <button
                            className="btn-orange-text"
                            onClick={() => props.updateOpenedColsList()}
                            aria-label={props.isColumnOpened ? "Schließen" : "Öffnen"}
                        >
                            <ArrowForwardIos
                                className={`icon-accent-wrapper icon-accent-orange
                                        ${props.isColumnOpened ? "rotateArrow-90" : "rotateArrow-0"} `}
                                style={{ fontSize: "0.938rem" }}
                            />
                        </button>
                    )}
                </td>
            </tr>
            {props.isColumnOpened && (
                <>
                    <ExpandedPurchaseDesktop
                        storeName={getStoreName()}
                        transaction={props.transaction}
                        transactionDate={getTransactionDateTime(true, false)}
                        exceptionsText={EXCEPTIONS_TEXT}
                    />
                    <ExpandedPurchaseMobile
                        storeName={getStoreName()}
                        transaction={props.transaction}
                        transactionDate={getTransactionDateTime(true, false)}
                        exceptionsText={EXCEPTIONS_TEXT}
                    />
                </>
            )}
        </tbody>
    );
};
