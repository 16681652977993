import { AES, enc, mode, pad } from "crypto-js";
import { getAnalytics, logEvent, setAnalyticsCollectionEnabled } from "firebase/analytics";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAppSetting } from "utils/AppSettings";

export enum EventMaps {
    ScreenView = "screen_view",
    Button = "button_event",
    SelectContent = "select_content",
    Filter = "filter_event",
    Search = "search",
    SelectProduct = "product_selection",
    Register = "register",
    Logout = "logout",
}

export enum RouteMaps {
    Landing = "Login/Registrierung",
    Home = "Start",
    Punkteuebersicht = "Punkte Übersicht",
    Lieblingsprodukte = "Lieblingsprodukte",
    Aktionsprodukte = "Aktionsprodukte",
    WochenHits = "Wochen-Hits",
    News = "NewsView",
    PersoenlicheDaten = "Persönliche Daten",
    PasswortAendern = "Passwort ändern",
    EMailAdresseAendern = "E-Mail-Adresse ändern",
    Registrierungsbestaetigung = "Registrierungsbestätigung",
    Kontakt = "Kontakt",
    Teilnahmebedingungen = "Teilnahmebedingungen",
    FAQ = "FAQ",
    Impressum = "Impressum",
    Datenschutz = "Datenschutzhinweise",
    ArticleFinder = "Artikelsuche",
    PrivatsphaereEinstellungen = "Privatsphäre-Einstellungen",
    Onboarding = "Onboarding",
}

export const CommonButtonTitles = {
    selection: {
        saveSelection: "Auswahl speichern",
        changeSelection: "Auswahl ändern",
    },
    multiplier: {
        saveMultiplier: "Lieblingsprodukte speichern",
        discardMultiplier: "Lieblingsprodukte verwerfen",
        returnToMultiplier: "Zu Lieblingsprodukten zurückkehren",
        onSearchBoxClicked: "Nach Artikeln suchen",
        openBrandsFilter: "Markenfilter Lieblingsprodukte",
        openCategoriesFilter: "Warengruppenfilter Lieblingsprodukte",
        resetBrandsFilter: "Marken Zurücksetzen",
        resetCategoriesFilter: "Warengruppe Zurücksetzen",
        multplierTile: "+",
    },
    promotions: {
        savePromotions: "Aktionsprodukte speichern",
        discardPromotions: "Aktionsprodukte verwerfen",
        returnToPromotions: "Zu Aktionsprodukten zurückkehren",
    },
    termination: {
        terminateCustomer: "Teilnahme kündigen",
        confirmTerminateCustomer: "tebonus Teilnahme kündigen",
        cancelTerminateCustomer: "Kündigung abbrechen",
    },
    authorization: {
        logout: "Ausloggen",
    },
    news: {
        newsSlide: "News-Kachel",
    },
    consent: {
        privacyConsent: "Einwilligung Nutzeranalyse erteilt",
        modalConsent: "Consent erteilt",
    },
};

const HOME_ROUTE = "/";
const firebaseApp = initializeApp(getFirebaseOptions());
const blackListRoutes: string[] = ["/signin-oidc", "/meintebonus/News"];

export function setAnalyticsConsent(isConfirmed: boolean): void {
    setAnalyticsCollectionEnabled({ app: firebaseApp }, isConfirmed);
}

export function logAnalyticsScreenView(parameters?: object, screenTitle?: string, isLandingPage?: boolean): void {
    const screenView = screenTitle ?? getScreenView(isLandingPage);

    if (screenView) {
        const screenParameters = {
            screen_title: screenView,
            source: "web",
            ...parameters,
        };

        logAnalyticsEvent(EventMaps.ScreenView, screenParameters);
    }
}

export function logAnalyticsButton(parameters?: object): void {
    logAnalyticsEvent(EventMaps.Button, parameters);
}

export function logAnalyticsSelectContent(parameters: {
    content_type?: string;
    item_id?: string;
    [key: string]: unknown;
}): void {
    logAnalyticsEvent(EventMaps.SelectContent, parameters);
}

export function logAnalyticsFilter(parameters: object): void {
    logAnalyticsEvent(EventMaps.Filter, parameters);
}

export function logAnalyticsSelectProduct(parameters: object): void {
    logAnalyticsEvent(EventMaps.SelectProduct, parameters);
}

export function logAnalyticsSearch(parameters: { [key: string]: unknown; search_term?: string }): void {
    logAnalyticsEvent(EventMaps.Search, parameters);
}

export function logAnalyticsLogout(): void {
    logAnalyticsEvent(EventMaps.Logout);
}

function logAnalyticsEvent(eventName: string, parameters?: object): void {
    const analytics = getAnalytics(firebaseApp);
    logEvent(analytics, eventName, parameters);
}

function getScreenView(isLandingPage?: boolean): string {
    const pathName = location.pathname;
    if (blackListRoutes.includes(pathName)) {
        return undefined;
    }

    if (isLandingPage) {
        return RouteMaps.Landing;
    }

    if (pathName === HOME_ROUTE) {
        return RouteMaps.Home;
    }

    const splittedPath = pathName.split("/");
    const route = splittedPath[splittedPath.length - 1].replaceAll("-", "");

    return RouteMaps[route] ?? pathName;
}

function getFirebaseOptions(): FirebaseOptions {
    const firebasePassPhrase = getAppSetting("REACT_APP_FIREBASE_PASS_PHRASE") as string;
    const [keyStr, ivStr] = firebasePassPhrase.split("_");

    const key = enc.Utf8.parse(keyStr);
    const iv = enc.Utf8.parse(ivStr);

    const encryptedFirebaseConfig = getAppSetting("REACT_APP_FIREBASE_CONFIG") as string;
    const firebaseConfigJson = AES.decrypt(encryptedFirebaseConfig, key, {
        iv,
        padding: pad.Pkcs7,
        mode: mode.CBC,
    }).toString(enc.Utf8);

    const firebaseOptions = JSON.parse(firebaseConfigJson);

    return firebaseOptions;
}
