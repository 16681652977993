import { TebonusArrowRightSharp } from "components/_graphics";
import React, { FunctionComponent, ReactElement } from "react";
import { FaqList } from "types";
import FaqItem from "./FaqItem";

interface FaqCategoriesProps {
    categoryIndex: number;
    category: FaqList;
    expandedCategories: number[];
    toggleRowExpansion: (categoryId: number) => void;
    onToggleFaqButtonClick: (id: number, title: string) => void;
}

const FaqCategories: FunctionComponent<FaqCategoriesProps> = (props: FaqCategoriesProps): ReactElement => {
    const isCategoryExpanded = props.expandedCategories.includes(props.categoryIndex);

    return (
        <tbody key={props.categoryIndex}>
            {/* the individual categories */}
            <tr className="cursor-pointer" onClick={() => props.toggleRowExpansion(props.categoryIndex)}>
                <td>
                    <h3 className="font-FAQs-category font-plumPie mb-0">{props.category.key}</h3>
                </td>

                {/* toggle button */}
                <td className="text-right">
                    <button
                        className={`icon-accent-wrapper icon-accent-plumPie ${
                            isCategoryExpanded ? "rotateArrow-90" : "rotateArrow-0"
                        }`}
                        onClick={() => props.toggleRowExpansion(props.categoryIndex)}
                        aria-label={isCategoryExpanded ? "Schließen" : "Erweitern"}
                    >
                        <TebonusArrowRightSharp />
                    </button>
                </td>
            </tr>

            {isCategoryExpanded &&
                props.category.value.map((FAQ, index) => {
                    const isFAQExpanded = props.expandedCategories.includes(FAQ.id);
                    return (
                        <FaqItem
                            key={index}
                            faq={FAQ}
                            isFaqExpanded={isFAQExpanded}
                            onToggleFaqButtonClick={(id, title) => props.onToggleFaqButtonClick(id, title)}
                        />
                    );
                })}
        </tbody>
    );
};

export default FaqCategories;
