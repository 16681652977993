import React, { FunctionComponent, ReactElement } from "react";
import { useSelector } from "react-redux";
import PageBase from "components/Shared/PageBase";
import { WeeklyHitsHeader } from "components/WeeklyHits/WeeklyHitsHeader";
import { WeeklyHitsTiles } from "components/WeeklyHits/WeeklyHitsTiles";
import { TebonusStarIcon } from "../../components/_graphics";
import { TegutBadge } from "../../components/_tegut/TegutBadge";
import { AppState } from "../../store/configureStore";
import { PromotionState } from "../../store/Promotions";
import { Colors } from "../../styles";

const WeeklyHits: FunctionComponent = (): ReactElement => {
    const promotions: PromotionState = useSelector((state: AppState) => state.promotionReducer);

    return (
        <PageBase
            title="Wochen-Hits"
            icon={
                <TegutBadge bgColor={Colors.orange} size={35}>
                    <TebonusStarIcon htmlColor={Colors.white} />
                </TegutBadge>
            }
            isContentLoading={promotions.weekPromotionsLoading}
            contentLoadingText="Ihre Wochen-Hits werden geladen."
            showLoadingIndicator={true}
        >
            <div className="container-md content-in-tile">
                <WeeklyHitsHeader />
                <WeeklyHitsTiles
                    weekPromotions={promotions.weekPromotions}
                    areWeekPromotionsLoading={promotions.weekPromotionsLoading}
                />
            </div>
        </PageBase>
    );
};

export default WeeklyHits;
