import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import PageBase from "components/Shared/PageBase";
import { CommonButtonTitles, logAnalyticsButton, logAnalyticsSelectProduct } from "utils/FirebaseAnalytics";
import { hasInternetConnection } from "utils/MultiplierPromotionUtils";
import { PromotionsHeader } from "components/Promotions/PromotionsHeader";
import { PromotionProducts } from "components/Promotions/PromotionProducts";
import { PromotionsFooter } from "components/Promotions/PromotionsFooter";
import { TebonusBagIcon } from "../../components/_graphics";
import { TegutBadge } from "../../components/_tegut/TegutBadge";

import { ProductStatus } from "../../components/Shared/ProductTile";
import { AppState } from "../../store/configureStore";
import { PromotionState } from "../../store/Promotions";
import { Promotion, PromotionAdvantage } from "../../types";

import { actionCreators } from "../../store";
import { Colors } from "../../styles";
import PromotionModals, { ModalTypes } from "../../components/Promotions/Modals";
import { arePromotionsLocked, isInitial } from "./utils";

const PromotionsPage: FunctionComponent = (): ReactElement => {
    const MAX_ITEMS_TO_SELECT = 7;

    const dispatch = useAppDispatch();

    const promotions: PromotionState = useAppSelector((state: AppState) => state.promotionReducer);
    const customers = useAppSelector((state: AppState) => state.customerReducer);

    const [selectedPromotions, setSelectedPromotions] = useState<string[]>([]);
    const [isAnalyzeOptInVisible, setIsAnalyzeOptInVisible] = useState<boolean>(false);
    const [currentPromotion, setCurrentPromotion] = useState<Promotion>();
    const [hasOptInError, setHasOptInError] = useState<boolean>(false);
    // State to open and close the modals
    const [whichModalIsOpen, setWhichModalIsOpen] = useState<ModalTypes>(ModalTypes.none);
    const [isCheckingInternetConnection, setIsCheckingInternetConnection] = useState<boolean>(false);

    useEffect(() => {
        if (selectedPromotions.length !== 0) {
            dispatch(
                actionCreators.actionCreatorPromotion.validateMultiplierCollisions({
                    promotionIdentifiers: selectedPromotions,
                })
            );
        } else if (promotions.areMultiplierCollisionsFound) {
            dispatch(actionCreators.actionCreatorPromotion.clearMultiplierCollisions());
        }
    }, [selectedPromotions]);

    /**
     * Checks if the promotion product tile is selected, activated or disabled
     * @param promoProduct
     * @returns the appropriate product status
     */
    function getPromotionStatus(promoProduct: Promotion): ProductStatus {
        if (promoProduct.isActivated && arePromotionsLocked(promotions)) {
            // if (promoProduct.isActivated) {
            //return ProductStatus.isActivated;

            // When the promotions are reseted only the arePromotionsLocked is set to false.
            // The "isActivated" in the single promotion will not be reseted to its initial (false) value.
            // Therefore the if statement includes "arePromotionsLocked" and "promoProduct.IsActivated".
            // If the "isActivated" will be reseted or the new promotions will always have false as the initial value, then the if statement can be changed.
            return ProductStatus.isActivated;
        }

        if (selectedPromotions.includes(promoProduct.tegutPromotionIdentifier)) {
            return ProductStatus.isSelected;
        }

        if (
            !selectedPromotions.includes(promoProduct.tegutPromotionIdentifier) &&
            selectedPromotions.length >= MAX_ITEMS_TO_SELECT
        ) {
            return ProductStatus.isDisabled;
        }

        return ProductStatus.isIdle;
    }

    function checkInternetConnection() {
        hasInternetConnection().then(result => {
            if (!result) {
                setWhichModalIsOpen(ModalTypes.none);
                setIsCheckingInternetConnection(false);
                setHasOptInError(true);
            } else {
                setWhichModalIsOpen(ModalTypes.none);
                setIsCheckingInternetConnection(false);
                setIsAnalyzeOptInVisible(true);
            }
        });
    }

    function onOptInErrorRetryButtonClick(): void {
        setHasOptInError(false);
        evaluateNextStep(currentPromotion);
    }

    function evaluateNextStep(item) {
        if (!customers.customer?.analysisOptInDate) {
            setIsCheckingInternetConnection(true);
            checkInternetConnection();
        } else {
            selectDeselectPromotion(item);
        }
    }

    function onPromoProductSelect(item: Promotion): void {
        setCurrentPromotion(item);
        evaluateNextStep(item);
    }

    /**
     * Adds the {@link promotion} to the active promotions stored in the temporary state of this component.
     * @param promotion The promotion which was selected by the user.
     */
    function selectDeselectPromotion(promotion: Promotion) {
        // remove the item from the selectedPromotion list
        if (selectedPromotions.includes(promotion.tegutPromotionIdentifier)) {
            const tmp = selectedPromotions.filter(id => id !== promotion.tegutPromotionIdentifier);
            setSelectedPromotions(tmp);
        }

        // add item to the selectedPromotion list
        else if (selectedPromotions.length < MAX_ITEMS_TO_SELECT) {
            const tmp = [...selectedPromotions, promotion.tegutPromotionIdentifier];
            setSelectedPromotions(tmp);

            const advantageType = promotion.advantage === PromotionAdvantage.PlusPoints ? "+" : "×";

            logAnalyticsSelectProduct({
                [`produkt_aktion_${selectedPromotions.length + 1}`]: promotion.productDescription,
                produkt_punkte: advantageType + promotion.advantageAmount,
            });
        }
    }

    function handleAgreement() {
        dispatch(actionCreators.actionCreatorCustomers.getCustomerInfo());
        setIsAnalyzeOptInVisible(false);
        selectDeselectPromotion(currentPromotion);
    }

    /**
     * Call a dispatch and activate the selected promotion products.
     */
    function activateSelectedPromotions() {
        dispatch(actionCreators.actionCreatorPromotion.activatePromotions(selectedPromotions));
        setWhichModalIsOpen(ModalTypes.none);
    }

    function setOpenedModal(modalTypes: ModalTypes) {
        setWhichModalIsOpen(modalTypes);
    }

    function onSaveButtonClicked() {
        setWhichModalIsOpen(ModalTypes.confirm);
        logAnalyticsButton({ button_title: CommonButtonTitles.promotions.savePromotions });
    }

    /**
     * Reset the selected promotion products. If these are not reseted after activating the information popup will be visible when leaving the page
     */
    function resetNumberOfSelectedPromoProducts() {
        setSelectedPromotions([]);
    }

    return (
        <PageBase
            title="Ihre Aktionsprodukte"
            id="tile_content"
            icon={
                <TegutBadge bgColor={Colors.orange} size={35}>
                    <TebonusBagIcon htmlColor={Colors.white} />
                </TegutBadge>
            }
            modals={
                <PromotionModals
                    isAnalyzeOptInVisible={isAnalyzeOptInVisible}
                    isCheckingInternetConnection={isCheckingInternetConnection}
                    hasOptInError={hasOptInError}
                    openedModal={whichModalIsOpen}
                    currentPromotions={promotions}
                    numberSelectedProducts={selectedPromotions.length}
                    onOptInErrorRetryButtonClick={onOptInErrorRetryButtonClick}
                    handleOptInAgreement={handleAgreement}
                    activateSelectedPromotionFunction={activateSelectedPromotions}
                    setHasOptInError={setHasOptInError}
                    setIsAnalyzeOptInVisible={setIsAnalyzeOptInVisible}
                    setOpenedModalFunction={setOpenedModal}
                    resetNumberOfSelectedProducts={resetNumberOfSelectedPromoProducts}
                />
            }
        >
            <div className="container-md content-in-tile">
                <PromotionsHeader arePromotionsLocked={arePromotionsLocked(promotions)} isInitial={isInitial(promotions)} />

                {/* content will be shown when loading is done */}
                <div className="scrollScoreContainer-md table-scroll-bar overflow-y-md">
                    <div className="d-flex justify-content-center flex-row flex-wrap">
                        <PromotionProducts
                            arePromotionsLocked={arePromotionsLocked(promotions)}
                            areMonthPromotionsLoading={promotions.monthPromotionsLoading}
                            onSelectButton={p => onPromoProductSelect(p)}
                            getPromotionStatus={p => getPromotionStatus(p)}
                            monthPromotions={promotions.monthPromotions}
                        />
                    </div>

                    <PromotionsFooter
                        arePromotionsLocked={arePromotionsLocked(promotions)}
                        onSaveButtonClicked={onSaveButtonClicked}
                        promotions={promotions}
                        selectedPromotionsLength={selectedPromotions.length}
                    />
                </div>
            </div>
        </PageBase>
    );
};

export default PromotionsPage;
