import React, { FunctionComponent, ReactElement } from "react";
import { getTitle } from "../../pages/ArticleMultipliers/utils";
import MultiplierHeader from "../MultiplierHeader";

interface WeclomeText {
    isInitial: boolean;
    isEditable: boolean;
}

const MultiplierWelcomeText: FunctionComponent<WeclomeText> = (props): ReactElement => {
    return (
        <div className="d-flex flex-column align-items-center mb-3">
            <div className="text-justify text-center font-subheading">{getTitle(props.isInitial, props.isEditable)}</div>
            <MultiplierHeader isInitial={props.isInitial} isEditable={props.isEditable} />
        </div>
    );
};

export default MultiplierWelcomeText;
