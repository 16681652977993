import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import React, { FunctionComponent, ReactElement } from "react";

interface LockCardModalProps {
    lockPhysicalCard: () => void;
    closeModal: () => void;
}

export const LockCardModal: FunctionComponent<LockCardModalProps> = (props): ReactElement => {
    return (
        <ModalConfirmInfo
            titleText={
                <>
                    <p className="mb-n3">
                        Möchten Sie Ihre tebonus Karte <br /> unwiderruflich sperren?
                    </p>
                </>
            }
            button1Text="Karte sperren"
            button1function={props.lockPhysicalCard}
            button2Text="Abbrechen"
            button2function={props.closeModal}
        />
    );
};
