import { MonthSelector } from "components/DateSelector/MonthSelector";
import { YearSelector } from "components/DateSelector/YearSelector";
import dayjs from "dayjs";
import React, { FunctionComponent, ReactElement } from "react";
import { CustomerScoreTransaction } from "types";
import { useCurrentWidth } from "utils/useCurrentWidth";

interface DateSelectorProps {
    scoreTransactions: CustomerScoreTransaction[];
    selectedYear: number;
    selectedMonth: string;
    setSelectedYear: (value: number) => void;
    setSelectedMonth: (value: string) => void;
}

export const DateSelector: FunctionComponent<DateSelectorProps> = (props): ReactElement => {
    const LG_BREAK_POINT = 1024;

    const screenWidth = useCurrentWidth();

    /**
     * @returns a sorted array of available years
     */
    const getTransactionsYears = (): number[] => {
        const years: number[] = [];

        let minYear = undefined;
        if (props?.scoreTransactions) {
            props.scoreTransactions?.forEach((t: CustomerScoreTransaction) => {
                const parsedYear = new Date(t.transactionDateUtc).getFullYear();
                if (!years.includes(parsedYear)) {
                    years.push(new Date(t.transactionDateUtc).getFullYear());

                    if (minYear === undefined || parsedYear < minYear) {
                        minYear = parsedYear;
                    }
                }
            });
        }

        // fill missing years
        const currentYear = Number.parseInt(dayjs().format("YYYY"));
        for (let i = 0; i < (minYear ? currentYear - minYear : 1); i++) {
            if (!years.includes(currentYear - i)) {
                years.push(currentYear - i);
            }
        }

        return years.sort();
    };

    return (
        <div>
            {/* Desktop and Mobile Component for */}
            <div className="row justify-content-center my-3">
                <div className="col-6 col-lg-3">
                    <YearSelector
                        yearsList={getTransactionsYears()}
                        selectedYear={props.selectedYear}
                        setSelectedYear={props.setSelectedYear}
                        isSmallScreen={screenWidth < LG_BREAK_POINT}
                    />
                </div>
                <div className="col-6 col-lg-12">
                    <MonthSelector
                        selectedMonth={props.selectedMonth}
                        setSelectedMonth={props.setSelectedMonth}
                        isSmallScreen={screenWidth < LG_BREAK_POINT}
                    />
                </div>
            </div>
        </div>
    );
};
