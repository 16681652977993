import React, { FunctionComponent } from "react";

interface PhysicalCardLineProps {
    isVisible: boolean;
    classNameAdditions?: string;
    text: string;
    icon: JSX.Element;
    onClick?: () => void;
}

const PhysicalCardLine: FunctionComponent<PhysicalCardLineProps> = props => {
    return (
        <>
            {props.isVisible && (
                <div className={`d-flex align-items-center ${props.classNameAdditions ?? ""}`} onClick={props.onClick}>
                    {props.icon}
                    <h5 className="ml-3 mt-2">{props.text}</h5>
                    <div className="ml-auto">{props.children}</div>
                </div>
            )}
        </>
    );
};

export default PhysicalCardLine;
