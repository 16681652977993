import { AxiosRequestConfig, AxiosResponse } from "axios";
import { AllReduxActions, Article, AxiosRequestError, BaseType, ErrorStatusCode, ReduxBaseType } from "../types";
import RequestStatus from "../utils/RequestStatus";
import { getAppSetting } from "../utils/AppSettings";

const REACT_APP_DATASERVICE_URL = getAppSetting("REACT_APP_DATASERVICE_URL");

const SET_ARTICLE_MULTIPLIERS = "pekuma/articles/SET_MULTIPLIERS";
const SET_ARTICLE_MULTIPLIERS_SUCCESS = "pekuma/articles/SET_MULTIPLIERS_SUCCESS";
const SET_ARTICLE_MULTIPLIERS_FAIL = "pekuma/articles/SET_MULTIPLIERS_FAIL";
const GET_ARTICLE_MULTIPLIERS = "pekuma/articles/GET_MULTIPLIERS";
const GET_ARTICLE_MULTIPLIERS_SUCCESS = "pekuma/articles/GET_MULTIPLIERS_SUCCESS";
const GET_ARTICLE_MULTIPLIERS_FAIL = "pekuma/articles/GET_MULTIPLIERS_FAIL";
const GET_ARTICLE_MULTIPLIER_DETAILS = "pekuma/articles/GET_ARTICLE_MULTIPLIER_DETAILS";
const GET_ARTICLE_MULTIPLIER_DETAILS_SUCCESS = "pekuma/articles/GET_ARTICLE_MULTIPLIER_DETAILS_SUCCESS";
const GET_ARTICLE_MULTIPLIER_DETAILS_FAIL = "pekuma/articles/GET_ARTICLE_MULTIPLIER_DETAILS_FAIL";
const SAVE_MULTIPLIER_DETAILS = "pekuma/articles/SAVE_MULTIPLIER_DETAILS";
const VALIDATE_PROMOTION_COLLISIONS = "pekuma/articles/VALIDATE_MULTIPLIER_PROMOTIONS_COLLISIONS";
const VALIDATE_PROMOTION_COLLISIONS_SUCCESS = "pekuma/articles/VALIDATE_MULTIPLIER_PROMOTIONS_COLLISIONS_SUCCESS";
const VALIDATE_PROMOTION_COLLISIONS_FAIL = "pekuma/articles/VALIDATE_MULTIPLIER_PROMOTIONS_COLLISIONS_FAIL";
const CLEAR_MULTIPLIER_LOADING_STATE = "pekuma/articles/CLEAR_MULTIPLIER_LOADING_STATE";
const CLEAR_PROMOTION_COLLISIONS = "pekuma/articles/CLEAR_PROMOTION_COLLISIONS";
const CLEAR_ALL_MULTIPLIERS = "pekuma/articles/CLEAR_ALL";

type ArticleMultiplierAmount = 5 | 10 | 20;

interface ResponseArticle extends Article {
    name: string;
    description: string;
    tan: string;
}

interface SetMultiplierRequest {
    amount: ArticleMultiplierAmount;
    articleTan: string;
}

interface ValidatePromotionCollisionsRequest {
    multiplierTans: string[];
}

interface SetArticleMultiplierResponse {
    lastUpdate: number;
    articles: { articleTan: string; amount: ArticleMultiplierAmount }[];
    nextChangeDate: string;
}

interface GetArticleMultiplierResponse {
    lastUpdate: number;
    articles: { articleTan: string; amount: ArticleMultiplierAmount }[];
    nextChangeDate: string;
    areMultiplierLocked: boolean;
}

interface SetArticleMultipliersAction {
    type: typeof SET_ARTICLE_MULTIPLIERS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface ValidatePromotionCollisionsResponse {
    isCollisionFound: boolean;
    multiplierTans: string[];
}

interface SetArticleMultipliersSuccessAction {
    type: typeof SET_ARTICLE_MULTIPLIERS_SUCCESS;
    payload: AxiosResponse<SetArticleMultiplierResponse>;
}

interface SetArticleMultipliersFailAction {
    type: typeof SET_ARTICLE_MULTIPLIERS_FAIL;
    error: AxiosRequestError;
}

export interface GetArticleMultipliersAction {
    type: typeof GET_ARTICLE_MULTIPLIERS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetArticleMultipliersSuccessAction {
    type: typeof GET_ARTICLE_MULTIPLIERS_SUCCESS;
    payload: AxiosResponse<GetArticleMultiplierResponse>;
}

interface GetArticleMultipliersFailAction {
    type: typeof GET_ARTICLE_MULTIPLIERS_FAIL;
    error: AxiosRequestError;
}

interface GetArticleMultiplierDetailsAction {
    type: typeof GET_ARTICLE_MULTIPLIER_DETAILS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetArticleMultiplierDetailsSuccessAction {
    type: typeof GET_ARTICLE_MULTIPLIER_DETAILS_SUCCESS;
    payload: AxiosResponse<ResponseArticle[]>;
}

interface GetArticleMultiplierDetailsFailAction {
    type: typeof GET_ARTICLE_MULTIPLIER_DETAILS_FAIL;
    error: AxiosRequestError;
}

interface ValidatePromotionCollisionsAction {
    type: typeof VALIDATE_PROMOTION_COLLISIONS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface ValidatePromotionCollisionsSuccessAction {
    type: typeof VALIDATE_PROMOTION_COLLISIONS_SUCCESS;
    payload: AxiosResponse<ValidatePromotionCollisionsResponse>;
}

interface ValidatePromotionCollisionsFailAction {
    type: typeof VALIDATE_PROMOTION_COLLISIONS_FAIL;
    error: AxiosRequestError;
}

interface SaveMultiplierDetailsAction {
    type: typeof SAVE_MULTIPLIER_DETAILS;
    article: Article;
    value: number;
}

interface ClearLoadingStateAction {
    type: typeof CLEAR_MULTIPLIER_LOADING_STATE;
}

interface ClearPromotionCollisionsAction {
    type: typeof CLEAR_PROMOTION_COLLISIONS;
}

interface ClearAllAction {
    type: typeof CLEAR_ALL_MULTIPLIERS;
}

export interface ArticleMultiplierState extends BaseType, ReduxBaseType {
    articles: Article[];
    values: ArticleMultiplierAmount[];
    areMultiplierLocked: boolean;
    nextChangeDate: string;
    isSettingMultiplier: boolean;
    lastUpdate: number;
    shouldFetchDetails: boolean;
    arePromotionsCollisionsFound: boolean;
    collidedMultiplierTans: string[];
}

export type KnownAction =
    | SetArticleMultipliersAction
    | SetArticleMultipliersSuccessAction
    | SetArticleMultipliersFailAction
    | GetArticleMultipliersAction
    | GetArticleMultipliersSuccessAction
    | GetArticleMultipliersFailAction
    | GetArticleMultiplierDetailsAction
    | GetArticleMultiplierDetailsSuccessAction
    | GetArticleMultiplierDetailsFailAction
    | ValidatePromotionCollisionsAction
    | ValidatePromotionCollisionsSuccessAction
    | ValidatePromotionCollisionsFailAction
    | SaveMultiplierDetailsAction
    | ClearLoadingStateAction
    | ClearPromotionCollisionsAction
    | ClearAllAction;

export type SuccessAction =
    | SetArticleMultipliersSuccessAction
    | GetArticleMultipliersSuccessAction
    | GetArticleMultiplierDetailsSuccessAction;

export type ErrorAction =
    | SetArticleMultipliersFailAction
    | GetArticleMultipliersFailAction
    | GetArticleMultiplierDetailsFailAction;

export const INITIAL_STATE: ArticleMultiplierState = {
    values: [20, 10, 5],
    articles: [],
    areMultiplierLocked: false,
    nextChangeDate: "",
    isLoading: false,
    isSettingMultiplier: false,
    errorCode: ErrorStatusCode.noError,
    lastUpdate: 0,
    shouldFetchDetails: false,
    arePromotionsCollisionsFound: false,
    collidedMultiplierTans: [],
};

// Reducer
export const reducer = (state = INITIAL_STATE, action: KnownAction): ArticleMultiplierState => {
    switch (action.type) {
        case SET_ARTICLE_MULTIPLIERS: {
            return {
                ...state,
                isSettingMultiplier: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.SET_ARTICLE_MULTIPLIERS,
            };
        }
        case SET_ARTICLE_MULTIPLIERS_FAIL: {
            return {
                ...state,
                isSettingMultiplier: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.SET_ARTICLE_MULTIPLIERS_FAIL,
            };
        }
        case SET_ARTICLE_MULTIPLIERS_SUCCESS: {
            const { nextChangeDate, lastUpdate } = action.payload.data;

            const articles = action.payload.data.articles.map(el => {
                return { amount: el.amount, article: el.articleTan };
            });

            const tmp: Article[] = [undefined, undefined, undefined];

            if (articles) {
                articles.forEach(
                    comb =>
                        (tmp[state.values.indexOf(comb.amount)] = {
                            tan: comb.article,
                        } as Article)
                );
            }
            return {
                ...state,
                isSettingMultiplier: false,
                shouldFetchDetails: true,
                articles: tmp,
                areMultiplierLocked: true,
                nextChangeDate,
                lastUpdate,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.SET_ARTICLE_MULTIPLIERS_SUCCESS,
            };
        }
        case GET_ARTICLE_MULTIPLIERS_SUCCESS: {
            const { areMultiplierLocked, lastUpdate, nextChangeDate } = action.payload.data;

            const tmp: Article[] = [undefined, undefined, undefined];
            const articles = action.payload.data.articles.map(el => {
                return { amount: el.amount, article: el.articleTan };
            });

            let counter = 0;
            state.articles.forEach(el => {
                articles.forEach(ar => {
                    if (ar.article !== el?.tan) {
                        counter++;
                    }
                });
            });

            if ((counter === 0 && state.articles.length !== 0) || !articles) {
                return {
                    ...state,
                    shouldFetchDetails: false,
                    nextChangeDate,
                    lastUpdate,
                    isLoading: false,
                    errorCode: ErrorStatusCode.noError,
                    performedAction: AllReduxActions.GET_ARTICLE_MULTIPLIERS_SUCCESS,
                };
            }

            articles.forEach(
                comb =>
                    (tmp[state.values.indexOf(comb.amount)] = {
                        tan: comb.article,
                    } as Article)
            );
            return {
                ...state,
                articles: tmp,
                shouldFetchDetails: true,
                nextChangeDate,
                lastUpdate,
                areMultiplierLocked,
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_ARTICLE_MULTIPLIERS_SUCCESS,
            };
        }

        case GET_ARTICLE_MULTIPLIERS: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_ARTICLE_MULTIPLIERS,
            };
        }
        case GET_ARTICLE_MULTIPLIERS_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_ARTICLE_MULTIPLIERS_FAIL,
            };
        }
        case GET_ARTICLE_MULTIPLIER_DETAILS: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_ARTICLE_MULTIPLIER_DETAILS,
            };
        }
        case GET_ARTICLE_MULTIPLIER_DETAILS_SUCCESS: {
            const details = action.payload.data;
            const tmp = [...state.articles];
            tmp.forEach((element, index) => {
                const article = details.find(at => at.tan === element?.tan);
                if (article) {
                    tmp[index] = {
                        tan: article.tan,
                        name: article.name,
                        description: article.description,
                        imagePath: article.imagePath,
                    };
                }
            });

            return {
                ...state,
                articles: tmp,
                shouldFetchDetails: false,
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_ARTICLE_MULTIPLIER_DETAILS_SUCCESS,
            };
        }
        case GET_ARTICLE_MULTIPLIER_DETAILS_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_ARTICLE_MULTIPLIER_DETAILS_FAIL,
            };
        }
        case SAVE_MULTIPLIER_DETAILS: {
            const tmp = [...state.articles];
            tmp[action.value] = { ...action.article };

            return {
                ...state,
                articles: tmp,
                shouldFetchDetails: false,
                performedAction: AllReduxActions.SAVE_MULTIPLIER_DETAILS,
            };
        }
        case VALIDATE_PROMOTION_COLLISIONS: {
            return {
                ...state,
                arePromotionsCollisionsFound: false,
                collidedMultiplierTans: [],
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.VALIDATE_PROMOTION_COLLISIONS,
            };
        }
        case VALIDATE_PROMOTION_COLLISIONS_SUCCESS: {
            const { isCollisionFound, multiplierTans } = action.payload.data;

            return {
                ...state,
                arePromotionsCollisionsFound: isCollisionFound,
                collidedMultiplierTans: multiplierTans,
                isLoading: false,
                performedAction: AllReduxActions.VALIDATE_PROMOTION_COLLISIONS_SUCCESS,
            };
        }
        case VALIDATE_PROMOTION_COLLISIONS_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.VALIDATE_PROMOTION_COLLISIONS_FAIL,
            };
        }
        case CLEAR_MULTIPLIER_LOADING_STATE: {
            return {
                ...state,
                isSettingMultiplier: false,
                isLoading: false,
                performedAction: AllReduxActions.CLEAR_MULTIPLIER_LOADING_STATE,
            };
        }
        case CLEAR_PROMOTION_COLLISIONS: {
            return {
                ...state,
                arePromotionsCollisionsFound: false,
                collidedMultiplierTans: [],
            };
        }
        case CLEAR_ALL_MULTIPLIERS: {
            return state;
        }
        default:
            return state;
    }
};

// Action Creators
export const actionCreators = {
    setArticleMultipliers: (articles: SetMultiplierRequest[]): SetArticleMultipliersAction =>
        <SetArticleMultipliersAction>{
            type: SET_ARTICLE_MULTIPLIERS,
            payload: {
                request: {
                    method: "POST",
                    url: "/SetMultipliers",
                    data: {
                        multiplier: articles.filter(a => a.articleTan).map(article => article),
                    },
                },
            },
        },
    getArticleMultipliers: (): GetArticleMultipliersAction =>
        <GetArticleMultipliersAction>{
            type: GET_ARTICLE_MULTIPLIERS,
            payload: {
                request: {
                    method: "GET",
                    url: "/GetMultipliers",
                },
            },
        },
    saveArticleAsMultiplier: (article: Article, value: number): SaveMultiplierDetailsAction =>
        <SaveMultiplierDetailsAction>{
            type: SAVE_MULTIPLIER_DETAILS,
            article,
            value,
        },
    getMultiplierDetails: (terms: string[]): GetArticleMultiplierDetailsAction =>
        <GetArticleMultiplierDetailsAction>{
            type: GET_ARTICLE_MULTIPLIER_DETAILS,
            payload: {
                request: {
                    method: "POST",
                    url: `${REACT_APP_DATASERVICE_URL}api/FindArticlesByTan`,
                    data: {
                        terms,
                        isDiscountable: true,
                        isActive: true,
                    },
                },
            },
        },
    validatePromotionCollisions: (requestData: ValidatePromotionCollisionsRequest): ValidatePromotionCollisionsAction =>
        <ValidatePromotionCollisionsAction>{
            type: VALIDATE_PROMOTION_COLLISIONS,
            payload: {
                request: {
                    method: "POST",
                    url: "/ValidateMultiplierPromotionsCollisions",
                    data: requestData,
                },
            },
        },
    clearMultiplierLoadingState: (): ClearLoadingStateAction =>
        <ClearLoadingStateAction>{
            type: CLEAR_MULTIPLIER_LOADING_STATE,
        },
    clearPromotionCollisions: (): ClearPromotionCollisionsAction =>
        <ClearPromotionCollisionsAction>{
            type: CLEAR_PROMOTION_COLLISIONS,
        },
    clearAll: (): ClearAllAction =>
        <ClearAllAction>{
            type: CLEAR_ALL_MULTIPLIERS,
        },
};

export type ArticleMultiplierActions = typeof actionCreators;
