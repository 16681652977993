import { Article } from "types";
import defaultProduct from "../assets/icons/products/default-camera-icon.svg";

/**
 * Fetches an appropriate image path for an article, so it can be displayed.
 * @param article The article for which an image path is needed.
 * @returns The correct image path, if the article has one, or a default image path if the
 * image path of the article is a falsy value, or null otherwise.
 */
export function getImagePath(article: Article): string {
    if (article) {
        return article.imagePath ? article.imagePath : defaultProduct;
    }
    return null;
}
