import dayjs from "dayjs";
import "dayjs/locale/de";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { CommonButtonTitles, logAnalyticsButton, logAnalyticsScreenView } from "utils/FirebaseAnalytics";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import TerminateTebonus, { terminationReceived } from "components/terminateTebonus";
import PrivacyPolicy from "pages/PrivacyPolicy";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { CardOverviewState, isPhysicalCardOrdered } from "store/CardOverview";
import { isTokenTebonusTerminated } from "utils/AuthHelper";
import { CustomerScoreState } from "store/CustomerScore";
import { DocumentState } from "store/Documents";
import { NewsState } from "store/News";
import { CustomerState } from "store/Customers";
import { ArticleMultiplierState } from "store/ArticleMultipliers";
import { PromotionState } from "store/Promotions";
import { IAuthState } from "store/Auth";
import { CARD_NAV_TITLE, hasPhysicalCard } from "utils/CardSubMenu";
import { TrackingConsentState } from "store/TrackingConsent";
import GoToAppAlert from "../components/GoToApp";
import NavBar from "../components/NavBar";
import NavBarMobile from "../components/NavBar/NavBarMobile";
import TokenRefresh from "../components/TokenRefresh";
import TrackingConsentModal from "../components/Shared/TrackingConsentModal";
import ModalConfirmInfo from "../components/_overlays/ConfirmationModal";
import OnboardingModal from "../components/_overlays/OnboardingModal";
import Footer from "../components/_tegut/TegutFooter";
import SubMenu from "../dashboard/Menu";
import * as NavLinks from "../navigation";
import ProtectedRoute from "../navigation/ProtectedRoute";
import { actionCreators } from "../store";
import { AppState } from "../store/configureStore";
import { AllReduxActions, ErrorStatusCode } from "../types";
import ArticleFinder from "./ArticleFinder";
import Imprint from "./Imprint";
import SigninOidc from "./SignIn";
import Update from "./Update";

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.locale("de");

const App: FunctionComponent = (): ReactElement => {
    const trackingConsentState: TrackingConsentState = useAppSelector((state: AppState) => state.trackingConsentReducer);
    const authState: IAuthState = useAppSelector((state: AppState) => state.authReducer);
    const dispatch = useAppDispatch();
    const location = useLocation();

    const sessionStorageKey = "showRegistrationCardModal";
    const sessionStorageValue = JSON.parse(sessionStorage.getItem(sessionStorageKey));
    const [showRegistrationCardModal, setShowRegistrationCardModal] = useState<boolean>(sessionStorageValue);

    // Log Analytics Screen View
    useEffect(() => {
        if (authState.isAuthenticated && customerState.isTrackingConsentSet && !showRegistrationCardModal) {
            logAnalyticsScreenView();
        }
    }, [location.pathname, showRegistrationCardModal]);

    /**
     * determines wether the onboarding-modal should be shown
     * @returns boolean-value if modal should be shown
     */
    const showOnboardingModal = (): boolean => {
        const registrationDate = dayjs.utc(customerState.registrationDate);

        if (!registrationDate.isValid()) {
            return null;
        }

        // 1 hour ago.
        const dateFrom = dayjs.utc().subtract(1, "hour");

        // now
        const dateTo = dayjs.utc();

        // true if the registrationDate is in between now and 1 hour ago.
        const isDateBetween = registrationDate.isBetween(dateFrom, dateTo, null, "[]");

        return (
            isDateBetween &&
            isPhysicalCardOrdered(cardOverviewState.cards) &&
            showRegistrationCardModal &&
            customerState.isTrackingConsentSet
        );
    };

    // Reload data if the fetch was not successffull and display corresponding error modal
    const promotionState: PromotionState = useAppSelector((state: AppState) => state.promotionReducer);
    const articleMultiplierState: ArticleMultiplierState = useAppSelector((state: AppState) => state.multiplierReducer);
    const customerState: CustomerState = useAppSelector((state: AppState) => state.customerReducer);
    const customerScoreState: CustomerScoreState = useAppSelector((state: AppState) => state.customerScoreReducer);
    const newsState: NewsState = useAppSelector((state: AppState) => state.newsReducer);
    const documentsState: DocumentState = useAppSelector((state: AppState) => state.documentsReducer);
    const cardOverviewState: CardOverviewState = useAppSelector((state: AppState) => state.cardOverviewReducer);

    // state with the corresponding action
    // List of all states which are should be checked when a state has changed
    const StateActions = [
        articleMultiplierState,
        customerState,
        customerScoreState,
        promotionState,
        newsState,
        documentsState,
        cardOverviewState,
    ];
    // List of all failed api call actions. This list is used to know which calls have to be repeated
    const [failedActions, setFailedActions] = useState<AllReduxActions[]>([]);
    const [isTerminateButtonPressed, setTerminateButtonPressed] = useState<boolean>(false);

    // Redux actions which should be opened in an error modal to be reloaded when it was not possible to load them.
    // Activate or set actions are excluded because these have a separate error modal
    const actionsToReload = [
        AllReduxActions.GET_MONTH_PROMOTIONS_FAIL,
        AllReduxActions.GET_WEEK_PROMOTIONS_FAIL,
        AllReduxActions.GET_ARTICLE_MULTIPLIERS_FAIL,
        AllReduxActions.GET_CUSTOMER_INFO_FAIL,
        AllReduxActions.GET_CUSTOMER_SCORE_FAIL,
        AllReduxActions.GET_NEWS_FAIL,
        AllReduxActions.GET_DOCUMENTS_FAIL,
        AllReduxActions.GET_CARDOVERVIEW_CARDS_FAIL,
    ];

    // Check in all states if an error has occoured and which action was performed to trigger it. Set the setFailedAction state accordingly with only unique values.
    useEffect(
        () => {
            StateActions.map(item => {
                // Add the failed action to the list when an error occoured and it was one of the desired actions which should be handled by the error pop up
                if (
                    item.errorCode !== ErrorStatusCode.noError &&
                    actionsToReload.includes(item.performedAction) &&
                    !failedActions.includes(item.performedAction) &&
                    authState.isAuthenticated
                ) {
                    setFailedActions(failedActions.concat(item.performedAction));
                }
            });
        },
        StateActions.map(item => item.errorCode)
    );

    if (sessionStorageValue == null) {
        sessionStorage.setItem(sessionStorageKey, "true");
        setShowRegistrationCardModal(true);
    }

    useEffect(() => {
        dispatch(actionCreators.actionCreatorsDocuments.getLegalDocuments());

        if (!authState.isAuthenticated) {
            // reset/ set the inital values if the user is not authenticated
            setFailedActions([]);
            dispatch(actionCreators.actionCreatorNews.clearLoadingState());
            dispatch(actionCreators.actionCreatorsFaqs.clearLoadingState());
            dispatch(actionCreators.actionCreatorCustomers.clearAll());
            dispatch(actionCreators.actionCreatorCustomerScore.clearAll());
            dispatch(actionCreators.actionCreatorPromotion.clearAll());
            dispatch(actionCreators.actionCreatorArticleMultipliers.clearAll());
            dispatch(actionCreators.actionCreatorsCardOverview.clearAll());
        }

        if (authState.isAuthenticated) {
            setFailedActions([]);
            // fetch the whole data
            dispatch(actionCreators.actionCreatorCustomers.getCustomerInfo());
            dispatch(actionCreators.actionCreatorCustomerScore.getCustomerScore());
            dispatch(actionCreators.actionCreatorPromotion.getMonthPromotions());
            dispatch(actionCreators.actionCreatorPromotion.getWeekPromotions());
            dispatch(actionCreators.actionCreatorArticleMultipliers.getArticleMultipliers());
            dispatch(actionCreators.actionCreatorNews.getNews());

            /**
             * The cards will be dispatched with a delay because if the user creates a new account the engine needs a moment
             * to create and to provide the information with the physical card at the endpoint. This information about the
             * physical card is needed to show the onboarding pop up that a physical card was sent to the customer.
             */
            setTimeout(() => {
                dispatch(actionCreators.actionCreatorsCardOverview.getCardOverview());
            }, 3000);

            if (trackingConsentState.isConsentAccepted != undefined) {
                dispatch(actionCreators.actionCreatorCustomers.setTrackingConsentState(trackingConsentState.isConsentAccepted));
                dispatch(actionCreators.actionCreatorsTrackingConsent.resetTrackingConsent());
            }
        }
    }, [authState.isAuthenticated]);

    useEffect(() => {
        if (customerState.performedAction === AllReduxActions.SET_TRACKING_CONSENT_SUCCESS) {
            dispatch(actionCreators.actionCreatorCustomers.getCustomerInfo());
        }
    }, [customerState.performedAction]);

    useEffect(() => {
        const { tokenResponse } = authState;

        if (tokenResponse && tokenResponse.accessToken && isTokenTebonusTerminated(tokenResponse.accessToken)) {
            sessionStorage.clear();
            localStorage.clear();
            dispatch(actionCreators.actionCreatorsAuth.logout());
        }
    }, [authState.tokenResponse]);

    /**
     * Renders a modal that is displayed to the user if an timeout error occured during fetching the data
     * (returned by the redux store).
     * In case of timeout, month or week promotions shall be loaded again.
     * @returns a modal that is shown to the user in case of errors
     */
    function renderTimeoutErrorModal(): ReactElement {
        return (
            failedActions.length > 0 && (
                <ModalConfirmInfo
                    titleText="Etwas ist schiefgelaufen"
                    bodyText="Ihre Aktion konnte leider nicht bearbeitet werden. Bitte sorgen Sie für eine stabile Internetverbindung."
                    button1Text="Erneut versuchen"
                    button1function={() => {
                        // reload failed actions
                        failedActions.map(action => {
                            switch (action) {
                                case AllReduxActions.GET_MONTH_PROMOTIONS_FAIL:
                                    dispatch(actionCreators.actionCreatorPromotion.getMonthPromotions());
                                    break;
                                case AllReduxActions.GET_WEEK_PROMOTIONS_FAIL:
                                    dispatch(actionCreators.actionCreatorPromotion.getWeekPromotions());
                                    break;
                                case AllReduxActions.GET_ARTICLE_MULTIPLIERS_FAIL:
                                    dispatch(actionCreators.actionCreatorArticleMultipliers.getArticleMultipliers());
                                    break;
                                case AllReduxActions.GET_CUSTOMER_INFO_FAIL:
                                    dispatch(actionCreators.actionCreatorCustomers.getCustomerInfo());
                                    break;
                                case AllReduxActions.GET_CUSTOMER_SCORE_FAIL:
                                    dispatch(actionCreators.actionCreatorCustomerScore.getCustomerScore());
                                    break;
                                case AllReduxActions.GET_NEWS_FAIL:
                                    dispatch(actionCreators.actionCreatorNews.getNews());
                                    break;
                                case AllReduxActions.GET_DOCUMENTS_FAIL:
                                    dispatch(actionCreators.actionCreatorsDocuments.getLegalDocuments());
                                    break;
                                case AllReduxActions.GET_CARDOVERVIEW_CARDS_FAIL:
                                    dispatch(actionCreators.actionCreatorsCardOverview.getCardOverview());
                                    break;
                            }
                        });
                        setFailedActions([]);
                    }}
                    button2Text="Abbrechen"
                    button2function={() => setFailedActions([])}
                />
            )
        );
    }

    const setTerminateButton = (isPressed: boolean) => {
        setTerminateButtonPressed(isPressed);

        logAnalyticsButton({
            button_title: CommonButtonTitles.termination.terminateCustomer,
        });
    };

    const onLogoutButtonClicked = () => {
        logAnalyticsButton({
            button_title: CommonButtonTitles.authorization.logout,
        });

        logout();
    };

    const logout = () => {
        sessionStorage.clear();

        // clear whole storage when the user terminated tebonus
        if (JSON.parse(sessionStorage.getItem(terminationReceived))) {
            localStorage.clear();
        }

        dispatch(actionCreators.actionCreatorsAuth.logout());

        <Redirect to="/" />;
    };

    const login = () => {
        dispatch(actionCreators.actionCreatorsAuth.redirect());
    };

    const isRouteEnabled = (navTitle: string) => {
        if (navTitle === CARD_NAV_TITLE) {
            return hasPhysicalCard(cardOverviewState.cards);
        }

        return true;
    };

    return (
        <>
            <TokenRefresh />
            <GoToAppAlert />
            <NavBar login={login} logout={onLogoutButtonClicked} isAuthenticated={authState.isAuthenticated} />
            <NavBarMobile
                login={login}
                logout={onLogoutButtonClicked}
                isAuthenticated={authState.isAuthenticated}
                isCustomerRegistrationConfirmed={customerState.isEmailConfirmed}
                customerRegistrationDate={customerState.registrationDate}
                isConfirmationMailSent={customerState.isConfirmationSentSuccessfully}
            />

            {showOnboardingModal() && (
                <OnboardingModal
                    submitButtonAction={() => {
                        sessionStorage.setItem(sessionStorageKey, "false");
                        setShowRegistrationCardModal(false);
                    }}
                />
            )}

            <div className="container-xl pt-2 container-fluid-mobile mt-lg-2">
                {/* m-0 prevents the row to become larger than the screen */}
                <div className="row m-0">
                    {/* Menu on the left side which will be hidden if the screen is small */}
                    {authState.isAuthenticated && (
                        <div className="d-none d-lg-block col-lg-2 grid-gutter-y">
                            <SubMenu
                                isCustomerRegistrationConfirmed={customerState.isEmailConfirmed}
                                customerRegistrationDate={customerState.registrationDate}
                                isConfirmationMailSent={customerState.isConfirmationSentSuccessfully}
                            />
                        </div>
                    )}
                    {/* Dashboard were the content will be changed */}
                    <div className={`${authState.isAuthenticated ? "col-lg-10" : ""} col-12 p-lg-0 p-0`}>
                        {/* display error modals. Which will be centered on the detail tiles*/}
                        {renderTimeoutErrorModal()}
                        <TerminateTebonus
                            isTerminateButtonPressed={isTerminateButtonPressed}
                            setTerminateButtonPressed={setTerminateButtonPressed}
                            logout={logout}
                        ></TerminateTebonus>
                        <Switch>
                            {/* pages, which are allowed to be displayed */}
                            <ProtectedRoute
                                exact
                                path="/"
                                component={NavLinks.linksMenu[0].component}
                                isAuthenticated={authState.isAuthenticated}
                            />
                            {NavLinks.linksMenu.map((item, i) => (
                                <ProtectedRoute
                                    key={i}
                                    exact
                                    path={item.htmlLink}
                                    component={item.component}
                                    isAuthenticated={authState.isAuthenticated}
                                />
                            ))}
                            {NavLinks.linksSubMenu_MyTebonus.map(
                                (item, i) =>
                                    isRouteEnabled(item.title) && (
                                        <ProtectedRoute
                                            key={i}
                                            exact
                                            path={item.htmlLink}
                                            component={item.component}
                                            isAuthenticated={authState.isAuthenticated}
                                        />
                                    )
                            )}
                            {NavLinks.linksSubMenu_Account.map((item, i) => (
                                <ProtectedRoute
                                    key={i}
                                    exact
                                    path={item.htmlLink}
                                    component={item.component}
                                    isAuthenticated={authState.isAuthenticated}
                                />
                            ))}
                            {NavLinks.linksSubMenu_Usefull.map((item, i) => (
                                <ProtectedRoute
                                    key={i}
                                    exact
                                    path={item.htmlLink}
                                    component={item.component}
                                    isAuthenticated={authState.isAuthenticated}
                                />
                            ))}

                            <ProtectedRoute
                                exact
                                path="/ArticleFinder"
                                component={ArticleFinder}
                                isAuthenticated={authState.isAuthenticated}
                            />

                            <Route exact path="/Impressum" component={Imprint} />
                            <Route exact path="/Datenschutz" component={PrivacyPolicy} />

                            <Route exact path="/Update" component={Update} />

                            {/* Necessary for login */}
                            <Route path="/signin-oidc" component={SigninOidc}></Route>

                            {/* Redirect to home when the page could not be found */}
                            <Route path="*">
                                <Redirect to="/" />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
            <Footer setTerminateButton={setTerminateButton} isAuthenticated={authState.isAuthenticated} />
            <TrackingConsentModal />
        </>
    );
};

export default App;
