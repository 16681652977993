import React, { FunctionComponent, useState, useEffect } from "react";
import dayjs from "dayjs";
import localeDate from "dayjs/plugin/localeData";

dayjs.extend(localeDate);

interface MonthSelectorProps {
    selectedMonth: string;
    setSelectedMonth: (month: string) => void;
    isSmallScreen?: boolean;
}

export const MonthSelector: FunctionComponent<MonthSelectorProps> = props => {
    const [months, setMonths] = useState([]);

    useEffect(() => {
        setMonths(dayjs.months().map(m => m));
    }, []);

    const handleSelection = (month: string): void => {
        props.setSelectedMonth(month);
    };

    // Mobile and Tablet
    if (props.isSmallScreen) {
        return (
            <select
                className="mx-2 date-dropdown w-100"
                aria-label="Month Selector"
                defaultValue="Monat"
                value={props.selectedMonth}
                onChange={e => handleSelection(e.target.value)}
            >
                <option hidden value="Monat">
                    Monat
                </option>
                {months.map((month, index) => {
                    return (
                        <option key={index} className="font-date-selector">
                            {month}
                        </option>
                    );
                })}
            </select>
        );
    }
    //Desktop
    return (
        <div className="row justify-content-center">
            {months.map((month, index) => {
                return (
                    <div key={index}>
                        <div
                            className={`mx-2 inline-link ${month === props.selectedMonth ? "font-orange" : "font-silver"}`}
                            onClick={() => handleSelection(month)}
                            onKeyUp={e => {
                                if (e.key === "Enter") {
                                    handleSelection(month);
                                }
                            }}
                            tabIndex={0}
                        >
                            {month}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
