import React, { FunctionComponent, ReactElement } from "react";
import barcode from "assets/images/barcode.png";

interface EmptySearchResults {
    searchTerm: string;
}

const EmptySearchResults: FunctionComponent<EmptySearchResults> = (props): ReactElement => {
    const SEARCH_BAR_MARGIN = "10px";

    return (
        <div className="font-description">
            <div className="text-center" style={{ marginTop: SEARCH_BAR_MARGIN }}>
                Keine Ergebnisse zum Suchbegriff „{props.searchTerm}“ gefunden.
            </div>
            <div className="text-center" style={{ marginTop: 10 }}>
                <span className="font-unitText-Bold">Tipp</span>: Sie haben das Produkt bereits bei sich zu Hause? Geben Sie
                einfach <span className="font-unitText-Bold">die Zahlenfolge unterhalb des Strichcodes</span> ein, um dieses zu
                finden.
            </div>
            <div className="d-flex justify-content-center" style={{ marginTop: 20 }}>
                <img className="barcode-img" src={barcode} width={250} />
            </div>
        </div>
    );
};

export default EmptySearchResults;
