import { useSelector } from "react-redux";
import React, { FunctionComponent, ReactElement } from "react";
import { PromotionProductCarousel } from "components/Promotions/Dashboard/PromotionProductCarousel";
import { DashboardPromotionsHeader } from "components/Promotions/Dashboard/DashboardPromotionsHeader";
import { PromotionState } from "store/Promotions";
import { DashboardPromotionsFooter } from "components/Promotions/Dashboard/DashboardPromotionsFooter";
import { AppState } from "../../store/configureStore";

const ProductPromotion: FunctionComponent = (): ReactElement => {
    const promotions: PromotionState = useSelector((state: AppState) => state.promotionReducer);

    /**
     * Checks if the promotions have been selected.
     * @returns true if the promotions have already been selected. Otherwise false will be returned
     */
    function arePromotionsLocked(): boolean {
        return promotions.arePromotionsLocked;
    }

    return (
        <div className="d-flex flex-column h-100">
            <DashboardPromotionsHeader arePromotionsLocked={arePromotionsLocked()} isInitial={!promotions.nextChangeDate} />

            <PromotionProductCarousel
                arePromotionsLocked={arePromotionsLocked()}
                isLoading={promotions.isLoading}
                monthPromotions={promotions.monthPromotions}
            />

            <DashboardPromotionsFooter arePromotionsLocked={arePromotionsLocked()} nextChangeDate={promotions.nextChangeDate} />
        </div>
    );
};

export default ProductPromotion;
