import React from "react";

// Source: https://loserkid.io/bootstrap-modal-with-react/

const KEYCODE_TAB = 9;

const FOCUSABLE_ELEMENTS = 'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])';

export function useFocusTrap(): React.MutableRefObject<HTMLDivElement> {
    const ref = React.useRef<HTMLDivElement>(null);

    // Focus trap function
    function handleFocus(event: KeyboardEvent) {
        // Array of all the focusable elements in the array.
        const focusableEls = [...Array.from(ref.current.querySelectorAll(FOCUSABLE_ELEMENTS))].filter(
            el => !el.hasAttribute("disabled")
        ) as HTMLElement[];

        // First focusable element
        const firstFocusableEl = focusableEls[0];

        // Last focusable element
        const lastFocusableEl = focusableEls[focusableEls.length - 1];

        const isTabPressed = event.key === "Tab" || event.keyCode === KEYCODE_TAB;

        // Logic to focus only the current modal focusable items.
        if (!isTabPressed) {
            return;
        }

        if (event.shiftKey) {
            if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                event.preventDefault();
            }
        } else if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus();
            event.preventDefault();
        }
    }

    React.useEffect(() => {
        // Add event listener to focus trap
        const currentRef = ref.current;

        if (currentRef) {
            currentRef.addEventListener("keydown", handleFocus);
        }

        return () => {
            // Remove event listener to focus trap
            if (currentRef) {
                currentRef.removeEventListener("keydown", handleFocus);
            }
        };
    }, []);

    return ref;
}
