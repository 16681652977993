import React, { FunctionComponent, ReactElement } from "react";

interface MultiplierHeaderProps {
    isInitial: boolean;
    isEditable: boolean;
}

const MultiplierHeader: FunctionComponent<MultiplierHeaderProps> = (props): ReactElement => {
    if (props.isInitial) {
        return (
            <div className="text-justify text-center font-description">
                Legen Sie Ihre Lieblingsprodukte fest, mit denen Sie <span className="font-unitText-Bold">diesen Monat</span>{" "}
                mehrfach punkten: <span className="font-unitText-Bold">20-fach, 10-fach und 5-fach</span>.
            </div>
        );
    } else if (!props.isEditable) {
        return (
            <div className="text-justify text-center font-description">
                Sie haben Ihre persönlichen Lieblingsprodukte <span className="font-unitText-Bold">erfolgreich ausgewählt</span>
                . Viel Freude beim Mehrfachpunkten!
            </div>
        );
    }
    return (
        <div className="text-justify text-center font-description">
            Sie können <span className="font-unitText-Bold">neue Lieblingsprodukte wählen</span> oder Ihre derzeitigen Produkte
            beibehalten.
        </div>
    );
};

export default MultiplierHeader;
