import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "store/configureStore";
import { useLocation } from "react-router-dom";
import PageBase from "components/Shared/PageBase";
import { RouteMaps, logAnalyticsScreenView } from "utils/FirebaseAnalytics";
import { NewsState } from "store/News";
import NewsItem from "../../components/News/NewsItem";
import Pagination from "../../components/Shared/Pagination";

interface LocationProps {
    newsId: number;
    newsIndex: number;
}

const TILE_POSITION = "KachelPosition";

const NewsDetails: FunctionComponent = (): ReactElement => {
    const newsState: NewsState = useSelector((state: AppState) => state.newsReducer);
    const [currentNewsIndex, setCurrentNewsIndex] = useState<number>();
    const [currentNewsId, setCurrentNewsId] = useState<number>();
    const location = useLocation<LocationProps>();

    /**
     * render the clicked news item, if the user is coming from the dashboard, else render the first news Item.
     * */
    useEffect(() => {
        if (!location.state) {
            setCurrentNewsIndex(0);
            return setCurrentNewsId(newsState?.news[currentNewsIndex]?.id);
        }
        const { newsId, newsIndex } = location.state;
        setCurrentNewsId(newsId);
        setCurrentNewsIndex(newsIndex);
    }, []);

    /**
     * * PAGINATION-UseEffect
     * go to previous or next news, based on the news Index.
     * */
    useEffect(() => {
        setCurrentNewsId(newsState?.news[currentNewsIndex]?.id);

        if (currentNewsIndex !== undefined) {
            logAnalyticsScreenView({ [TILE_POSITION]: currentNewsIndex + 1 }, RouteMaps.News);
        }
    }, [currentNewsIndex]);

    const hasNextNews = () => {
        return currentNewsIndex < newsState.news.length - 1;
    };

    const hasPrevNews = () => {
        return currentNewsIndex > 0;
    };

    const getNextNews = () => {
        setCurrentNewsIndex(prevState => {
            if (prevState >= newsState.news.length - 1) {
                return 0;
            }
            return prevState + 1;
        });
    };

    const getPrevNews = () => {
        setCurrentNewsIndex(prevState => {
            if (prevState <= 0) {
                return newsState.news.length - 1;
            }
            return prevState - 1;
        });
    };

    return (
        <PageBase
            title="News"
            isContentLoading={newsState.isLoading}
            contentLoadingText="News werden geladen."
            showLoadingIndicator={true}
        >
            <NewsItem currentNewsId={currentNewsId} news={newsState.news} />
            <Pagination
                getPrevNews={getPrevNews}
                getNextNews={getNextNews}
                hasPrevNews={hasPrevNews()}
                hasNextNews={hasNextNews()}
            />
        </PageBase>
    );
};

export default NewsDetails;
