import React, { FunctionComponent, ReactElement } from "react";
import { Article, MultiplierBaseProps } from "types";
import SaveButton from "./SaveButton";

interface FooterProps extends MultiplierBaseProps {
    arePromotionsCollisionsFound: boolean;
    chosenArticleAmount: number;
    daysChooseable: number;
    numberOfMultipliers: number;
    changedMultipliers: Article[];
    collidedMultiplierTans: string[];
    onSaveButtonClicked: () => void;
}

const Footer: FunctionComponent<FooterProps> = (props): ReactElement => {
    function getFooterText(): string {
        if (props.isInitial) {
            return `${props.chosenArticleAmount} von 3 Produkten ausgewählt`;
        }

        if (!props.isEditable) {
            return `Neue Auswahl ${props.daysChooseable > 1 ? `in ${props.daysChooseable} Tagen` : "morgen"} möglich`;
        }

        return `${props.chosenArticleAmount} von 3 Produkten geändert`;
    }

    return (
        <>
            <div className="d-flex justify-content-center align-items-center mt-4">
                <div className="text-justify text-center font-footer">{getFooterText()}</div>
            </div>
            <SaveButton
                isLoading={props.isLoading}
                isInitial={props.isInitial}
                isEditable={props.isEditable}
                areCollisionsFound={props.arePromotionsCollisionsFound}
                changedMultipliers={props.changedMultipliers}
                collidedMultiplierTans={props.collidedMultiplierTans}
                numberOfMultipliers={props.numberOfMultipliers}
                onSaveButtonClicked={props.onSaveButtonClicked}
            />
        </>
    );
};

export default Footer;
