import { TebonusArrowSize, TebonusArrowType } from "components/_graphics/TebonusArrows";
import React, { FunctionComponent, ReactElement } from "react";
import Slider from "react-slick";
import { DataCarousel, DataCarouselProps } from "components/Shared/DataCarousel";
import { Promotion } from "types";
import { Link } from "react-router-dom";
import DashboardProductTile, { ProductStatus, TilePlacement } from "components/Shared/DashboardProductTile";
import { filterActivePromotions } from "store/Promotions";
import { areTherePromotions } from "../utils";
import { DashboardEmptyTiles } from "./DashboardEmptyTiles";

interface WeeklyHitsCarouselProps {
    isLoading: boolean;
    weekPromotions: Promotion[];
    weeklyHitsLink: string;
}

export const WeeklyHitsCarousel: FunctionComponent<WeeklyHitsCarouselProps> = (props): ReactElement => {
    const filteredWeeklyHits = filterActivePromotions(props.weekPromotions);
    const weeklyHitsSlider = React.createRef<Slider>();

    // Weekly hits carousel settings
    const carouselProps = {
        arrows: false,
        infinity: true,
        animationSpeed: 600,
        customArrows: true,
        customArrowsProps: {
            arrowType: TebonusArrowType.ROUND,
            arrowSize: TebonusArrowSize.MEDIUM,
            className: "icon-accent-wrapper icon-accent-orange",
            prevSliderRequested() {
                weeklyHitsSlider?.current?.slickPrev();
            },
            nextSliderRequested() {
                weeklyHitsSlider?.current?.slickNext();
            },
        },
        slidesToShowAllBreakpoints: 3,
        elementNumber: filterActivePromotions(props.weekPromotions).promotions.length,
    } as DataCarouselProps;

    const noItemsCarouselProps = {
        ...carouselProps,
        slidesToShowAllBreakpoints: 1,
        elementNumber: 1,
    } as DataCarouselProps;

    function areWeeklyHitsAvailable(): boolean {
        return areTherePromotions(props.weekPromotions) && !props.isLoading;
    }

    function areWeeklyHitsLoading(): boolean {
        return !areTherePromotions(props.weekPromotions) && props.isLoading;
    }

    function areNoWeeklyHitsAvailable(): boolean {
        return !areTherePromotions(props.weekPromotions) && !props.isLoading;
    }

    return (
        <div className="col-12 col-md-9 weeklyHits-Carousel mt-3 mt-md-0">
            <DataCarousel
                ref={weeklyHitsSlider}
                {...(areTherePromotions(props.weekPromotions) || props.isLoading
                    ? { ...carouselProps }
                    : { ...noItemsCarouselProps })}
            >
                {areWeeklyHitsAvailable() &&
                    filteredWeeklyHits.promotions.map((item, id) => (
                        <Link to={props.weeklyHitsLink} key={id} className="my-4 d-flex justify-content-center">
                            <DashboardProductTile
                                tilePlacement={TilePlacement.dashboardWeeklyHits}
                                productStatus={ProductStatus.isIdle}
                                advantage={item.advantage}
                                advantageAmount={item.advantageAmount}
                                image={item.image}
                            />
                        </Link>
                    ))}
                {areWeeklyHitsLoading() && <DashboardEmptyTiles />}
                {areNoWeeklyHitsAvailable() && (
                    <span className="text-justify text-center dashboard-tile-subheader font-dimGray">
                        Derzeit sind keine Wochen-Hits eingestellt.
                    </span>
                )}
            </DataCarousel>
        </div>
    );
};
