import LoadingIndicator from "components/LoadingIndicator";
import React, { FunctionComponent, useEffect } from "react";
import { useFocusTrap } from "utils/useFocusTrap";
import { toggleModalOpenClass } from "utils/BodyModalOpenHandler";

interface ModalConfirmInfoProps {
    img?: JSX.Element;
    titleText?: string | JSX.Element;
    titleClassNames?: string;
    bodyText?: string | JSX.Element[] | JSX.Element;
    button1Text?: string;
    button2Text?: string;

    button1function?: Function;
    button2function?: Function;

    button1HasOutline?: boolean;

    isDisplayTextLeft?: boolean;
    showLoadingIndicator?: boolean;
    loadingText?: string;

    isTerminationModal?: boolean;

    modalFooter?: JSX.Element;
}

/**
 * Modal which has a title info text and up to two buttons.
 * The modal is placed above the content and is blocking the website
 */
const ModalConfirmInfo: FunctionComponent<ModalConfirmInfoProps> = props => {
    const dialogRef = useFocusTrap();

    useEffect(() => {
        if (dialogRef && dialogRef.current) {
            dialogRef.current.focus();
        }

        toggleModalOpenClass(true);

        return () => {
            toggleModalOpenClass(false);
        };
    }, []);

    const getTextAlignmentClass = () => {
        return props.isDisplayTextLeft ? "text-left" : "text-center";
    };

    return (
        <div className="center-on-container">
            {/* div which creates a dark background over the whole screen which is not clickable */}
            <div className="modal-bg-disabled"></div>

            <div
                role="dialog"
                tabIndex={-1}
                ref={dialogRef}
                className={props.isTerminationModal ? "centered-modal" : "modal-position"}
            >
                <div className={`modal-tile-information table-scroll-bar ${props.modalFooter ? "pb-3" : ""}`} role="document">
                    <div className="modal-content-multiplier table-scroll-bar">
                        {props.img && <div className="mb-3">{props.img}</div>}
                        <div className={`modal-title ${getTextAlignmentClass()} ${props.titleClassNames}`}>
                            {props.titleText}
                        </div>
                        <div className={`modal-body-text mt-3 ${getTextAlignmentClass()}`}>
                            {props.showLoadingIndicator && (
                                <div className="mb-4 mt-n3">
                                    <LoadingIndicator loadingText={props.loadingText} isPositionStatic={true} />
                                </div>
                            )}
                            {props.bodyText}
                        </div>

                        {/* this content should include a button with a close function */}
                        {(props.button1function || props.button2function) && (
                            <div className="mt-4 d-flex flex-column align-items-center">
                                {props.button1function && (
                                    <button
                                        onClick={() => props.button1function()}
                                        className={`btn ${
                                            props.button1HasOutline ? "btn-outline-orange" : "btn-orange"
                                        }  btn-select w-100`}
                                    >
                                        {props.button1Text}
                                    </button>
                                )}

                                {props.button2function && (
                                    <button
                                        onClick={() => props.button2function()}
                                        className="btn btn-outline-orange btn-select mt-3 w-100"
                                    >
                                        {props.button2Text}
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                    {props.modalFooter && <div className="d-flex mt-3">{props.modalFooter}</div>}
                </div>
            </div>
        </div>
    );
};
export default ModalConfirmInfo;
