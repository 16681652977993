import React, { FunctionComponent, ReactElement, useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";

const Update: FunctionComponent = (): ReactElement => {
    const UPDATE_LINK = "https://www.tegut.com/tebonus.html#c78288";
    const ANDROID_UPDATE_LINK = "https://play.google.com/store/apps/details?id=com.tegut.pekuma";
    const IOS_UPDATE_LINK = "https://apps.apple.com/de/app/tegut-tebonus/id1487603408";

    useEffect(() => {
        let updateLink = UPDATE_LINK;

        if (isAndroid) {
            updateLink = ANDROID_UPDATE_LINK;
        }

        if (isIOS) {
            updateLink = IOS_UPDATE_LINK;
        }

        window.location.href = updateLink;
    });

    return <></>;
};

export default Update;
