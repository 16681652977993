import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import React, { FunctionComponent, ReactElement } from "react";

interface LoadingModalProps {
    isActivatingCurrentPromotions: boolean;
    areMonthPromotionsLoading: boolean;
    isCheckingInternetConnection: boolean;
}

export const LoadingModal: FunctionComponent<LoadingModalProps> = (props): ReactElement => {
    function getBodyText(): string {
        if (props.isActivatingCurrentPromotions) {
            return "Ihre Aktionsprodukte werden gespeichert.";
        }

        if (props.areMonthPromotionsLoading) {
            return "Ihre Aktionsprodukte werden geladen.";
        }

        return "";
    }

    function isLoadingModalVisible(): boolean {
        return props.isActivatingCurrentPromotions || props.areMonthPromotionsLoading || props.isCheckingInternetConnection;
    }

    return <>{isLoadingModalVisible() && <ModalConfirmInfo showLoadingIndicator={true} bodyText={getBodyText()} />}</>;
};
