import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "store/configureStore";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import useDebounce from "utils/UseDebounce";
import { actionCreators } from "store";
import { getNumberOfMultipliersInSessionStorage, removeAllMultipliersInSessionStorage } from "pages/ArticleMultipliers";
import PageBase from "components/Shared/PageBase";
import ArticleCategoriesView from "components/ArticleFinder/ArticleCategoriesView";
import { logAnalyticsFilter, logAnalyticsSearch } from "utils/FirebaseAnalytics";
import MultiplierWelcomeText from "components/MultiplierWelcomeText";
import ArticleFinderModals from "components/ArticleFinder/ArticleFinderModals";
import SearchBar from "components/ArticleFinder/SearchBar";
import ArticleSearchFilter from "components/ArticleFinder/ArticleSearchFilter";
import SelectedFilters from "components/ArticleFinder/SelectedFilters";
import ArticleSearchResults from "components/ArticleFinder/ArticleSearchResults";
import { LocationState, SearchProps } from "types";
import { Colors } from "../../styles";
import { TegutBadge } from "../../components/_tegut/TegutBadge";
import { TebonusSingleHeart } from "../../components/_graphics";

const HEADING_BADGE_SIZE = 35;
const HEADING_HEART_ICON_MULTIPLIER = 0.75;

export const INITIAL_SEARCH_CATEGORY_PROPS: SearchProps = {
    id: "",
    name: "",
};

const ArticleFinder: FunctionComponent = (): ReactElement => {
    const EXECUTING_SEARCH_TYPE = "Textsuche";
    const BRANDS_FILTER_TERM = "Marken";
    const CATEGORIES_FILTER_TERM = "Warengruppe";
    const LIST_FILTER_TYPE = "list";

    const dispatch = useAppDispatch();
    const location = useLocation<LocationState>();
    const history = useHistory();
    const articleMultipliers = useAppSelector((state: AppState) => state.multiplierReducer);
    const articleSearch = useSelector((state: AppState) => state.articleSearchReducer);
    const [searchTerm, setSearchTerm] = useState("");
    const debounceSearchTerm = useDebounce<string>(searchTerm, 500);
    const [debounceLoading, setDebounceLoading] = useState<boolean>(false);
    const [searchTagCategory, setSearchTagCategory] = useState<SearchProps>(INITIAL_SEARCH_CATEGORY_PROPS);
    const [selectedBrands, setSelectedBrands] = useState<SearchProps[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<SearchProps[]>([]);
    const [allCategories, setAllCategories] = useState<SearchProps[]>([]);
    const [allBrands, setAllBrands] = useState<SearchProps[]>([]);

    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    const [resetBreadcrumb, setResetBreadcrumb] = useState<boolean>(false);

    const handleTabClose = event => {
        event.preventDefault();
        return (event.returnValue =
            "Sind Sie sich sicher, dass sie die Seite verlassen möchten? Nicht gespeicherte Änderungen gehen verloren.");
    };

    useEffect(() => {
        const listen = () =>
            // clears the session storage when the user visits another page than the ones necessary for
            // selecting new article multiplers
            history.listen(location => {
                if (
                    // Compare the urls in lower case, because if the user tipes in the url it can differ.
                    location.pathname.toLowerCase() !== "/meintebonus/Lieblingsprodukte".toLowerCase() &&
                    location.pathname.toLowerCase() !== "/ArticleFinder".toLowerCase() &&
                    getNumberOfMultipliersInSessionStorage() !== 0
                ) {
                    removeAllMultipliersInSessionStorage();
                }
            });

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            listen();
            window.removeEventListener("beforeunload", handleTabClose);
        };
    });

    // [Analytics] log selected brands
    useEffect(() => {
        if (selectedBrands.length > 0) {
            const brandNames = selectedBrands.map(brand => brand.name);

            logAnalyticsFilter({
                filter_term: BRANDS_FILTER_TERM,
                produkt: brandNames,
                filter_type: LIST_FILTER_TYPE,
            });
        }
    }, [selectedBrands]);

    // [Analytics] log selected categories
    useEffect(() => {
        if (selectedCategories.length > 0) {
            const categorynames = selectedCategories.map(cat => cat.name);

            logAnalyticsFilter({
                filter_term: CATEGORIES_FILTER_TERM,
                produkt: categorynames,
                fitler_type: LIST_FILTER_TYPE,
            });
        }
    }, [selectedCategories]);

    const searchStarted = searchTagCategory?.id || searchTerm;

    // redirect to favourite products when the location state was not set
    if (!location.state) {
        history.push("/meintebonus/Lieblingsprodukte");
    }

    // // A new search will be started if the search term was changed and the debounce was triggered
    useEffect(() => {
        prepareAndFetchData();
        setDebounceLoading(false);

        if (debounceSearchTerm !== "") {
            logAnalyticsSearch({
                search_term: debounceSearchTerm,
                search_type: EXECUTING_SEARCH_TYPE,
            });
        }
    }, [debounceSearchTerm]);

    // A new search will be started if the category or brand was changed
    useEffect(() => {
        prepareAndFetchData();
    }, [searchTagCategory, selectedCategories, selectedBrands]);

    // Go throug the new results and collect the brand names
    useEffect(() => {
        const brands: SearchProps[] = [];
        articleSearch.searchResults.forEach(article => {
            article.tags.forEach(tag => {
                tag.key === "Brand" && brands.push({ id: tag.value, name: tag.value });
            });
        });

        // combine all the previous selected brands and add the new selected ones to it and make the list unique
        const combinedBrands: SearchProps[] = [...allBrands, ...brands];
        const uniqueBrands: SearchProps[] = combinedBrands.filter(
            (item, index) => index === combinedBrands.findIndex(elem => elem.id === item.id)
        );
        const renamedBrands: SearchProps[] = uniqueBrands.map(item => {
            return { ...item, id: item.id, name: decodeBrandName(item.name) };
        });

        setAllBrands(renamedBrands);
    }, [articleSearch.searchResults]);

    /**
     * Combine the search term, brand and online categorie selection to an json request and fetch the wanted data from the api.
     */
    const prepareAndFetchData = () => {
        // If no data is selected the fetch data can be skiped
        if (!searchTerm && selectedBrands.length <= 0 && selectedCategories.length <= 0 && !searchTagCategory?.id) {
            return;
        }

        const searchTags = [];
        // add brands to search tags if there are any
        if (selectedBrands.length > 0) {
            searchTags.push({ key: "Brand", value: [...selectedBrands.map(i => i.id)] });
        }

        // add the searchTagCategory tag from the search tree if it was selected
        const allSelectedCategories = searchTagCategory?.id
            ? [...selectedCategories, searchTagCategory]
            : [...selectedCategories];
        // add online categories to the search tag if at least one was selected
        if (allSelectedCategories.length > 0) {
            searchTags.push({ key: "OnlineCategory", value: allSelectedCategories.map(i => i.id) });
        }
        // Make a api request dependend on brands categories and search term
        dispatch(actionCreators.actionCreatorArticleSearch.searchArticle(searchTerm, searchTags));
    };

    function onSearchTermChanged(text: string) {
        setSearchTerm(text);
        setAllBrands([]);
        dispatch(actionCreators.actionCreatorArticleSearch.resetSearchResult());
        setDebounceLoading(true);

        // Reset states if they are not empty. They should only be resetet if neccessary. Otherwise the UseEffect is triggered to often
        searchTagCategory?.id && setSearchTagCategory(INITIAL_SEARCH_CATEGORY_PROPS);
        selectedBrands.length > 0 && setSelectedBrands([]);
        selectedCategories.length > 0 && setSelectedCategories([]);

        setResetBreadcrumb(true);
    }

    function onCancelButtonPressed() {
        setSearchTerm("");
        setAllBrands([]);
        dispatch(actionCreators.actionCreatorArticleSearch.resetSearchResult());

        // Reset states if they are not empty. They should only be resetet if neccessary. Otherwise the UseEffect is triggered to often
        searchTagCategory?.id && setSearchTagCategory(INITIAL_SEARCH_CATEGORY_PROPS);
        //  searchTagCategory?.id && setSearchTagCategory(null);
        selectedBrands.length > 0 && setSelectedBrands([]);
        selectedCategories.length > 0 && setSelectedCategories([]);
        setResetBreadcrumb(true);
    }

    /**
     * Replaces html codes in brand names by utf-8 encoded characters
     * (e.g.,'tegut&#8230;' is replaced with 'tegut…')
     * @param brandName the original brand name with html codes
     * @returns the brand name where all html codes are replaced with readable characters
     */
    const decodeBrandName = (brandName: string): string => {
        return brandName.replace(/&#(\d+);/g, (match, dec) => {
            return String.fromCharCode(dec);
        });
    };

    /**
     * Remove the desired filter from the selected brands list.
     * @param item
     */
    const removeBrandFilter = (item: SearchProps): void => {
        setSelectedBrands(selectedBrands.filter(i => i.id !== item.id));
    };

    /**
     * Remove the desired filter from the selected brands list.
     * @param item
     */
    const removeCategoryFilter = (item: SearchProps): void => {
        setSelectedCategories(selectedCategories.filter(i => i.id !== item.id));
    };

    return (
        <PageBase
            title="Ihre Lieblingsprodukte"
            icon={
                <TegutBadge bgColor={Colors.orange} size={HEADING_BADGE_SIZE}>
                    <TebonusSingleHeart
                        preserveAspectRatio={true}
                        size={HEADING_BADGE_SIZE * HEADING_HEART_ICON_MULTIPLIER}
                        color={Colors.white}
                    />
                </TegutBadge>
            }
            prevPageLink="/meintebonus/Lieblingsprodukte"
            modals={
                <ArticleFinderModals
                    isFilterModalOpen={isFilterModalOpen}
                    setShowModal={setIsFilterModalOpen}
                    setSelectedBrands={setSelectedBrands}
                    selectedBrands={selectedBrands}
                    brandOptions={allBrands}
                    setSelectedCategories={setSelectedCategories}
                    selectedCategories={selectedCategories}
                    categoryOptions={allCategories}
                />
            }
        >
            <div className="container-md content-in-tile">
                <MultiplierWelcomeText isInitial={location?.state?.isInitial} isEditable={location?.state?.isEditable} />
                <SearchBar
                    searchTerm={searchTerm}
                    searchTagCategory={searchTagCategory}
                    onCancelButtonPressed={onCancelButtonPressed}
                    onSearchTermChanged={event => onSearchTermChanged(event)}
                />
                {/* ARTICLE CATEGORIES AND BREADCRUMB MENU: only renders if the search bar is not being used to search the products. */}
                <ArticleCategoriesView
                    setSearchTagCategory={searchTag => setSearchTagCategory(searchTag)}
                    searchStarted={searchStarted !== ""}
                    resetBreadcrumb={resetBreadcrumb}
                    setResetBreadcrumb={setResetBreadcrumb}
                />
                {/* Search filters are shown when the search term was filled out */}
                {searchStarted && (
                    <ArticleSearchFilter
                        searchTerm={searchTerm}
                        selectedBrands={selectedBrands}
                        setSelectedBrands={setSelectedBrands}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        allCategories={allCategories}
                        setAllCategories={setAllCategories}
                        allBrands={allBrands}
                        isFilterModalOpen={isFilterModalOpen}
                        setIsFilterModalOpen={setIsFilterModalOpen}
                    />
                )}

                {searchStarted && (
                    <SelectedFilters
                        selectedBrands={selectedBrands}
                        selectedCategories={selectedCategories}
                        removeBrandFilter={brand => removeBrandFilter(brand)}
                        removeCategoryFilter={category => removeCategoryFilter(category)}
                    />
                )}

                {searchStarted && (
                    <ArticleSearchResults
                        currentMultiplierIndex={articleMultipliers.values[location?.state?.index]}
                        searchTerm={searchTerm || searchTagCategory?.name}
                        isSearchLoading={debounceLoading}
                    />
                )}
            </div>
        </PageBase>
    );
};

export default ArticleFinder;
