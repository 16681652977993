import { CardOverviewInfo, CardState } from "types";

const CARD_STATE_ORDERED = "bestellt";
const CARD_STATE_LOCKED = "gesperrt";

export function isLockCardLineVisible(state: CardState): boolean {
    return state === CardState.active;
}

export function isOrderNewCardLineVisible(state: CardState): boolean {
    return state !== CardState.ordered;
}

export function isOnlyCardStateVisible(state: CardState): boolean {
    return !isLockCardLineVisible(state) && !isOrderNewCardLineVisible(state);
}

export function getTebonusCardStateText(physicalCard: CardOverviewInfo): string {
    switch (physicalCard.state) {
        case CardState.active:
            return physicalCard.cardNumber;
        case CardState.ordered:
            return CARD_STATE_ORDERED;
        case CardState.locked:
            return CARD_STATE_LOCKED;
        default:
            return undefined;
    }
}
