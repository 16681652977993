import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { FaqList } from "types";
import { logAnalyticsSelectContent } from "utils/FirebaseAnalytics";
import FaqCategories from "./FaqCategories";

interface FaqsTableProps {
    faqs: FaqList[];
}

const FaqsTable: FunctionComponent<FaqsTableProps> = (props): ReactElement => {
    const FAQ_CONTENT_TYPE = "faq";
    const ACCORDEON_ACTION_OPEN = "open";
    const ACCORDEON_ACTION_CLOSE = "close";

    const [expandedCategories, setExpandedCategories] = useState<number[]>([]);

    /**
     * HTML-PARSING-UseEffect
     * parses and render the answers every time a question is expanded.
     * */
    useEffect(() => {
        const parser = new DOMParser();
        // flattens the FAQs container and gets the individual FAQs.
        const FAQs = props.faqs.flatMap(x => x.value);

        FAQs.forEach(item => {
            // gets the <p> tag id of each expanded Question
            const FAQsBodyWrapper = document.getElementById(`FAQsBody${item.id}`);
            const doc = parser.parseFromString(item.body, "text/html").body.innerHTML;

            if (FAQsBodyWrapper != null) {
                FAQsBodyWrapper.innerHTML = doc;
            }
        });
    }, [expandedCategories]);

    const onToggleFaqButtonClick = (id: number, title: string): void => {
        toggleRowExpansion(id);

        logAnalyticsSelectContent({
            content_type: FAQ_CONTENT_TYPE,
            view: title,
            accordeon_action: !expandedCategories.includes(id) ? ACCORDEON_ACTION_OPEN : ACCORDEON_ACTION_CLOSE,
        });
    };

    /**
     * Toggles the individual FAQs of the clicked category.
     * If the category is already expanded its ID will be removed from the list.
     * @param {number} [categoryId]
     * @return {*}
     */
    const toggleRowExpansion = (categoryId: number): void => {
        if (expandedCategories.includes(categoryId)) {
            // remove id from List if already expanded
            return setExpandedCategories(expandedCategories.filter(item => item !== categoryId));
        }

        // append id to list
        return setExpandedCategories(prevExpandedCategoriesIDs => [...prevExpandedCategoriesIDs, categoryId]);
    };

    return (
        <div className="content-in-tile">
            {/* scrollable FAQs */}
            <div className="mx-md-3 mx-lg-5 scrollContainer-md table-scroll-bar overflow-y-md">
                <table className="table table-hover">
                    {props.faqs.map((category, categoryIndex) => {
                        return (
                            <FaqCategories
                                key={categoryIndex}
                                category={category}
                                categoryIndex={categoryIndex}
                                expandedCategories={expandedCategories}
                                onToggleFaqButtonClick={(id, title) => onToggleFaqButtonClick(id, title)}
                                toggleRowExpansion={id => toggleRowExpansion(id)}
                            />
                        );
                    })}
                </table>
            </div>
        </div>
    );
};

export default FaqsTable;
