import React, { FunctionComponent } from "react";
import CloseSharp from "@material-ui/icons/CloseSharp";

interface FilterTileProps {
    removeFunction: (string) => void;
    labelName: string;
}

const FilterTile: FunctionComponent<FilterTileProps> = props => {
    return (
        <div className="filterTile">
            <button className="btn d-flex align-items-center p-0 " onClick={() => props.removeFunction(props.labelName)}>
                <div className="h-100 font-dimGray d-flex">
                    <CloseSharp style={{ fontSize: "1rem" }}></CloseSharp>
                </div>
                <div className="h-100 font-unitText-Bold filterTileText">{props.labelName}</div>
            </button>
        </div>
    );
};

export default FilterTile;
