import React, { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { News } from "types";
import { NewsSlide } from "types";

interface SingleNewsProps {
    news: News;
    slideIndex: number;
    slides: NewsSlide[];
}

const DashboardNewsItem: FunctionComponent<SingleNewsProps> = (props): ReactElement => {
    const singleNews = props.news;
    const imgPathWideScreen = singleNews.imagePathWeb;
    const imgPathSmallScreen = singleNews.imagePath;

    const history = useHistory();

    const navigateToNewsItem = (newsId: number, newsIndex: number) => {
        const path = `/meintebonus/News`;
        history.push(path, {
            newsId,
            newsIndex: newsIndex - props.slides.filter((s, sIndex) => !!s.contentPanel && sIndex < newsIndex).length,
        });
    };

    return (
        <div
            className="d-flex align-items-center justify-content-start h-100 dashboard-news-carousel"
            key={singleNews.id}
            id={`news-slide-${props.slideIndex}`}
            onClick={() => navigateToNewsItem(singleNews.id, props.slideIndex)}
            onKeyUp={e => {
                if (e.key === "Enter") {
                    navigateToNewsItem(singleNews.id, props.slideIndex);
                }
            }}
        >
            {imgPathWideScreen && (
                <div className="d-none d-md-block firstRowTile">
                    <img src={imgPathWideScreen} width="auto" height="100%" />
                </div>
            )}

            <div
                className={`d-none d-md-block h-100 ${imgPathWideScreen ? "pl-3 text-left" : "w-100 text-center"}`}
                style={{ paddingRight: 30, ...(imgPathWideScreen ? {} : { paddingLeft: 30 }) }}
            >
                <div className="truncate-4 pb-1 dashboardNewsTitle font-unitText-Medium">{singleNews.title}</div>
            </div>
            {imgPathSmallScreen && (
                <div className="d-md-none d-flex w-100" style={{ maxHeight: "574px" }}>
                    <img src={imgPathSmallScreen} width="100%" />
                </div>
            )}
        </div>
    );
};

export default DashboardNewsItem;
