import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import React, { FunctionComponent, ReactElement } from "react";
import { CustomerState } from "store/Customers";
import { AllReduxActions, ErrorStatusCode } from "types";

interface EmailSentStateModalProps {
    isVisible: boolean;
    storageKey: string;
    customerState: CustomerState;
    setIsVisible: (value: boolean) => void;
}

export const EmailSentStateModal: FunctionComponent<EmailSentStateModalProps> = (props): ReactElement => {
    function emailSentSucceed(): boolean {
        return props.customerState.isConfirmationSentSuccessfully && props.customerState.errorCode === ErrorStatusCode.noError;
    }

    function emailSentFailed(): boolean {
        return (
            props.customerState.errorCode !== ErrorStatusCode.noError &&
            props.customerState.performedAction === AllReduxActions.SEND_CUSTOMER_CONFIRMATION_MAIL_FAIL
        );
    }

    function getTitleText(): string {
        if (emailSentFailed()) {
            return "Das hat leider nicht geklappt!";
        }

        if (emailSentSucceed()) {
            return "Erfolgreich!";
        }

        return undefined;
    }

    function getTitleClassNames(): string {
        return emailSentSucceed() ? "font-seaWeedGreen" : undefined;
    }

    function getBodyText(): string | JSX.Element {
        if (emailSentFailed()) {
            return (
                <div>
                    Leider ist ein technisches Problem aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut
                    oder{" "}
                    <a
                        href="https://www.tegut.com/kontakt.html"
                        className="font-orange"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        wenden Sie sich an unsere Kundenbetreuung.
                    </a>
                </div>
            );
        }

        if (emailSentSucceed()) {
            return "Vielen Dank für die Anforderung eines neuen Bestätigungs-Links.";
        }

        return undefined;
    }

    return (
        <>
            {props.isVisible && (
                <ModalConfirmInfo
                    titleText={getTitleText()}
                    titleClassNames={getTitleClassNames()}
                    bodyText={getBodyText()}
                    button1Text="Okay"
                    button1function={() => {
                        props.setIsVisible(false);
                        sessionStorage.setItem(props.storageKey, JSON.stringify(false));
                    }}
                />
            )}
        </>
    );
};
