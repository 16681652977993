import { getMultplierPromotionCollisionsMessage } from "utils/MultiplierPromotionUtils";
import { Article } from "../../types";
// import MultiplierTile, { MultiplierStatus } from "../../components/ArticleMultipliers/Components/MultiplierTile";

export function getTitle(isInitial: boolean, isEditable: boolean): string {
    let title = "Punkten, sparen, freuen …";

    if (isInitial) {
        title = "Bereit zu punkten?";
    } else if (isEditable) {
        title = "Es ist wieder soweit!";
    }

    return title;
}

export const DuplicateErrorMsg = "Ein Lieblingsprodukt kann nur einmal gewählt werden. Bitte verändern Sie Ihre Auswahl.";

export const multipliersHasDuplicates = (multipliers: Article[]): boolean => {
    let hasDuplicates = false;
    multipliers.forEach(m1 => {
        if (!!m1 && multipliers.filter(m2 => m2 && m1.tan === m2.tan).length > 1) {
            hasDuplicates = true;
        }
    });

    return hasDuplicates;
};

export const getCollisionsWarningMsg = (changedMultipliers: Article[], collidedMultiplierTans: string[]): string => {
    const collidedMultipliers = changedMultipliers.filter(m => collidedMultiplierTans.includes(m.tan));
    const articleNames = collidedMultipliers.map(m => m.name);

    const firstWarningMsgSentence =
        articleNames.length === 1
            ? `Sie haben bereits "${articleNames}" als Aktionsprodukt ausgewählt.`
            : "Sie haben mehrere Produkte bereits als Aktionsprodukt ausgewählt.";

    return getMultplierPromotionCollisionsMessage(firstWarningMsgSentence);
};
