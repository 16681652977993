import React, { ReactElement, ReactNode } from "react";
import { Colors } from "../../../styles";
import "./styles.css";

interface TegutBadgeProps {
    size?: number;
    children?: ReactNode[] | ReactNode;
    bgColor?: string;
    className?: string;
}
export const TegutBadge = (props: TegutBadgeProps): ReactElement => {
    const { size } = props;
    const bgColor = props.bgColor ? props.bgColor : Colors.yellow;

    return (
        <div
            className={`badge ${props.className}`}
            style={
                size
                    ? {
                          backgroundColor: bgColor,
                          width: size,
                          height: size,
                          borderRadius: 0.43 * size,
                      }
                    : { backgroundColor: bgColor }
            }
        >
            <div className="badge-content">{props.children}</div>
        </div>
    );
};
