import { TebonusStarIcon } from "components/_graphics";
import { TegutBadge } from "components/_tegut/TegutBadge";
import React, { FunctionComponent, ReactElement } from "react";
import { Link } from "react-router-dom";
import { Colors } from "styles";
import { Promotion } from "types";
import dayjs from "dayjs";
import { areTherePromotions } from "../utils";

interface DashboardWeeklyHitsHeaderProps {
    weekPromotions: Promotion[];
    weeklyHitsLink: string;
}

export const DashboardWeeklyHitsHeader: FunctionComponent<DashboardWeeklyHitsHeaderProps> = (props): ReactElement => {
    const getValidFrom = (): string => {
        return dayjs().startOf("week").format("DD.MM.YYYY");
    };

    const getValidTo = (): string => {
        return dayjs().endOf("week").format("DD.MM.YYYY");
    };

    return (
        <div className="col-12 col-md-3">
            <div className="weeklyHits-TitleContainer d-flex flex-column my-auto">
                {/* LOGO */}
                <TegutBadge bgColor={Colors.orange} className="dashboard-icon">
                    <TebonusStarIcon htmlColor={Colors.orange} style={{ fontSize: "3rem" }} />
                </TegutBadge>
                {/* Title */}
                <div className="dashboard-tile-title mt-1 mt-md-3">Wochen-Hits</div>
                {/* Valid from to */}
                <div className="font-unitText-Regular weeklyHitsFromToText">
                    {"gültig vom "}
                    <span className="font-unitText-Bold"> {getValidFrom()} </span>
                    {" bis zum "}
                    <span className="font-unitText-Bold"> {getValidTo()}</span>
                </div>
                {/* Link to detail page */}
                {areTherePromotions(props.weekPromotions) && (
                    <Link to={props.weeklyHitsLink} className="font-unitText-Regular weeklyHitsLink">
                        Produkte ansehen
                    </Link>
                )}
            </div>
        </div>
    );
};
