import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const TebonusFilterIcon: React.FC<SvgIconProps> = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 27.542 28.575">
            <path
                id="Pfad_838"
                data-name="Pfad 838"
                d="M4351.244,10082.186v-14.373l-10.412-14.2h27.542l-10.561,14.2v10.171Z"
                transform="translate(-4340.833 -10053.61)"
                fill={props.htmlColor}
            />
        </SvgIcon>
    );
};

export default TebonusFilterIcon;
