import React, { FunctionComponent } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { ANDROID, getAppStoreLink, IOS } from "utils/GetAppStoreLink";
import CloseSharp from "@material-ui/icons/CloseSharp";

const GoToAppAlert: FunctionComponent = () => {
    return (
        <div>
            {/* Only render this part if it is an android */}
            {isAndroid && (
                /* container which will be closed when the closing button is pressed */
                <div className="collapse show bg-light" id="collapseAppAlert">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-1 my-auto">
                                <a
                                    className="btn p-0"
                                    data-toggle="collapse"
                                    href="#collapseAppAlert"
                                    role="button"
                                    aria-expanded="true"
                                    aria-controls="collapseAppAlert"
                                    aria-label="Schließen"
                                >
                                    <CloseSharp />
                                </a>
                            </div>
                            <div className="col-7 my-auto">
                                <span>
                                    <strong>Punkte sammeln</strong>
                                    <br />
                                    <small>In der tebonus App öffnen</small>
                                </span>
                            </div>
                            <div className="col-4 my-auto text-right">
                                <button
                                    className="btn btn-orange mr-1"
                                    onClick={() => {
                                        window.location.href = `${isIOS ? getAppStoreLink(IOS) : getAppStoreLink(ANDROID)}`;
                                    }}
                                >
                                    ZUR APP
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GoToAppAlert;
