import { AxiosRequestConfig, AxiosResponse } from "axios";
import { Reducer } from "redux";
import { AxiosRequestError, BaseType, ErrorStatusCode, ReduxBaseType, TegutStore } from "types";
import RequestStatus from "utils/RequestStatus";
import { getAppSetting } from "../utils/AppSettings";

const REACT_APP_DATASERVICE_URL = getAppSetting("REACT_APP_DATASERVICE_URL");

export const GET_MARKETS_INFO = "pekuma/markets/GET_INFO";
export const GET_MARKETS_INFO_SUCCESS = "pekuma/markets/GET_INFO_SUCCESS";
export const GET_MARKETS_INFO_FAIL = "pekuma/markets/GET_INFO_FAIL";

interface GetMarketsResponse {
    storeId: number;
    name: string;
    tegutStoreIdentifier: string;
    description: string;
}

interface GetMarketsInfoAction {
    type: typeof GET_MARKETS_INFO;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetMarketsInfoSuccessAction {
    type: typeof GET_MARKETS_INFO_SUCCESS;
    payload: AxiosResponse<GetMarketsResponse[]>;
}

interface GetMarketsInfoFailAction {
    type: typeof GET_MARKETS_INFO_FAIL;
    error: AxiosRequestError;
}

export interface MarketState extends BaseType, ReduxBaseType {
    storeList: TegutStore[];
    lastUpdate: number;
}

export type KnownAction = GetMarketsInfoAction | GetMarketsInfoFailAction | GetMarketsInfoSuccessAction;

export type ErrorAction = GetMarketsInfoFailAction;

const INITIAL_STATE: MarketState = { storeList: [], isLoading: false, errorCode: ErrorStatusCode.noError, lastUpdate: 0 };

// Reducer
export const reducer: Reducer<MarketState> = (state = INITIAL_STATE, action: KnownAction): MarketState => {
    switch (action.type) {
        case GET_MARKETS_INFO: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
            };
        }
        case GET_MARKETS_INFO_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
            };
        }
        case GET_MARKETS_INFO_SUCCESS: {
            const storeList: TegutStore[] = action.payload.data.map(element => {
                return {
                    tegutStoreIdentifier: element.tegutStoreIdentifier,
                    store: element.storeId,
                    name: element.name,
                };
            });

            return {
                ...state,
                isLoading: false,
                storeList,
                errorCode: ErrorStatusCode.noError,
                lastUpdate: Date.now(),
            };
        }
        default:
            return state;
    }
};

// Action Creators
export const actionCreators = {
    getMarketsInfoByID: (identifiers: string[]): GetMarketsInfoAction =>
        <GetMarketsInfoAction>{
            type: GET_MARKETS_INFO,
            payload: {
                request: {
                    method: "POST",
                    url: `${REACT_APP_DATASERVICE_URL}api/FindStores`,
                    data: { identifiers },
                },
            },
        },
};
