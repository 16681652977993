import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";
import App from "./pages/App";
import { store, persistor } from "./store/configureStore";

// adding created bootstrap library. Own statements are included
import "../src/styles/scss/main.css";
// eslint-disable-next-line import/order
import CustomLeavePagePrompt from "components/_overlays/CustomLeavePagePrompt";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter
                    getUserConfirmation={(message, callback) => {
                        const data = JSON.parse(message);

                        // should expect true or false (equivalent to OK and Cancel in the system prompt)
                        callback(data.shouldShowForm ? true : false);

                        const promptContainer = document.getElementById("customLeavePagePromptContainer");

                        const cleanUp = answer => {
                            ReactDOM.unmountComponentAtNode(promptContainer);
                            callback(answer);
                        };

                        ReactDOM.render(
                            <CustomLeavePagePrompt
                                cleanUp={cleanUp}
                                title={data.title}
                                description={data.description}
                                buttonPositiveText={data.buttonPositiveText}
                                buttonNegativeText={data.buttonNegativeText}
                                discardButtonLogInfos={data.discardButtonLogInfos}
                                returnButtonLogInfos={data.returnButtonLogInfos}
                            />,
                            promptContainer
                        );
                    }}
                >
                    <ScrollToTop />
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
