export const ANDROID = "Android";
export const IOS = "iOS";

const APP_STORE_URL_ANDROID = "https://play.google.com/store/apps/details?id=com.tegut.pekuma";
const APP_STORE_URL_IOS = "https://apps.apple.com/de/app/tegut-tebonus/id1487603408";

export function getAppStoreLink(operatingSystem: string): string {
    if (operatingSystem === ANDROID) {
        return APP_STORE_URL_ANDROID;
    }
    if (operatingSystem === IOS) {
        return APP_STORE_URL_IOS;
    }
}
