import { Buffer } from "buffer";
import React, { FunctionComponent, ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "store/configureStore";
import PageBase from "components/Shared/PageBase";

const PrivacyPolicy: FunctionComponent = (): ReactElement => {
    const documentsState = useSelector((state: AppState) => state.documentsReducer);

    useEffect(() => {
        // get the privacy policy object if it is there
        const base64Data = documentsState.documents?.find(item => item.name === "Datenschutz.html");

        if (base64Data) {
            // get the html privacy policy data and decode it from base64 to string
            const data = Buffer.from(base64Data.data, "base64").toString();

            if (data) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(data, "text/html");

                const docArticle = doc.querySelector("body").innerHTML;
                document.querySelector("#privacyPolicy").innerHTML = docArticle;
            }
        }
        if (!base64Data && !documentsState.isLoading) {
            document.querySelector("#privacyPolicy").innerHTML =
                "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.";
        }
    }, [documentsState.documents]);

    return (
        <PageBase
            title="Datenschutz"
            isContentLoading={documentsState.isLoading}
            showLoadingIndicator={true}
            contentLoadingText="Datenschutzhinweise werden geladen."
        >
            <div className="content-in-tile scrollContainer-md table-scroll-bar overflow-y-md">
                {/* The privacy policy will be placed inside of this container */}
                <div id="privacyPolicy" className="mt-3 mt-md-0 px-3"></div>
            </div>
        </PageBase>
    );
};

export default PrivacyPolicy;
