import React, { FunctionComponent } from "react";
import { Colors } from "../../../styles";

interface TegutLogoProps {
    width: number | string;
    height: number | string;
    color?: string;
    colorPrimary?: string;
}

const TebonusLogo: FunctionComponent<TegutLogoProps> = props => {
    const LogoColor = props.color != null ? props.color : Colors.white;
    const ColorPrimary = props.colorPrimary != null ? props.colorPrimary : Colors.orange;
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 386.48 95.26">
            <path
                fill={ColorPrimary}
                d="M4.55,47.82c-1.29,0-2.37-0.44-3.24-1.31C0.44,45.64,0,44.55,0,43.26v-3.07c0-1.29,0.44-2.37,1.31-3.24
            c0.87-0.87,1.95-1.31,3.24-1.31h1.37v-9.67c0-1.29,0.42-2.46,1.25-3.53c0.83-1.06,1.9-1.67,3.19-1.82l7.28-1.14
            c1.21-0.15,2.24,0.15,3.07,0.91c0.83,0.76,1.25,1.71,1.25,2.84v12.4h6.14c1.29,0,2.37,0.44,3.24,1.31
            c0.87,0.87,1.31,1.95,1.31,3.24v3.07c0,1.29-0.44,2.37-1.31,3.24c-0.87,0.87-1.95,1.31-3.24,1.31h-6.14v29.13
            c0,2.5,0.36,4.15,1.08,4.95c0.72,0.8,1.8,1.2,3.24,1.2c0.53,0,1.02-0.04,1.48-0.11c0.46-0.07,0.87-0.19,1.25-0.34
            c0.61-0.15,1.17-0.06,1.71,0.28c0.53,0.34,0.87,0.82,1.02,1.42l0.91,4.44c0.23,1.21,0.02,2.35-0.63,3.41
            c-0.65,1.06-1.57,1.78-2.79,2.16c-0.99,0.3-2.03,0.53-3.13,0.68c-1.1,0.15-2.37,0.23-3.81,0.23c-2.5,0-4.76-0.23-6.77-0.68
            c-2.01-0.46-3.72-1.25-5.12-2.39c-1.4-1.14-2.5-2.63-3.3-4.49c-0.8-1.86-1.19-4.19-1.19-7V47.82H4.55z"
            />
            <path
                fill={ColorPrimary}
                d="M82.48,91.05c-2.05,1.29-4.38,2.31-7,3.07c-2.62,0.76-5.52,1.14-8.7,1.14c-9.71,0-16.84-2.41-21.39-7.22
            c-4.55-4.82-6.83-12.69-6.83-23.61c0-5.46,0.55-10.12,1.65-13.99c1.1-3.87,2.69-7,4.78-9.39c2.09-2.39,4.65-4.13,7.68-5.23
            c3.03-1.1,6.45-1.65,10.24-1.65c8.72,0,14.96,2.48,18.72,7.45c3.75,4.97,5.63,12.8,5.63,23.49v2.39c0,0.61-0.21,1.14-0.63,1.59
            c-0.42,0.46-0.93,0.68-1.54,0.68H55.97c0.15,2.66,0.46,4.82,0.91,6.49c0.46,1.67,1.1,2.98,1.93,3.92
            c0.83,0.95,1.88,1.59,3.13,1.93c1.25,0.34,2.75,0.51,4.49,0.51c1.82,0,3.45-0.23,4.89-0.68c1.44-0.46,2.81-1.02,4.1-1.71
            c1.06-0.61,2.24-0.78,3.53-0.51c1.29,0.27,2.28,0.93,2.96,1.99l1.93,3.19c0.68,1.06,0.89,2.2,0.63,3.41
            C84.21,89.53,83.54,90.44,82.48,91.05z M70.99,58.85c-0.08-2.35-0.21-4.34-0.4-5.97c-0.19-1.63-0.57-2.98-1.14-4.04
            c-0.57-1.06-1.33-1.84-2.28-2.33c-0.95-0.49-2.18-0.74-3.7-0.74c-1.29,0-2.39,0.19-3.3,0.57c-0.91,0.38-1.65,1.06-2.22,2.05
            c-0.57,0.99-1.02,2.33-1.37,4.04c-0.34,1.71-0.55,3.85-0.63,6.43H70.99z"
            />
            <path
                fill={LogoColor}
                d="M122.07,95c-7.33,0-13.32-4.03-16.98-8.18l-1.1,4.64c-0.37,1.34-1.59,2.32-3.05,2.32h-0.24
            c-1.34,0-2.44-1.1-2.44-2.44V9.12c0-1.47,1.22-2.69,2.69-2.69h1.95c1.47,0,2.69,1.22,2.69,2.69v33.59
            c4.03-4.28,10.02-8.55,16.98-8.55c11.61,0,19.79,8.19,19.79,30.42C142.35,85.6,135.26,95,122.07,95z M120.73,88.65
            c9.16,0,13.93-5.38,13.93-23.46c0-19.55-4.4-24.68-13.19-24.68c-6.72,0-11.61,4.15-15.88,8.06v32.86
            C109.85,85.72,114.86,88.65,120.73,88.65z"
            />
            <path
                fill={LogoColor}
                d="M174.36,95c-13.56,0-23.33-8.67-23.33-30.3c0-21.75,9.53-30.54,23.09-30.54c13.44,0,23.46,8.67,23.46,30.42
            C197.57,86.21,188.04,95,174.36,95z M174.36,89.14c10.14,0,15.51-5.99,15.51-24.31c0-18.45-5.5-24.8-15.64-24.8
            c-10.14,0-15.51,5.99-15.51,24.43C158.72,82.91,164.22,89.14,174.36,89.14z"
            />
            <path
                fill={LogoColor}
                d="M209.66,93.78c-1.47,0-2.69-1.22-2.69-2.69V37.83c0-1.34,1.1-2.44,2.44-2.44h0.24c1.34,0,2.69,0.98,2.93,2.44
            l1.22,5.99c5.74-5.62,11.85-9.65,19.06-9.65c8.31,0,14.42,4.89,14.42,15.03v41.9c0,1.47-1.22,2.69-2.69,2.69h-1.95
            c-1.47,0-2.69-1.22-2.69-2.69V51.14c0-7.82-2.81-10.51-8.8-10.51c-5.86,0-11.61,3.79-16.86,8.43v42.02c0,1.47-1.22,2.69-2.69,2.69
            H209.66z"
            />
            <path
                fill={LogoColor}
                d="M274.29,95c-8.43,0-14.78-5.01-14.78-15.39V38.07c0-1.47,1.22-2.69,2.69-2.69h1.83
            c1.47,0,2.69,1.22,2.69,2.69v39.58c0,7.94,3.18,10.87,9.04,10.87s11.48-3.42,16.86-7.82V38.07c0-1.47,1.22-2.69,2.69-2.69h1.95
            c1.47,0,2.69,1.22,2.69,2.69v53.14c0,1.47-1.1,2.57-2.44,2.57h-0.24c-1.47,0-2.81-1.1-3.05-2.44l-1.22-5.38
            C287.48,91.21,281.49,95,274.29,95z"
            />
            <path
                fill={LogoColor}
                d="M326.08,95c-6.6,0-12.46-2.08-16.61-4.76c-1.22-0.85-1.59-2.44-0.86-3.79l0.37-0.61
            c0.73-1.34,2.44-1.71,3.67-0.98c3.91,2.44,8.18,4.03,13.56,4.03c8.67,0,12.22-3.91,12.22-10.26c0-7.08-3.18-9.16-13.19-12.34
            c-8.67-2.69-15.15-6.72-15.15-17.22c0-8.19,5.62-14.9,17.22-14.9c5.74,0,10.02,1.1,13.56,2.81c1.22,0.61,1.83,2.2,1.22,3.54
            l-0.37,0.86c-0.61,1.34-2.32,1.95-3.66,1.34c-3.18-1.47-6.23-2.32-10.63-2.32c-6.6,0-10.38,2.69-10.38,8.55
            c0,6.35,2.93,8.06,11.12,10.75c9.77,3.18,17.35,6.47,17.35,18.57C345.51,89.63,338.3,95,326.08,95z"
            />
            <path
                fill={ColorPrimary}
                d="M369.19,10.55c1.82,1.14,2.16,0.22,3.92-0.2c3.91-0.92,8.58,0.03,11.37,3.08c5.05,5.51-0.46,11.34-5.5,14.37
            c-3.27,1.96-6.82,3.35-10.47,4.32c-5.86,1.55-11.75,1.92-15.63-3.63c-3.34-4.79-4.72-11.34-5.01-17.05
            c-0.16-3.22,0.98-8.61,4.14-10.16c5.87-2.87,11.42-0.77,14.3,4.87C367.05,7.58,367.76,9.66,369.19,10.55"
            />
        </svg>
    );
};

export default TebonusLogo;
