import { AxiosRequestConfig, AxiosResponse } from "axios";
import { Reducer } from "redux";
import dayjs from "dayjs";
import { AllReduxActions, AxiosRequestError } from "../types";
import { Promotion, BaseType, ReduxBaseType, ErrorStatusCode, ActivePromotionList } from "../types";
import RequestStatus from "../utils/RequestStatus";

const GET_MONTH_PROMOTIONS = "GET_MONTH_PROMOTIONS";
const GET_MONTH_PROMOTIONS_SUCCESS = "GET_MONTH_PROMOTIONS_SUCCESS";
const GET_MONTH_PROMOTIONS_FAIL = "GET_MONTH_PROMOTIONS_FAIL";
const CLEAR_PROMOTIONS_LOADING_STATE = "CLEAR_PROMOTIONS_LOADING_STATE";
const ACTIVATE_PROMOTIONS = "ACTIVATE_PROMOTIONS_FOR_CUSTOMER";
const ACTIVATE_PROMOTIONS_SUCCESS = "ACTIVATE_PROMOTIONS_FOR_CUSTOMER_SUCCESS";
const ACTIVATE_PROMOTIONS_FAIL = "ACTIVATE_PROMOTIONS_FOR_CUSTOMER_FAIL";
const GET_WEEK_PROMOTIONS = "GET_WEEK_PROMOTIONS";
const GET_WEEK_PROMOTIONS_SUCCESS = "GET_WEEK_PROMOTIONS_SUCCESS";
const GET_WEEK_PROMOTIONS_FAIL = "GET_WEEK_PROMOTIONS_FAIL";
const VALIDATE_MULTIPLIER_COLLISIONS = "VALIDATE_MULTIPLIER_COLLISIONS";
const VALIDATE_MULTIPLIER_COLLISIONS_SUCCESS = "VALIDATE_MULTIPLIER_COLLISIONS_SUCCESS";
const VALIDATE_MULTIPLIER_COLLISIONS_FAIL = "VALIDATE_MULTIPLIER_COLLISIONS_FAIL";
const CLEAR_MULTIPLIER_COLLISIONS = "CLEAR_MULTIPLIER_COLLISIONS";
const CLEAR_ALL_PROMOTIONS = "CLEAR_ALL";

const IMAGE_MAX_SIZE = 200000;

interface ValidateMultiplierCollisionsRequest {
    promotionIdentifiers: string[];
}

interface ActivatePromotionsResponse {
    tegutPromotionIdentifiers: string[];
}

interface GetPromotionsResponse {
    promotions: Promotion[];
    lastUpdate: number;
    arePromotionsLocked: boolean;
    nextChangeDate: string;
}

interface ValidateMultiplierCollisionsResponse {
    isCollisionFound: boolean;
    promotionIdentifiers: string[];
}

export interface GetMonthPromotionsAction {
    type: typeof GET_MONTH_PROMOTIONS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetMonthPromotionsSuccessAction {
    type: typeof GET_MONTH_PROMOTIONS_SUCCESS;
    payload: AxiosResponse<GetPromotionsResponse>;
}

interface GetMonthPromotionsFailAction {
    type: typeof GET_MONTH_PROMOTIONS_FAIL;
    error: AxiosRequestError;
}

interface ValidateMultiplierCollisionsAction {
    type: typeof VALIDATE_MULTIPLIER_COLLISIONS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface ValidateMultiplierCollisionsSuccessAction {
    type: typeof VALIDATE_MULTIPLIER_COLLISIONS_SUCCESS;
    payload: AxiosResponse<ValidateMultiplierCollisionsResponse>;
}

interface ValidateMultiplierCollisionsFailAction {
    type: typeof VALIDATE_MULTIPLIER_COLLISIONS_FAIL;
    error: AxiosRequestError;
}

interface ClearLoadingStateAction {
    type: typeof CLEAR_PROMOTIONS_LOADING_STATE;
}

interface ActivatePromotionsAction {
    type: typeof ACTIVATE_PROMOTIONS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface ActivatePromotionsSuccessAction {
    type: typeof ACTIVATE_PROMOTIONS_SUCCESS;
    payload: AxiosResponse<ActivatePromotionsResponse>;
}

interface ActivatePromotionsFailAction {
    type: typeof ACTIVATE_PROMOTIONS_FAIL;
    error: AxiosRequestError;
}

export interface GetWeekPromotionsAction {
    type: typeof GET_WEEK_PROMOTIONS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetWeekPromotionsSuccessAction {
    type: typeof GET_WEEK_PROMOTIONS_SUCCESS;
    payload: AxiosResponse<GetPromotionsResponse>;
}

interface GetWeekPromotionsFailAction {
    type: typeof GET_WEEK_PROMOTIONS_FAIL;
    error: AxiosRequestError;
}

interface ClearMultiplierCollisionsAction {
    type: typeof CLEAR_MULTIPLIER_COLLISIONS;
}

interface ClearAllAction {
    type: typeof CLEAR_ALL_PROMOTIONS;
}

export interface PromotionState extends BaseType, ReduxBaseType {
    monthPromotions: Promotion[];
    weekPromotions: Promotion[];
    arePromotionsLocked: boolean;
    lastUpdateWeekPromotions: number;
    lastUpdateMonthPromotions: number;
    monthPromotionsLoading: boolean;
    weekPromotionsLoading: boolean;
    visitedWeeklyHitsDate: number;
    isActivating: boolean;
    nextChangeDate: string;
    areMultiplierCollisionsFound: boolean;
    collidedPromotionIdentifiers: string[];
}

export type KnownAction =
    | ActivatePromotionsAction
    | ActivatePromotionsSuccessAction
    | ActivatePromotionsFailAction
    | GetMonthPromotionsAction
    | GetMonthPromotionsSuccessAction
    | GetMonthPromotionsFailAction
    | GetWeekPromotionsAction
    | GetWeekPromotionsSuccessAction
    | GetWeekPromotionsFailAction
    | ValidateMultiplierCollisionsAction
    | ValidateMultiplierCollisionsSuccessAction
    | ValidateMultiplierCollisionsFailAction
    | ClearLoadingStateAction
    | ClearMultiplierCollisionsAction
    | ClearAllAction;

export const INITIAL_STATE: PromotionState = {
    weekPromotions: [],
    monthPromotions: [],
    monthPromotionsLoading: false,
    weekPromotionsLoading: false,
    arePromotionsLocked: false,
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
    lastUpdateWeekPromotions: 0,
    lastUpdateMonthPromotions: 0,
    visitedWeeklyHitsDate: 0,
    isActivating: false,
    nextChangeDate: "",
    performedAction: null,
    areMultiplierCollisionsFound: false,
    collidedPromotionIdentifiers: [],
};

interface ActivatePromotionsAction {
    type: typeof ACTIVATE_PROMOTIONS;
    payload: {
        request: AxiosRequestConfig;
    };
}

export const filterActivePromotions = (promotions: Promotion[]): ActivePromotionList => {
    if (promotions == null) {
        return {
            promotions: [],
        };
    }
    const activePromotions = promotions.filter(p => dayjs().isBetween(dayjs(p.startDate), dayjs(p.endDate)));
    return { promotions: activePromotions };
};

/**
 * Filter for active and activated Promotions.
 * Needed to display only the activated Promotions when the selection was made.
 * Gets a list of @param promotions and @returns only the active and activated Promotions
 **/
export const filterActiveAndActivatedPromotions = (promotions: Promotion[]): ActivePromotionList => {
    if (promotions == null) {
        return {
            promotions: [],
        };
    }
    const activePromotions = promotions.filter(p => dayjs().isBetween(dayjs(p.startDate), dayjs(p.endDate)) && p.isActivated);

    return { promotions: activePromotions };
};

// Reducer
export const reducer: Reducer<PromotionState> = (state = INITIAL_STATE, action: KnownAction): PromotionState => {
    switch (action.type) {
        case ACTIVATE_PROMOTIONS: {
            return {
                ...state,
                isActivating: true,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.ACTIVATE_PROMOTIONS,
            };
        }
        case ACTIVATE_PROMOTIONS_FAIL: {
            return {
                ...state,
                isActivating: false,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.ACTIVATE_PROMOTIONS_FAIL,
            };
        }
        case ACTIVATE_PROMOTIONS_SUCCESS: {
            const { tegutPromotionIdentifiers } = action.payload.data;
            return {
                ...state,
                nextChangeDate: dayjs().add(1, "month").startOf("month").startOf("day").format(),
                arePromotionsLocked: true,
                isActivating: false,
                monthPromotions: state.monthPromotions.reduce((previous, current) => {
                    if (tegutPromotionIdentifiers.includes(current.tegutPromotionIdentifier)) {
                        current.isActivated = true;
                        return [...previous, current];
                    }
                    return previous;
                }, []),
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.ACTIVATE_PROMOTIONS_SUCCESS,
            };
        }

        case GET_MONTH_PROMOTIONS: {
            return {
                ...state,
                monthPromotionsLoading: true,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_MONTH_PROMOTIONS,
            };
        }
        case GET_MONTH_PROMOTIONS_FAIL: {
            return {
                ...state,
                monthPromotionsLoading: false,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_MONTH_PROMOTIONS_FAIL,
            };
        }
        case GET_MONTH_PROMOTIONS_SUCCESS: {
            const { promotions, arePromotionsLocked, nextChangeDate } = action.payload.data;
            promotions.forEach(element => (element.image?.length > IMAGE_MAX_SIZE ? (element.image = "") : element));

            return {
                ...state,
                arePromotionsLocked,
                monthPromotionsLoading: false,
                monthPromotions: promotions,
                lastUpdateMonthPromotions: dayjs().unix(),
                nextChangeDate,
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_MONTH_PROMOTIONS_SUCCESS,
            };
        }

        case GET_WEEK_PROMOTIONS: {
            return {
                ...state,
                weekPromotionsLoading: true,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_WEEK_PROMOTIONS,
            };
        }
        case GET_WEEK_PROMOTIONS_FAIL: {
            return {
                ...state,
                weekPromotionsLoading: false,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_WEEK_PROMOTIONS_FAIL,
            };
        }

        case GET_WEEK_PROMOTIONS_SUCCESS: {
            const { promotions } = action.payload.data;
            promotions.forEach(element => (element.image?.length > IMAGE_MAX_SIZE ? (element.image = "") : element));
            return {
                ...state,
                weekPromotionsLoading: false,
                weekPromotions: promotions,
                lastUpdateWeekPromotions: dayjs().unix(),
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_WEEK_PROMOTIONS_SUCCESS,
            };
        }

        case VALIDATE_MULTIPLIER_COLLISIONS: {
            return {
                ...state,
                areMultiplierCollisionsFound: false,
                collidedPromotionIdentifiers: [],
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.VALIDATE_MULTIPLIER_COLLISIONS,
            };
        }

        case VALIDATE_MULTIPLIER_COLLISIONS_SUCCESS: {
            const { isCollisionFound, promotionIdentifiers } = action.payload.data;

            return {
                ...state,
                areMultiplierCollisionsFound: isCollisionFound,
                collidedPromotionIdentifiers: promotionIdentifiers,
                isLoading: false,
                performedAction: AllReduxActions.VALIDATE_MULTIPLIER_COLLISIONS_SUCCESS,
            };
        }

        case VALIDATE_MULTIPLIER_COLLISIONS_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.VALIDATE_MULTIPLIER_COLLISIONS_FAIL,
            };
        }

        case CLEAR_PROMOTIONS_LOADING_STATE: {
            return {
                ...state,
                isActivating: false,
                weekPromotionsLoading: false,
                monthPromotionsLoading: false,
                isLoading: false,
                performedAction: AllReduxActions.CLEAR_PROMOTIONS_LOADING_STATE,
            };
        }

        case CLEAR_MULTIPLIER_COLLISIONS: {
            return {
                ...state,
                areMultiplierCollisionsFound: false,
                collidedPromotionIdentifiers: [],
            };
        }

        case CLEAR_ALL_PROMOTIONS: {
            return {
                ...state,
            };
        }

        default:
            return state;
    }
};

export enum PromotionType {
    Month = 0,
    Week = 1,
}

// Action Creators

export const actionCreators = {
    getMonthPromotions: (): GetMonthPromotionsAction =>
        <GetMonthPromotionsAction>{
            type: GET_MONTH_PROMOTIONS,
            payload: {
                request: {
                    method: "GET",
                    url: `/GetPromotions/${PromotionType.Month}`,
                },
            },
        },

    activatePromotions: (promotions: string[]): ActivatePromotionsAction =>
        <ActivatePromotionsAction>{
            type: ACTIVATE_PROMOTIONS,
            payload: {
                request: {
                    method: "POST",
                    url: "/ActivatePromotions",
                    data: {
                        tegutPromotionIdentifiers: promotions,
                    },
                },
            },
        },

    getWeekPromotions: (): GetWeekPromotionsAction =>
        <GetWeekPromotionsAction>{
            type: GET_WEEK_PROMOTIONS,
            payload: {
                request: {
                    method: "GET",
                    url: `/GetPromotions/${PromotionType.Week}`,
                },
            },
        },

    validateMultiplierCollisions: (requestData: ValidateMultiplierCollisionsRequest): ValidateMultiplierCollisionsAction =>
        <ValidateMultiplierCollisionsAction>{
            type: VALIDATE_MULTIPLIER_COLLISIONS,
            payload: {
                request: {
                    method: "POST",
                    url: "/ValidateMultiplierPromotionsCollisions",
                    data: requestData,
                },
            },
        },

    clearPromotionsLoadingState: (): ClearLoadingStateAction =>
        <ClearLoadingStateAction>{
            type: CLEAR_PROMOTIONS_LOADING_STATE,
        },
    clearMultiplierCollisions: (): ClearMultiplierCollisionsAction =>
        <ClearMultiplierCollisionsAction>{
            type: CLEAR_MULTIPLIER_COLLISIONS,
        },
    clearAll: (): ClearAllAction =>
        <ClearAllAction>{
            type: CLEAR_ALL_PROMOTIONS,
        },
};

export type PromotionActions = typeof actionCreators;
