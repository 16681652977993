import IdentityPage from "components/IdentityPage";
import React, { FunctionComponent, ReactElement } from "react";
import { getAppSetting } from "utils/AppSettings";

const REACT_APP_IDENTITY_SERVER_URL = getAppSetting("REACT_APP_IDENTITY_SERVER_URL");

const ChangeEmail: FunctionComponent = (): ReactElement => {
    const changeEmailURL = `${REACT_APP_IDENTITY_SERVER_URL}/Identity/Account/manage/Email`;

    return (
        <IdentityPage
            pageTitle="E-Mail-Adresse ändern"
            pageTextPreButton="Bitte ändern Sie Ihre E-Mail-Adresse "
            buttonText="im Tab E-Mail-Adresse ändern."
            pageTextPostButton=" Es geht ein neuer Tab auf, den Sie danach einfach wieder schließen können."
            useDefaultUrlParameters={true}
            baseUrl={changeEmailURL}
        />
    );
};

export default ChangeEmail;
