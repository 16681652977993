/**
 * @category AuthHelper
 */

import {
    AuthorizationServiceConfiguration,
    AuthorizationRequest,
    RedirectRequestHandler,
    FetchRequestor,
    LocalStorageBackend,
    DefaultCrypto,
    BaseTokenRequestHandler,
    AuthorizationNotifier,
    TokenRequest,
    GRANT_TYPE_AUTHORIZATION_CODE,
    AuthorizationRequestHandler,
    RevokeTokenRequest,
    StringMap,
    TokenResponse,
    GRANT_TYPE_REFRESH_TOKEN,
    TokenTypeHint,
} from "@openid/appauth";
import jwtDecode from "jwt-decode";
import { AccessTokenData } from "types";
import axios, { AxiosError } from "axios";
import buildUrl from "build-url-ts";
import { getAppSetting } from "../utils/AppSettings";
import { NoHashQueryStringUtils } from "./NoHashQueryStringUtils";

const REACT_APP_IDENTITY_SERVER_URL = getAppSetting("REACT_APP_IDENTITY_SERVER_URL") as string;
const REACT_APP_REDIRECT_URI = getAppSetting("REACT_APP_REDIRECT_URI") as string;
const REACT_APP_CLIENT_ID = getAppSetting("REACT_APP_CLIENT_ID") as string;
const REACT_APP_POST_LOGOUT_REDIRECT_URI = getAppSetting("REACT_APP_POST_LOGOUT_REDIRECT_URI") as string;
const REACT_APP_IDP_LOGOUT_RETURN_URI = getAppSetting("REACT_APP_IDP_LOGOUT_RETURN_URI") as string;

/**
 * Defines the configuration for different types of Requests (AuthorizationRequest, TokenRequest)
 * @constant
 * @category AuthHelper
 * @type {object}
 */
const requestTemplate = {
    clientId: `${REACT_APP_CLIENT_ID}`,
    identityServer: `${REACT_APP_IDENTITY_SERVER_URL}`,
    redirectURL: `${REACT_APP_REDIRECT_URI}`,
    scope: "openid profile offline_access IdentityServerApi",
    extras: {
        prompt: "select_account",
        access_type: "code",
        registrationSource: "tebonus",
    },
};

export const invokeEndSession = (idToken: string): void => {
    const endSessionUrl = buildUrl(REACT_APP_IDENTITY_SERVER_URL, {
        path: "connect/endsession",
        queryParams: {
            id_token_hint: idToken,
            post_logout_redirect_uri: REACT_APP_POST_LOGOUT_REDIRECT_URI,
        },
    });

    axios
        .get(endSessionUrl)
        .then(() => {
            return;
        })
        .catch(() => {
            throw new AxiosError("Communication to endsession failed");
        });
};

export const invokeIdpLogout = (): void => {
    const idpLogoutUrl = buildUrl(REACT_APP_IDENTITY_SERVER_URL, {
        path: "Identity/Account/Logout",
        queryParams: {
            returnUrl: REACT_APP_IDP_LOGOUT_RETURN_URI,
        },
    });

    window.location.href = idpLogoutUrl;
};

/**
 *  Represents an RedirectRequestHandler which uses a standard
 *  redirect based code flow. This object is responsible to handle the redirect task
 * @function
 * @see {@link https://github.com/openid/AppAuth-JS}
 * @see {@link https://dev.to/kdhttps/appauth-js-integration-in-react-1m3e}
 * @category AuthHelper
 * @returns {RedirectRequestHandler}
 */
export const getAuthorizationHandler = (): RedirectRequestHandler => {
    return new RedirectRequestHandler(
        new LocalStorageBackend(),
        new NoHashQueryStringUtils(),
        window.location,
        new DefaultCrypto()
    );
};

/**
 * The default token request handler
 * @function
 * @see {@link https://github.com/openid/AppAuth-JS}
 * @see {@link https://dev.to/kdhttps/appauth-js-integration-in-react-1m3e}
 * @category AuthHelper
 * @returns {BaseTokenRequestHandler}
 */
export const getTokenHandler = (): BaseTokenRequestHandler => {
    return new BaseTokenRequestHandler(new FetchRequestor());
};

/**
 * Fetch configuration details required to interact with an authorization service
 * @function
 * @see {@link https://github.com/openid/AppAuth-JS}
 * @see {@link https://dev.to/kdhttps/appauth-js-integration-in-react-1m3e}
 * @category AuthHelper
 * @returns {Promise<AuthorizationServiceConfiguration>}
 */
export const fetchServiceConfiguration = (): Promise<AuthorizationServiceConfiguration> => {
    try {
        return AuthorizationServiceConfiguration.fetchFromIssuer(requestTemplate.identityServer, new FetchRequestor());
    } catch (err) {
        return err;
    }
};

/**
 * Once this code executes, it will redirect you to Server,
 * OP Server will authentiate the user and redirect back to your side
 * with code in URL.
 * @function
 * @see {@link https://github.com/openid/AppAuth-JS}
 * @see {@link https://dev.to/kdhttps/appauth-js-integration-in-react-1m3e}
 * @param {AuthorizationServiceConfiguration} config
 * @category AuthHelper
 * @returns {Promise<boolean>}
 */
export const makeAuthorizationRequest = async (
    config: AuthorizationServiceConfiguration,
    isRegistration = false
): Promise<boolean> => {
    if (!config) {
        return false;
    }

    const authorizationHandler: AuthorizationRequestHandler = getAuthorizationHandler();

    const request = new AuthorizationRequest({
        client_id: requestTemplate.clientId,
        redirect_uri: requestTemplate.redirectURL,
        scope: requestTemplate.scope,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state: undefined,
        extras: requestTemplate.extras,
    });

    if (isRegistration) {
        request.extras.suppressed_prompt = "select_account";
    }

    try {
        await authorizationHandler.performAuthorizationRequest(config, request);
        return true;
    } catch (err) {
        return false;
    }
};

/**
 * Makes token request using code
 * @function
 * @see {@link https://github.com/openid/AppAuth-JS}
 * @see {@tutorial https://dev.to/kdhttps/appauth-js-integration-in-react-1m3e}
 * @category AuthHelper
 * @returns {Promise<TokenResponse>}
 */
export const makeTokenRequestUsingCode = async (): Promise<TokenResponse> => {
    const tokenHandler: BaseTokenRequestHandler = getTokenHandler();
    const authorizationHandler: RedirectRequestHandler = getAuthorizationHandler();
    const notifier: AuthorizationNotifier = new AuthorizationNotifier();
    authorizationHandler.setAuthorizationNotifier(notifier);
    const serviceConfiguration: AuthorizationServiceConfiguration = await fetchServiceConfiguration();

    return new Promise((resolve, reject) => {
        notifier.setAuthorizationListener((request, response) => {
            let tokenRequest: TokenRequest | null = null;
            let extras: StringMap | undefined;

            if (response != null && response.code) {
                if (request && request.internal) {
                    extras = {};
                    extras.code_verifier = request.internal.code_verifier;
                    extras.access_type = "code";
                    extras.registrationSource = "tebonus";
                    extras.prompt = "select_account";
                }

                tokenRequest = new TokenRequest({
                    client_id: requestTemplate.clientId,
                    redirect_uri: requestTemplate.redirectURL,
                    grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
                    code: response.code,
                    refresh_token: undefined,
                    extras,
                });

                tokenHandler.performTokenRequest(serviceConfiguration, tokenRequest).then(tokenResponse => {
                    if (tokenResponse) {
                        resolve(tokenResponse);
                    } else {
                        reject("Token Response Error");
                    }
                });
            }
        });

        authorizationHandler.completeAuthorizationRequestIfPossible();
    });
};

/**
 * The function which gets access token using refresh token
 * @function
 * @param {string} token refresh token
 * @see {@link https://github.com/openid/AppAuth-JS}
 * @see {@link https://dev.to/kdhttps/appauth-js-integration-in-react-1m3e}
 * @category AuthHelper
 * @returns {Promise<TokenResponse>}
 */
export const getAccessTokenUsingRefreshToken = async (token: string): Promise<TokenResponse> => {
    const tokenHandler: BaseTokenRequestHandler = getTokenHandler();
    const authorizationHandler: RedirectRequestHandler = getAuthorizationHandler();
    const notifier: AuthorizationNotifier = new AuthorizationNotifier();
    authorizationHandler.setAuthorizationNotifier(notifier);
    const serviceConfiguration: AuthorizationServiceConfiguration = await fetchServiceConfiguration();

    return new Promise((resolve, reject) => {
        const tokenRequest = new TokenRequest({
            client_id: requestTemplate.clientId,
            redirect_uri: requestTemplate.redirectURL,
            grant_type: GRANT_TYPE_REFRESH_TOKEN,
            code: undefined,
            refresh_token: token,
            extras: undefined,
        });

        tokenHandler
            .performTokenRequest(serviceConfiguration, tokenRequest)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
        authorizationHandler.completeAuthorizationRequestIfPossible();
    });
};

/**
 * The function causes the removal of the client permissons associated with the specified token
 * @function
 * @see {@link https://github.com/openid/AppAuth-JS}
 * @see {@link https://dev.to/kdhttps/appauth-js-integration-in-react-1m3e}
 * @param {string} token access_token or refresh_token value
 * @param {TokenTypeHint} tokenType | 'access_token' | 'refresh_token' type
 * @category AuthHelper
 * @returns {Promise<boolean>}
 */
export const revokeToken = async (token: string, tokenType: TokenTypeHint): Promise<boolean> => {
    const tokenHandler: BaseTokenRequestHandler = getTokenHandler();
    const serviceConfiguration: AuthorizationServiceConfiguration = await fetchServiceConfiguration();

    const tokenRevokeRequest = new RevokeTokenRequest({
        token,
        token_type_hint: tokenType,
        client_id: requestTemplate.clientId,
    });

    return new Promise((resolve, reject) => {
        tokenHandler
            .performRevokeTokenRequest(serviceConfiguration, tokenRevokeRequest)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const isTokenTebonusTerminated = (token: string): boolean => {
    const decoded = jwtDecode(token) as AccessTokenData;
    return decoded && decoded.tebonus_terminated === true;
};
