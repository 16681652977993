import { Buffer } from "buffer";
import React, { FunctionComponent, ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "store/configureStore";
import PageBase from "components/Shared/PageBase";

const Imprint: FunctionComponent = (): ReactElement => {
    const documentsState = useSelector((state: AppState) => state.documentsReducer);

    useEffect(() => {
        // get the imprint object if it is there
        const base64Impressum = documentsState.documents?.find(item => item.name === "Impressum.html");

        if (base64Impressum) {
            // get the html imprint data and decode it from base64 to string
            const imprint = Buffer.from(base64Impressum.data, "base64").toString();

            if (imprint) {
                // Initialize the DOM parser
                const parser = new DOMParser();
                // Parse the text to an document
                const doc = parser.parseFromString(imprint, "text/html");
                // Hide the first element with an <h1> tag
                doc.querySelector("h1").style.display = "none";
                // select only the <body> without the header and css
                const docArticle = doc.querySelector("body").innerHTML;
                // place the selected html code into the container with the legalDocuments id.
                document.querySelector("#legalDocuments").innerHTML = docArticle;
            }
        }

        if (!base64Impressum && !documentsState.isLoading) {
            document.querySelector("#legalDocuments").innerHTML =
                "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.";
        }
    }, [documentsState.documents]);

    return (
        <PageBase
            title="Impressum"
            isContentLoading={documentsState.isLoading}
            contentLoadingText="Impressum wird geladen."
            showLoadingIndicator={true}
        >
            <div className="content-in-tile container-md">
                <div className="scrollContainer-md table-scroll-bar overflow-y-md">
                    {/* The imprin will be placed inside of this container */}
                    <div id="legalDocuments" className="d-flex flex-column align-items-center text-center mt-3 mt-md-0"></div>
                </div>
            </div>
        </PageBase>
    );
};

export default Imprint;
