import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import React, { FunctionComponent, ReactElement } from "react";

interface OptInErrorModalProps {
    onOptInErrorRetryButtonClick: () => void;
    setHasOptInError: (hasError: boolean) => void;
}

export const OptInErrorModal: FunctionComponent<OptInErrorModalProps> = (props): ReactElement => {
    return (
        <ModalConfirmInfo
            key="optinerror"
            titleText="Etwas ist schiefgelaufen"
            bodyText="Ihre Aktion konnte leider nicht bearbeitet werden. Bitte sorgen Sie für eine stabile Internetverbindung."
            button1Text="Erneut versuchen"
            button1function={() => props.onOptInErrorRetryButtonClick()}
            button2Text="Abbrechen"
            button2function={() => props.setHasOptInError(false)}
        />
    );
};
