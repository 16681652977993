import IdentityPage from "components/IdentityPage";
import { getAppSetting } from "utils/AppSettings";
import React, { FunctionComponent, ReactElement } from "react";

const REACT_APP_IDENTITY_SERVER_URL = getAppSetting("REACT_APP_IDENTITY_SERVER_URL");

const ChangePersonalData: FunctionComponent = (): ReactElement => {
    const changePersonalDataURL = `${REACT_APP_IDENTITY_SERVER_URL}/Identity/Account/manage`;

    return (
        <IdentityPage
            pageTitle="Persönliche Daten"
            pageTextPreButton="Bitte prüfen Sie Ihre persönlichen Daten "
            buttonText="im Tab Persönliche Daten."
            pageTextPostButton=" Es geht ein neuer Tab auf, den Sie danach einfach wieder schließen können."
            useDefaultUrlParameters={true}
            baseUrl={changePersonalDataURL}
        />
    );
};

export default ChangePersonalData;
