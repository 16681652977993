import { TebonusArrowRightSharp } from "components/_graphics";
import React, { Fragment, FunctionComponent, ReactElement } from "react";
import { Faq } from "types";

interface FaqItemProps {
    key: number;
    faq: Faq;
    isFaqExpanded: boolean;
    onToggleFaqButtonClick: (id: number, title: string) => void;
}

const FaqItem: FunctionComponent<FaqItemProps> = (props: FaqItemProps): ReactElement => {
    return (
        <Fragment key={props.key}>
            <tr
                className={`${props.isFaqExpanded && "bg-transparentGray"} cursor-pointer`}
                onClick={() => props.onToggleFaqButtonClick(props.faq.id, props.faq.title)}
            >
                <td className="pl-2">
                    <h5 className="font-FAQs-questions mb-0 pt-1">{props.faq.title}</h5>
                </td>
                {/* toggle button */}
                <td className="text-right pr-4">
                    <button
                        className={`icon-accent-wrapper icon-accent-lightGray ${
                            props.isFaqExpanded ? "rotateArrow-90" : "rotateArrow-0"
                        }`}
                        onClick={() => props.onToggleFaqButtonClick(props.faq.id, props.faq.title)}
                        aria-label={props.isFaqExpanded ? "Schließen" : "Erweitern"}
                    >
                        <TebonusArrowRightSharp />
                    </button>
                </td>
            </tr>
            {props.isFaqExpanded && (
                <tr className="paddingNoBorderTable">
                    <td>
                        <p id={`FAQsBody${props.faq.id}`} className="pl-2 px-md-4 font-FAQs-answers embedded-html-body"></p>
                    </td>
                    <td></td>
                </tr>
            )}
        </Fragment>
    );
};

export default FaqItem;
