import React, { FunctionComponent, ReactElement } from "react";
import { NewsContentPanel } from "types";

interface NewsContentPanelProps {
    slideIndex: number;
    contentPanel: NewsContentPanel;
}

const ContentPanel: FunctionComponent<NewsContentPanelProps> = (props): ReactElement => {
    return (
        <a
            key={`panel-${props.slideIndex}`}
            className="d-flex align-items-center justify-content-start h-100 dashboard-news-carousel link-no-hover"
            id={`news-slide-${props.slideIndex}`}
            href={props.contentPanel.link}
            target="_blank"
            rel="noopener noreferrer"
        >
            {props.contentPanel.image && (
                <div className="d-none d-md-block firstRowTile">
                    <img src={props.contentPanel.image} width="auto" height="100%" />
                </div>
            )}

            <div
                className={`d-none d-md-block h-100 ${props.contentPanel.image ? "pl-3 text-left" : "w-100 text-center"}`}
                style={{ paddingRight: 30, ...(props.contentPanel.image ? {} : { paddingLeft: 30 }) }}
            >
                <div className="truncate-4 pb-1 dashboardNewsTitle font-unitText-Medium">{props.contentPanel.title}</div>
                {/* deactivated since carousel does not support sub-texts yet */}
                {/* <div className="text-justify dashboard-tile-subheader">{props.contentPanel.subHeader}</div> */}
            </div>

            {props.contentPanel.image && (
                <div className="d-md-none d-flex w-100" style={{ maxHeight: "574px" }}>
                    <img src={props.contentPanel.image} width="100%" />
                </div>
            )}
        </a>
    );
};

export default ContentPanel;
