import React, { FunctionComponent, ReactElement } from "react";
import { Prompt } from "react-router-dom";

interface LeavePageModalProps {
    shownWhen: boolean;
    discardButtonTitle: string;
    returnButtonTitle: string;
    ignoringMessagePath?: string;
}

const LeavePageModal: FunctionComponent<LeavePageModalProps> = (props): ReactElement => {
    const LEAVE_PAGE_MODAL_CONTAINER_ID = "customLeavePagePromptContainer";
    const LEAVE_PAGE_MODAL_TITLE = "Möchten Sie Ihre Änderungen verwerfen?";
    const LEAVE_PAGE_MODAL_DESCRIPTION =
        "Sie haben Ihre Auswahl noch nicht gespeichert. Wenn Sie die Seite verlassen, werden Ihre Änderungen verworfen.";
    const LEAVE_PAGE_MODAL_POSITIVE_TEXT = "Änderung verwerfen";
    const LEAVE_PAGE_MODAL_NEGATIVE_TEXT = "Zur Auswahl zurückkehren";

    return (
        <div id={LEAVE_PAGE_MODAL_CONTAINER_ID}>
            <Prompt
                when={props.shownWhen}
                message={location => {
                    if (location.pathname === props.ignoringMessagePath) {
                        return true;
                    }

                    return JSON.stringify({
                        title: LEAVE_PAGE_MODAL_TITLE,
                        description: LEAVE_PAGE_MODAL_DESCRIPTION,
                        buttonPositiveText: LEAVE_PAGE_MODAL_POSITIVE_TEXT,
                        buttonNegativeText: LEAVE_PAGE_MODAL_NEGATIVE_TEXT,
                        discardButtonLogInfos: {
                            parameters: {
                                button_title: props.discardButtonTitle,
                            },
                        },
                        returnButtonLogInfos: {
                            parameters: {
                                button_title: props.returnButtonTitle,
                            },
                        },
                    });
                }}
            />
        </div>
    );
};

export default LeavePageModal;
