import React, { FunctionComponent } from "react";
import { Colors } from "../../../styles/";

interface TebonusHeartProps {
    size?: number | string;
    preserveAspectRatio?: boolean;
    color?: string;
}

const TebonusSingleHeart: FunctionComponent<TebonusHeartProps> = props => {
    const color = props.color != undefined ? props.color : Colors.white;
    const size = props.size ? props.size : "85%";
    const preserveAspectRatio = props.preserveAspectRatio ? props.preserveAspectRatio : false;

    if (preserveAspectRatio) {
        return (
            <svg width={size} height={size} viewBox="0 0 238 238">
                <path
                    fill={color}
                    d="M119.8,65c8.4,0,11.3-6.3,18.5-14.4c16-17.8,42-29.2,66.1-23.9c43.5,9.7,36.5,57,22.3,89
                c-9.2,20.8-21.9,39.7-36.4,56.9c-23.4,27.6-50.8,49.6-88.7,35.7c-32.7-12-61.8-39.2-82.8-66.2C6.9,127-6,96.8,4.2,78.4
                C23,44.3,57.3,35.6,90.7,53.3C99.1,57.8,109.7,65.5,119.8,65"
                />
            </svg>
        );
    }

    return (
        <svg width={size} height={size} viewBox="0 0 519.07 334.88">
            <path
                fill={color}
                d="M124.1,44.14c8.39,0,11.29-6.33,18.48-14.37c15.96-17.84,42.02-29.21,66.07-23.88
                c43.53,9.65,36.53,56.97,22.27,89c-9.24,20.77-21.85,39.73-36.37,56.9c-23.35,27.61-50.83,49.59-88.74,35.73
                c-32.71-11.97-61.84-39.23-82.79-66.16C11.23,106.19-1.64,75.97,8.52,57.58c18.83-34.1,53.16-42.85,86.54-25.12
                C103.46,36.93,114.08,44.65,124.1,44.14"
            />
        </svg>
    );
};

export default TebonusSingleHeart;
