import LeavePageModal from "components/LeavePageModal";
import React, { FunctionComponent, ReactElement } from "react";
import { SearchProps } from "types";
import { CommonButtonTitles } from "utils/FirebaseAnalytics";
import FilterModalMobile from "./FilterModalMobile";

interface ModalsProps {
    isFilterModalOpen: boolean;
    setShowModal: Function;
    setSelectedBrands: (value: SearchProps[]) => void;
    selectedBrands: SearchProps[];
    brandOptions: SearchProps[];
    setSelectedCategories: (value: SearchProps[]) => void;
    selectedCategories: SearchProps[];
    categoryOptions: SearchProps[];
}

const ArticleFinderModals: FunctionComponent<ModalsProps> = (props): ReactElement => {
    return (
        <>
            <LeavePageModal
                shownWhen={true}
                discardButtonTitle={CommonButtonTitles.multiplier.discardMultiplier}
                returnButtonTitle={CommonButtonTitles.multiplier.returnToMultiplier}
                ignoringMessagePath="/meintebonus/Lieblingsprodukte"
            />

            {props.isFilterModalOpen && (
                <FilterModalMobile
                    setShowModal={props.setShowModal}
                    setSelectedBrands={props.setSelectedBrands}
                    selectedBrands={props.selectedBrands}
                    brandOptions={props.brandOptions}
                    setSelectedCategories={props.setSelectedCategories}
                    selectedCategories={props.selectedCategories}
                    categoryOptions={props.categoryOptions}
                />
            )}
        </>
    );
};

export default ArticleFinderModals;
