import React, { FunctionComponent, ReactElement, useEffect } from "react";
import { News } from "types";
import { logAnalyticsSelectContent } from "utils/FirebaseAnalytics";

interface NewsItemsProps {
    currentNewsId: number;
    news: News[];
}

enum UrlFileType {
    html = "HTML",
    pdf = "PDF",
    unknown = "unknown",
}

const NewsItem: FunctionComponent<NewsItemsProps> = (props): ReactElement => {
    useEffect(() => {
        window.addEventListener("click", async e => await onUrlLinkClick(e));

        return window.removeEventListener("click", onUrlLinkClick);
    }, []);

    /**
     * * HTML-PARSING-UseEffect
     * every time new news is rendered, parse the body of the news item into html.
     * */
    useEffect(() => {
        if (props.currentNewsId) {
            // find the current news and get its body to be parsed into HTML.
            const parser = new DOMParser();
            const singleNewsBody = props.news.find(x => x.id === props.currentNewsId)?.body;
            const doc = parser.parseFromString(singleNewsBody, "text/html").body.innerHTML;

            const newsBodyWrapper = document.getElementById("newsBody");
            newsBodyWrapper.innerHTML = doc;

            // remove the first child of the body if its a white space node
            if (newsBodyWrapper.firstElementChild.innerHTML === "&nbsp;") {
                newsBodyWrapper.firstElementChild.remove();
            }
        }
    }, [props.currentNewsId]);

    const onUrlLinkClick = async (event: MouseEvent): Promise<void> => {
        if (event.target instanceof HTMLAnchorElement) {
            const href = event.target.getAttribute("href");
            const fileName = href.split("?")[0].split("/").pop();

            let fileType: UrlFileType;
            switch (fileName.split(".").pop()) {
                case "html":
                    fileType = UrlFileType.html;
                    break;
                case "pdf":
                    fileType = UrlFileType.pdf;
                    break;
                default:
                    fileType = UrlFileType.unknown;
            }

            await logAnalyticsSelectContent({
                content_type: fileType,
                item_id: fileName,
                kw: getCurrentWeek(),
                source: "web",
            });
        }
    };

    const getCurrentWeek = (): number => {
        const currentDate = new Date();

        currentDate.setUTCDate(currentDate.getUTCDate() + 4 - (currentDate.getUTCDay() || 7));

        const yearStart = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));

        // eslint-disable-next-line
        // @ts-ignore
        const weekNumber = Math.ceil(((currentDate - yearStart) / 86400000 + 1) / 7);

        return weekNumber;
    };

    const getCurrentNews = (): News => {
        return props.news.find(n => n.id === props.currentNewsId);
    };

    return props.currentNewsId ? (
        <div className="content-in-tile">
            <div className="d-flex justify-content-center px-3 px-lg-5 scrollContainer-md table-scroll-bar overflow-y-md">
                <div key={getCurrentNews().id} className="row">
                    <div className="col-12 col-md-9 m-auto m-lg-0 col-lg-6" aria-label="News Bild">
                        <img src={getCurrentNews().imagePathWeb} className="news-Img shadow" width="100%" />
                    </div>
                    <div className="col-12 col-lg-6 pl-lg-4 mt-4 mt-lg-0">
                        <h2 className="font-news-title mb-4">{getCurrentNews().title}</h2>
                        <div id="newsBody" className="font-description w-100"></div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default NewsItem;
