import dayjs from "dayjs";

/**
 * calculates a date for when the multipliers can be chosen again.
 * @export
 * @param {string} date the date to calculate.
 * @return {*}  {number} the new calculated multipliers date.
 */
export function calculateTimeUntilNewChoice(date: string): number {
    if (!date) {
        return null;
    }
    const parsedDate = dayjs(date);

    return Math.ceil(parsedDate.diff(dayjs(), "days", true));
}
