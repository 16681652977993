import { AxiosRequestConfig, AxiosResponse } from "axios";
import { Reducer } from "redux";
import { News, AxiosRequestError, BaseType, ErrorStatusCode, ReduxBaseType, AllReduxActions } from "../types";
import RequestStatus from "../utils/RequestStatus";

export const GET_NEWS = "pekuma/news/GET_NEWS";
export const GET_NEWS_SUCCESS = "pekuma/news/GET_NEWS_SUCCESS";
export const GET_NEWS_FAIL = "pekuma/news/GET_NEWS_FAIL";
export const CLEAR_NEWS_LOADING_STATE = "pekuma/news/CLEAR_NEWS_LOADING_STATE";

interface GetNewsResponse {
    news: News[];
    lastUpdate: number;
}

export interface GetNewsAction {
    type: typeof GET_NEWS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetNewsSuccessAction {
    type: typeof GET_NEWS_SUCCESS;
    payload: AxiosResponse<GetNewsResponse>;
}

interface GetNewsFailAction {
    type: typeof GET_NEWS_FAIL;
    error: AxiosRequestError;
}

export interface NewsState extends BaseType, ReduxBaseType {
    news: News[];
    lastUpdate: number;
}

interface ClearLoadingStateAction {
    type: typeof CLEAR_NEWS_LOADING_STATE;
}

export type KnownAction = GetNewsAction | GetNewsFailAction | GetNewsSuccessAction | ClearLoadingStateAction;

export type ErrorAction = GetNewsFailAction;

export const INITIAL_STATE: NewsState = {
    news: [],
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
    lastUpdate: 0,
    performedAction: null,
};

// Reducer
export const reducer: Reducer<NewsState> = (state = INITIAL_STATE, action: KnownAction): NewsState => {
    switch (action.type) {
        case GET_NEWS: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_NEWS,
            };
        }
        case GET_NEWS_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_NEWS_FAIL,
            };
        }
        case GET_NEWS_SUCCESS: {
            const { news, lastUpdate } = action.payload.data;
            return {
                ...state,
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                news: news.sort((a, b) => a.position - b.position),
                lastUpdate,
                performedAction: AllReduxActions.GET_NEWS_SUCCESS,
            };
        }
        case CLEAR_NEWS_LOADING_STATE: {
            return {
                ...state,
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.CLEAR_NEWS_LOADING_STATE,
            };
        }
        default:
            return state;
    }
};

// Action Creators
export const actionCreators = {
    getNews: (): GetNewsAction =>
        <GetNewsAction>{
            type: GET_NEWS,
            payload: {
                request: {
                    method: "GET",
                    url: "/GetNews",
                },
            },
        },

    clearLoadingState: (): ClearLoadingStateAction =>
        <ClearLoadingStateAction>{
            type: CLEAR_NEWS_LOADING_STATE,
        },
};

export type NewsActions = typeof actionCreators;
