import React, { FunctionComponent, useEffect, useState } from "react";
// needed if the language is changed to german

import LeavePageModal from "components/LeavePageModal";
import AnalyzeOptInModal from "components/AnalyzeOptInModal";
import { CommonButtonTitles } from "utils/FirebaseAnalytics";
import { INITIAL_STATE, PromotionState } from "../../../store/Promotions";
import { ErrorStatusCode } from "../../../types";
import { SaveSelectionModal } from "./SaveSelectionModal";
import { RetrySavingModal } from "./RetrySavingModal";
import { OptInErrorModal } from "./OptInErrorModal";
import { LoadingModal } from "./LoadingModal";

export enum ModalTypes {
    none,
    confirm,
    error,
    retryConfirmation,
}

interface ModalsProps {
    isAnalyzeOptInVisible: boolean;
    isCheckingInternetConnection: boolean;
    hasOptInError: boolean;

    currentPromotions: PromotionState;
    openedModal: ModalTypes;

    numberSelectedProducts: number;

    resetNumberOfSelectedProducts: () => void;
    activateSelectedPromotionFunction: () => void;
    setOpenedModalFunction: (modalTypes: ModalTypes) => void;
    onOptInErrorRetryButtonClick: () => void;
    handleOptInAgreement: () => void;
    setHasOptInError: (hasError: boolean) => void;
    setIsAnalyzeOptInVisible: (isVisible: boolean) => void;
}

const PromotionModals: FunctionComponent<ModalsProps> = props => {
    const [prevPromotions, setPrevPromotions] = useState<PromotionState>(INITIAL_STATE);

    // If the user selects an promotion product an event handler will be created. This event handler checks if the user wants to leave the page.
    // When the user wants to leave the page, it will be canceled and a default message of the browser will be shown.
    // The eventhandler will be reseted if the selection was voided.
    useEffect(() => {
        if (props.numberSelectedProducts > 0) {
            window.addEventListener("beforeunload", alertUser);
        }
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, [props.numberSelectedProducts]);

    const alertUser = e => {
        e.preventDefault();
        return (e.returnValue =
            "Sind Sie sich sicher, dass sie die Seite verlassen möchten? Nicht gespeicherte Änderungen gehen verloren.");
    };

    // will be called if something has changed in promotions
    useEffect(() => {
        checkAndSetWhichModalShouldBeOpened(props.currentPromotions);

        // set prev promotions if something has changed in the promotions
        setPrevPromotions(props.currentPromotions);
    }, [props.currentPromotions]);

    /**
     * With the current and previous promotionState status the corresponding modal will be shown.
     * @param promotions are the current promotion state
     */
    function checkAndSetWhichModalShouldBeOpened(promotions: PromotionState) {
        // case selection (successfull and error)
        if (prevPromotions.isActivating && !promotions.isActivating) {
            if (promotions.errorCode === ErrorStatusCode.noError) {
                // successfull selection -> Modal can be closed
                props.setOpenedModalFunction(ModalTypes.none);

                // reset the list of selected promotion products to prevent the info modal when leaving the page
                props.resetNumberOfSelectedProducts();
                return;
            }

            // error occured in saving the promotions. A reload modal should be shown.
            props.setOpenedModalFunction(ModalTypes.retryConfirmation);

            return;
        }
    }

    function userHasUnsavedPromotions() {
        if (props.openedModal !== ModalTypes.none) {
            return false;
        }
        return props.numberSelectedProducts !== 0;
    }

    return (
        <div>
            <LoadingModal
                isActivatingCurrentPromotions={props.currentPromotions.isActivating}
                areMonthPromotionsLoading={props.currentPromotions.monthPromotionsLoading}
                isCheckingInternetConnection={props.isCheckingInternetConnection}
            />

            {props.hasOptInError && (
                <OptInErrorModal
                    onOptInErrorRetryButtonClick={props.onOptInErrorRetryButtonClick}
                    setHasOptInError={props.setHasOptInError}
                />
            )}

            {props.isAnalyzeOptInVisible && (
                <AnalyzeOptInModal
                    key="analysisOptInModal"
                    handleAgreement={props.handleOptInAgreement}
                    setHasOptInError={props.setHasOptInError}
                    onCloseButtonClick={() => props.setIsAnalyzeOptInVisible(false)}
                />
            )}

            {/* its possible to make a default Prompt Modal out of it */}
            {/* If the user is leaving the current page React-Router prompt is throwing a default modal with the defined message. */}
            {/* Modal can be modified. Like it is shown here: https://medium.com/nerd-for-tech/custom-react-router-prompt-d325538b4d2b */}
            <LeavePageModal
                shownWhen={userHasUnsavedPromotions()}
                discardButtonTitle={CommonButtonTitles.promotions.discardPromotions}
                returnButtonTitle={CommonButtonTitles.promotions.returnToPromotions}
            />
            {props.openedModal === ModalTypes.confirm && (
                <SaveSelectionModal
                    numberSelectedProducts={props.numberSelectedProducts}
                    onSaveSelectionButton={() => props.activateSelectedPromotionFunction()}
                    onReturnSelectionButton={() => props.setOpenedModalFunction(ModalTypes.none)}
                />
            )}
            {props.openedModal === ModalTypes.retryConfirmation && (
                <RetrySavingModal
                    onSaveSelectionButton={() => props.activateSelectedPromotionFunction()}
                    onReturnButton={() => props.setOpenedModalFunction(ModalTypes.none)}
                />
            )}
        </div>
    );
};

export default PromotionModals;
