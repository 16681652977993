import React, { FunctionComponent } from "react";

interface LoadingIndicatorProps {
    isInAnalyzeOptInModal?: boolean;
    loadingText?: string;
    isPositionStatic?: boolean;
}

const LoadingIndicator: FunctionComponent<LoadingIndicatorProps> = (props: LoadingIndicatorProps) => {
    return props.isInAnalyzeOptInModal ? (
        <div className="spinner-border font-orange custom-spinner" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    ) : (
        <div className="w-100 d-flex justify-content-center">
            <div className={`spinner-border font-orange ${!props.isPositionStatic && "custom-spinner"}`} role="status">
                <span className="sr-only">{props.loadingText ?? "Loading..."}</span>
            </div>
            {props.loadingText && <span className="mt-5">{props.loadingText}</span>}
        </div>
    );
};

export default LoadingIndicator;
