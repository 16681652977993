import { TebonusArrowLeftSharp, TebonusArrowRightSharp } from "components/_graphics";
import React, { FunctionComponent, ReactElement } from "react";

type ButtonDirection = "left" | "right";

interface PaginationButtonProps {
    enabled: boolean;
    buttonDirection: ButtonDirection;
    onClick: () => void;
}

const PaginationButton: FunctionComponent<PaginationButtonProps> = (props): ReactElement => {
    return (
        <button
            className={`icon-accent-wrapper ${props.enabled ? "icon-accent-orange" : "icon-accent-lightGray"} mx-1`}
            onClick={() => props.onClick()}
            disabled={!props.enabled}
        >
            {props.buttonDirection === "left" ? (
                <>
                    <span className="mx-2">zurück</span>
                    <TebonusArrowLeftSharp />
                </>
            ) : (
                <>
                    <TebonusArrowRightSharp />
                    <span className="mx-2">weiter</span>
                </>
            )}
        </button>
    );
};

export default PaginationButton;
