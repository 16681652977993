import * as Auth from "./Auth";

import * as Promotion from "./Promotions";
import * as ArticleCategories from "./ArticleCategories";
import * as ArticleMultipliers from "./ArticleMultipliers";
import * as ArticleSearch from "./ArticleSearch";
import * as Customers from "./Customers";
import * as CustomerScore from "./CustomerScore";
import * as News from "./News";
import * as Markets from "./Markets";
import * as Faqs from "./Faqs";
import * as Documents from "./Documents";
import * as CardOverview from "./CardOverview";
import * as TrackingConsent from "./TrackingConsent";
import { AppState } from "./configureStore";

export const reducers = {
    authReducer: Auth.reducer,
    promotionReducer: Promotion.reducer,
    multiplierReducer: ArticleMultipliers.reducer,
    articleSearchReducer: ArticleSearch.reducer,
    articleCategoriesReducer: ArticleCategories.reducer,
    customerReducer: Customers.reducer,
    customerScoreReducer: CustomerScore.reducer,
    newsReducer: News.reducer,
    marketsReducer: Markets.reducer,
    faqsReducer: Faqs.reducer,
    documentsReducer: Documents.reducer,
    cardOverviewReducer: CardOverview.reducer,
    trackingConsentReducer: TrackingConsent.reducer,
};

export const actionCreators = {
    actionCreatorsAuth: Auth.actionCreators,
    actionCreatorPromotion: Promotion.actionCreators,
    actionCreatorArticleMultipliers: ArticleMultipliers.actionCreators,
    actionCreatorArticleSearch: ArticleSearch.actionCreators,
    actionCreatorArticleCategories: ArticleCategories.actionCreators,
    actionCreatorCustomers: Customers.actionCreators,
    actionCreatorCustomerScore: CustomerScore.actionCreators,
    actionCreatorNews: News.actionCreators,
    actionCreatorMarkets: Markets.actionCreators,
    actionCreatorsFaqs: Faqs.actionCreators,
    actionCreatorsDocuments: Documents.actionCreators,
    actionCreatorsCardOverview: CardOverview.actionCreators,
    actionCreatorsTrackingConsent: TrackingConsent.actionCreators,
};

export interface AppThunkAction<TAction, TDispatchResponse = void> {
    (dispatch: (action: TAction) => TDispatchResponse, getState: () => AppState): void;
}
