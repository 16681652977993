import { Reducer } from "redux";

const RESET_TRACKING_CONSENT = "RESET_TRACKING_CONSENT";

export interface TrackingConsentState {
    isConsentAccepted?: boolean;
}

interface ResetTrackingConsentAction {
    type: typeof RESET_TRACKING_CONSENT;
}

type KnownAction = ResetTrackingConsentAction;

export const INITIAL_STATE: TrackingConsentState = {
    isConsentAccepted: undefined,
};

// Reducer
export const reducer: Reducer<TrackingConsentState> = (state = INITIAL_STATE, action: KnownAction): TrackingConsentState => {
    switch (action.type) {
        case RESET_TRACKING_CONSENT: {
            return {
                ...state,
                isConsentAccepted: undefined,
            };
        }
        default:
            return state;
    }
};

// ActionCreators
export const actionCreators = {
    resetTrackingConsent: (): ResetTrackingConsentAction =>
        <ResetTrackingConsentAction>{
            type: RESET_TRACKING_CONSENT,
        },
};
