import IdentityPage from "components/IdentityPage";
import React, { FunctionComponent, ReactElement } from "react";
import { getAppSetting } from "../../utils/AppSettings";

const REACT_APP_IDENTITY_SERVER_URL = getAppSetting("REACT_APP_IDENTITY_SERVER_URL");

const ChangePassword: FunctionComponent = (): ReactElement => {
    const changePasswordURL = `${REACT_APP_IDENTITY_SERVER_URL}/Identity/Account/manage/ChangePassword`;

    return (
        <IdentityPage
            pageTitle="Passwort ändern"
            pageTextPreButton="Bitte ändern Sie Ihr Passwort"
            buttonText=" im Tab Passwort ändern."
            pageTextPostButton=" Es geht ein neuer Tab auf, den Sie danach einfach wieder schließen können."
            useDefaultUrlParameters={true}
            baseUrl={changePasswordURL}
        />
    );
};

export default ChangePassword;
