import dayjs from "dayjs";
import React, { FunctionComponent, ReactElement } from "react";

export const WeeklyHitsHeader: FunctionComponent = (): ReactElement => {
    function getValidFrom(): string {
        return dayjs().startOf("week").format("DD.MM.");
    }

    function getValidTo(): string {
        return dayjs().endOf("week").format("DD.MM.YYYY");
    }

    return (
        <div className="d-flex flex-column align-items-center mb-3">
            <div className="text-justify text-center font-subheading">Jede Woche aufs Neue punkten, sparen und freuen...</div>
            <div className="text-justify text-center font-description w-75">
                Mit den Wochen-Hits erhalten Sie jede Woche neue Angebote, auf die Sie noch mehr Punkte sammeln. Die Punkte
                werden Ihnen beim Kauf automatisch gutgeschrieben.
            </div>
            <div className="text-justify text-center font-subheading font-orange mt-3">
                Gültig vom {getValidFrom()} bis {getValidTo()}
            </div>
        </div>
    );
};
