import React, { FunctionComponent, ReactElement } from "react";
import { useCurrentWidth } from "utils/useCurrentWidth";
import Greeting from "../../dashboard/Greeting";
import FavProducts from "../../dashboard/Multipliers";
import News from "../../dashboard/News";
import ProductPromotion from "../../dashboard/Promotions";
import WeeklyHits from "../../dashboard/WeeklyHits";

// Shows the dashboard which is separated into the Desktop and the mobile/ tablet version.
// In the desktop versions the tiles are orderd in an nested table.
// Because the order of the columns can only be adjusted in the row a separate dashboard for the mobile and tablet was created.
// Which dashboard is shown is depending on the screen size.

const MyTebonus: FunctionComponent = (): ReactElement => {
    const screenWidth = useCurrentWidth();

    return (
        <div>
            {screenWidth >= 1024 ? (
                <div className="d-none d-lg-block grid-gutter-x">
                    <div className="row">
                        <div className="col-md-5 grid-gutter-y">
                            <div className="greetingTile firstRowTile">
                                <Greeting />
                            </div>
                        </div>
                        <div className="col-md-7 grid-gutter-y">
                            <div className="newsTile firstRowTile">
                                <News />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5 grid-gutter-y">
                            <div className="tile">
                                <FavProducts />
                            </div>
                        </div>
                        <div className="col-md-7 grid-gutter-y">
                            <div className="tile">
                                <ProductPromotion />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 grid-gutter-y">
                            <div className="tile">
                                <WeeklyHits />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container-lg pt-2 d-lg-none">
                    <div className="row">
                        <div className="col-12 grid-gutter-y">
                            <div className="greetingTile">
                                <Greeting />
                            </div>
                        </div>
                        <div className="col-12 grid-gutter-y">
                            <div className="newsTile">
                                <News />
                            </div>
                        </div>
                        <div className="col-12 grid-gutter-y">
                            <div className="tile-dashboard">
                                <FavProducts />
                            </div>
                        </div>
                        <div className="col-12 grid-gutter-y">
                            <div className="tile-dashboard">
                                <ProductPromotion />
                            </div>
                        </div>
                        <div className="col-12 grid-gutter-y">
                            <div className="tile-dashboard">
                                <WeeklyHits />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyTebonus;
