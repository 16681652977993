import { CardOverviewInfo, CardType } from "types";

export const CARD_NAV_TITLE = "tebonus Karte";

export const isSubMenuItemHidden = (title: string, hiddenInMenu: boolean, cards: CardOverviewInfo[]): boolean => {
    if (title === CARD_NAV_TITLE) {
        return !hasPhysicalCard(cards);
    }

    return hiddenInMenu;
};

export function hasPhysicalCard(cards: CardOverviewInfo[]): boolean {
    return cards.some(c => c.cardType === CardType.physical);
}
