import React, { FunctionComponent, ReactElement, useEffect } from "react";
import { useAppDispatch } from "utils/hooks";
import { useSelector } from "react-redux";
import { ErrorStatusCode } from "types";
import { actionCreators } from "store";
import { AppState } from "store/configureStore";
import PageBase from "components/Shared/PageBase";
import { FaqsState } from "store/Faqs";
import FaqsTable from "../../components/Faqs/FaqsTable";

const FAQS_REQUEST = {
    filter: "tebonus",
};

const FaqsDetails: FunctionComponent = (): ReactElement => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(actionCreators.actionCreatorsFaqs.getAllFaqs(FAQS_REQUEST));
    }, []);

    const FAQsState: FaqsState = useSelector((state: AppState) => state.faqsReducer);

    return (
        <PageBase
            title="FAQ - Fragen und Antworten"
            isContentLoading={FAQsState.isLoading}
            contentLoadingText="FAQs werden geladen."
            showLoadingIndicator={true}
        >
            {FAQsState.errorCode !== ErrorStatusCode.noError ? (
                <div className="m-5 text-center">
                    <strong>FAQs konnten nicht geladen werden.</strong>
                </div>
            ) : (
                <FaqsTable faqs={FAQsState.faqs} />
            )}
        </PageBase>
    );
};

export default FaqsDetails;
