import DashboardProductTile, { ProductStatus, TilePlacement } from "components/Shared/DashboardProductTile";
import React, { FunctionComponent, ReactElement } from "react";

export const DashboardEmptyTiles: FunctionComponent = (): ReactElement => {
    return (
        <>
            {Array.from(Array(3).keys()).map(item => (
                <div className="my-4 d-flex justify-content-center" key={item}>
                    <DashboardProductTile
                        tilePlacement={TilePlacement.dashboardPromotions}
                        productStatus={ProductStatus.isLoading}
                    />
                </div>
            ))}
        </>
    );
};
