import { AxiosRequestConfig, AxiosResponse } from "axios";
import { Reducer } from "redux";
import {
    Article,
    ArticleSearchSortingOrder,
    BaseType,
    ErrorStatusCode,
    KeyValueArrayPair,
    ReduxBaseType,
    AxiosRequestError,
} from "../types";
import RequestStatus from "../utils/RequestStatus";
import { getAppSetting } from "../utils/AppSettings";
import { AppThunkAction } from ".";

const REACT_APP_DATASERVICE_URL = getAppSetting("REACT_APP_DATASERVICE_URL");

export const SEARCH_ARTICLES = "pekuma/articles/SEARCH_ARTICLES";
export const SEARCH_ARTICLES_SUCCESS = "pekuma/articles/SEARCH_ARTICLES_SUCCESS";
export const SEARCH_ARTICLES_FAIL = "pekuma/articles/SEARCH_ARTICLES_FAIL";

export const LOAD_NEXT_PAGE = "pekuma/articles/LOAD_NEXT_PAGE";
export const LOAD_NEXT_PAGE_SUCCESS = "pekuma/articles/LOAD_NEXT_PAGE_SUCCESS";
export const LOAD_NEXT_PAGE_FAIL = "pekuma/articles/LOAD_NEXT_PAGE_FAIL";

export const RESET_SEARCH_ARTICLES = "pekuma/articles/RESET_SEARCH_ARTICLES";

interface SearchArticlesAction {
    type: typeof SEARCH_ARTICLES;
    searchTerm: string;
    searchTags: KeyValueArrayPair[];
    payload: {
        request: AxiosRequestConfig;
    };
}

interface SearchArticlesSuccessAction {
    type: typeof SEARCH_ARTICLES_SUCCESS;
    payload: AxiosResponse<Article[]>;
}

interface SearchArticlesFailAction {
    type: typeof SEARCH_ARTICLES_FAIL;
    error: AxiosRequestError;
}

interface LoadNextPageAction {
    type: typeof LOAD_NEXT_PAGE;
    pageIndex: number;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface LoadNextPageSuccessAction {
    type: typeof LOAD_NEXT_PAGE_SUCCESS;
    payload: AxiosResponse<Article[]>;
}

interface LoadNextPageFailAction {
    type: typeof LOAD_NEXT_PAGE_FAIL;
    error: AxiosRequestError;
}

interface ResetSearchResultAction {
    type: typeof RESET_SEARCH_ARTICLES;
}

export interface ArticleSearchState extends BaseType, ReduxBaseType {
    searchTerm: string;
    searchTags: KeyValueArrayPair[];
    currentPage: number;
    searchResults: Article[];
}

export type KnownAction =
    | SearchArticlesAction
    | SearchArticlesSuccessAction
    | SearchArticlesFailAction
    | LoadNextPageAction
    | LoadNextPageSuccessAction
    | LoadNextPageFailAction
    | ResetSearchResultAction;

export type SuccessAction = SearchArticlesSuccessAction | LoadNextPageSuccessAction;

export type ErrorAction = SearchArticlesFailAction | LoadNextPageFailAction;

const initialResults = [];

const INITIAL_STATE: ArticleSearchState = {
    searchTerm: "",
    searchTags: [],
    currentPage: 0,
    searchResults: initialResults,
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
    dontPersist: true,
};

// Reducer
export const reducer: Reducer<ArticleSearchState> = (state = INITIAL_STATE, action: KnownAction): ArticleSearchState => {
    switch (action.type) {
        case SEARCH_ARTICLES: {
            const { searchTags, searchTerm } = action;

            return {
                ...state,
                isLoading: true,
                searchTerm,
                searchTags,
                currentPage: 0,
                searchResults: initialResults,
                errorCode: ErrorStatusCode.noError,
                dontPersist: true,
            };
        }
        case SEARCH_ARTICLES_FAIL: {
            if (RequestStatus.isCancelled(action.error)) {
                return state;
            }
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                dontPersist: true,
            };
        }
        case SEARCH_ARTICLES_SUCCESS: {
            const searchResults = action.payload.data;
            return {
                ...state,
                isLoading: false,
                searchResults,
                errorCode: ErrorStatusCode.noError,
                dontPersist: true,
            };
        }
        case LOAD_NEXT_PAGE: {
            return {
                ...state,
                isLoading: true,
                currentPage: action.pageIndex,
                errorCode: ErrorStatusCode.noError,
                dontPersist: true,
            };
        }
        case LOAD_NEXT_PAGE_FAIL: {
            if (RequestStatus.isCancelled(action.error)) {
                return state;
            }

            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                dontPersist: true,
            };
        }
        case LOAD_NEXT_PAGE_SUCCESS: {
            const searchResults = action.payload.data;
            return {
                ...state,
                isLoading: false,
                searchResults: [...state.searchResults, ...searchResults],
                errorCode: ErrorStatusCode.noError,
                dontPersist: true,
            };
        }
        case RESET_SEARCH_ARTICLES: {
            return {
                ...state,
                searchResults: initialResults,
            };
        }
        default:
            return state;
    }
};

// Action Creators
export const actionCreators = {
    searchArticle:
        (searchTerm: string, searchTags: KeyValueArrayPair[]): AppThunkAction<KnownAction, Promise<void>> =>
        dispatch => {
            // dispatch(cancelActionRequest(LOAD_NEXT_PAGE));
            // dispatch(cancelActionRequest(SEARCH_ARTICLES));

            return dispatch(<SearchArticlesAction>{
                type: SEARCH_ARTICLES,
                searchTerm,
                searchTags,
                payload: {
                    request: {
                        method: "POST",
                        url: `${REACT_APP_DATASERVICE_URL}api/FindArticles`,
                        data: {
                            terms: searchTerm ? [searchTerm] : [],
                            tags: searchTags,
                            pageIndex: 0,
                            pageSize: 25,
                            orderByName: ArticleSearchSortingOrder.ASC,
                            isTebonusable: true,
                            isDiscountable: true,
                            isActive: true,
                        },
                    },
                },
            });
        },
    loadNextPage: (): AppThunkAction<KnownAction, Promise<void>> => (dispatch, getState) => {
        const { articleSearchReducer } = getState();
        const { searchTerm, searchTags } = articleSearchReducer;
        const pageIndex = articleSearchReducer.currentPage + 1;

        return dispatch(<LoadNextPageAction>{
            type: LOAD_NEXT_PAGE,
            pageIndex,
            payload: {
                request: {
                    method: "POST",
                    url: `${REACT_APP_DATASERVICE_URL}api/FindArticles`,
                    data: {
                        terms: searchTerm ? [searchTerm] : [],
                        tags: searchTags,
                        pageIndex,
                        pageSize: 25,
                        orderByName: ArticleSearchSortingOrder.ASC,
                        isTebonusable: true,
                        isDiscountable: true,
                        isActive: true,
                    },
                },
            },
        });
    },
    resetSearchResult: (): AppThunkAction<KnownAction, Promise<void>> => dispatch => {
        return dispatch(<ResetSearchResultAction>{
            type: RESET_SEARCH_ARTICLES,
        });
    },
};

export type ArticleSearchActions = typeof actionCreators;
