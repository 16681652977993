import { configureStore } from "@reduxjs/toolkit";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { combineReducers } from "redux";
import axiosMiddleware from "redux-axios-middleware";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";
import { actionCreators } from "../store";
import { getAppSetting } from "../utils/AppSettings";
import { reducers } from "./index";

const REACT_APP_API_URI = getAppSetting("REACT_APP_API_URI");

const config: AxiosRequestConfig = {
    baseURL: `${REACT_APP_API_URI}`,
    responseType: "json",
    timeout: 50000,
};

const axiosInstance: AxiosInstance = axios.create(config);

const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

const initState = {};

const appReducer = combineReducers({ ...reducers });

const rootReducer = (store, action) => {
    if (action.type === LOGOUT_SUCCESS) {
        localStorage.clear();
        store = undefined;
    }

    return appReducer(store, action);
};

const persistConfig = {
    key: "root",
    storage,
};

const middlewareConfig = {
    interceptors: {
        request: [
            {
                success: function ({ getState, dispatch, getSourceAction }, req: AxiosRequestConfig) {
                    const { tokenResponse } = getState().authReducer;
                    if (tokenResponse) {
                        req.headers.accept = "application/json";
                        req.headers.Authorization = tokenResponse.accessToken ? `Bearer ${tokenResponse.accessToken}` : null;
                    }

                    return req;
                },
                error: function ({ getState, dispatch, getSourceAction }, error) {
                    return error;
                },
            },
        ],
        response: [
            {
                success: function ({ getState, dispatch, getSourceAction }, res: AxiosResponse) {
                    return Promise.resolve(res);
                },

                error: async function ({ getState, dispatch, getSourceAction }, error) {
                    const originalRequest = error.config;
                    const state = getState();

                    // if an 401 authentication error will be retured from the request a token refresh will be done and afterwards the prevoious request executed.
                    if (error.response.status === 401 && !originalRequest._retry) {
                        originalRequest._retry = true;

                        // Only make a token refresh when the user is authenticated and he is not refreshing
                        if (state.authReducer.isAuthenticated && !state.authReducer.isRefreshing) {
                            await dispatch(actionCreators.actionCreatorsAuth.refreshToken());
                        }

                        var backoff = new Promise(resolve => {
                            setTimeout(() => {
                                resolve(undefined);
                            }, 2000);
                        });

                        // make the previous/ original request which have been failed
                        return backoff.then(function () {
                            return axiosInstance(originalRequest);
                        });
                    }

                    // set the variable for keeping track of the retry count
                    originalRequest._retryCount = originalRequest._retryCount || 0;

                    // https://github.com/axios/axios/issues/164 (bottom page)
                    // Retry every failed request for 3 times except unauthorized requests
                    if (originalRequest._retryCount < 3 && error.response.status !== 401) {
                        // Increase the retry count
                        originalRequest._retryCount += 1;
                        return axiosInstance(originalRequest);
                    }

                    return Promise.reject(error);
                },
            },
        ],
    },
};

const middleware = [thunkMiddleware, axiosMiddleware(axiosInstance, middlewareConfig)];

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer,
    middleware: middleware,
    devTools: process.env.NODE_ENV == "development", // dev tools are only activated on developement
    preloadedState: initState,
});

const persistor = persistStore(store);
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
