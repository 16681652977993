// https://github.com/cytrowski/teedux
import { AxiosRequestConfig, AxiosResponse } from "axios";
import dayjs from "dayjs";
import { Reducer } from "redux";
import {
    AllReduxActions,
    AxiosRequestError,
    BaseType,
    CustomerScoreTransaction,
    ErrorStatusCode,
    ReduxBaseType,
} from "../types";
import RequestStatus from "../utils/RequestStatus";

// Actions
export const GET_CUSTOMER_SCORE = "pekuma/customers/GET_SCORE";
export const GET_CUSTOMER_SCORE_SUCCESS = "pekuma/customers/GET_SCORE_SUCCESS";
export const GET_CUSTOMER_SCORE_FAIL = "pekuma/customers/GET_SCORE_FAIL";

export const GET_CUSTOMER_SCORETRANSACTIONS = "pekuma/customers/GET_SCORETRANSACTIONS";
export const GET_CUSTOMER_SCORETRANSACTIONS_SUCCESS = "pekuma/customers/GET_SCORETRANSACTIONS_SUCCESS";
export const GET_CUSTOMER_SCORETRANSACTIONS_FAIL = "pekuma/customers/GET_SCORETRANSACTIONS_FAIL";

export const CLEAR_CUSTOMER_SCORE_LOADING_STATE = "pekuma/customers/CLEAR_CUSTOMER_SCORE_LOADING_STATE";
export const CLEAR_CUSTOMER_SCORE_TRANSACTIONS = "pekuma/customers/CLEAR_CUSTOMER_SCORE_TRANSACTIONS";

export const CLEAR_ALL_CUSTOMER_SCORE = "pekuma/customers/CLEAR_ALL";

interface GetCustomerScore {
    totalPoints: number;
    lastUpdate: number;
}

export interface GetCustomerScoreAction {
    type: typeof GET_CUSTOMER_SCORE;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetCustomerScoreSuccessAction {
    type: typeof GET_CUSTOMER_SCORE_SUCCESS;
    payload: AxiosResponse<GetCustomerScore>;
}

interface GetCustomerScoreFailAction {
    type: typeof GET_CUSTOMER_SCORE_FAIL;
    error: AxiosRequestError;
}

interface GetCustomerScoreTransacions {
    transactions: CustomerScoreTransaction[];
}

interface ClearLoadingStateAction {
    type: typeof CLEAR_CUSTOMER_SCORE_LOADING_STATE;
}

export interface GetCustomerScoreTransactionsAction {
    type: typeof GET_CUSTOMER_SCORETRANSACTIONS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetCustomerScoreTransactionsSuccessAction {
    type: typeof GET_CUSTOMER_SCORETRANSACTIONS_SUCCESS;
    payload: AxiosResponse<GetCustomerScoreTransacions>;
}

interface GetCustomerScoreTransactionsFailAction {
    type: typeof GET_CUSTOMER_SCORETRANSACTIONS_FAIL;
    error: AxiosRequestError;
}

interface ClearLoadingStateAction {
    type: typeof CLEAR_CUSTOMER_SCORE_LOADING_STATE;
}
interface ClearTransactionsStateAction {
    type: typeof CLEAR_CUSTOMER_SCORE_TRANSACTIONS;
}
interface ClearAllAction {
    type: typeof CLEAR_ALL_CUSTOMER_SCORE;
}

export type KnownAction =
    | GetCustomerScoreAction
    | GetCustomerScoreSuccessAction
    | GetCustomerScoreFailAction
    | GetCustomerScoreTransactionsAction
    | GetCustomerScoreTransactionsSuccessAction
    | GetCustomerScoreTransactionsFailAction
    | ClearLoadingStateAction
    | ClearTransactionsStateAction
    | ClearAllAction;

export interface CustomerScoreState extends BaseType, ReduxBaseType {
    currentScore: number;
    lastUpdate: number;
    scoreTransactions: CustomerScoreTransaction[];
    isTransactionsLoading: boolean;
}

export const INITIAL_STATE: CustomerScoreState = {
    currentScore: undefined,
    lastUpdate: 0,
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
    performedAction: null,
    scoreTransactions: [],
    isTransactionsLoading: false,
};

// Reducer
export const reducer: Reducer<CustomerScoreState> = (state = INITIAL_STATE, action: KnownAction): CustomerScoreState => {
    switch (action.type) {
        case GET_CUSTOMER_SCORE: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_CUSTOMER_SCORE,
            };
        }
        case GET_CUSTOMER_SCORE_SUCCESS: {
            const { totalPoints } = action.payload.data;
            return {
                ...state,
                isLoading: false,
                currentScore: totalPoints,
                lastUpdate: dayjs().unix(),
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_CUSTOMER_SCORE_SUCCESS,
            };
        }
        case GET_CUSTOMER_SCORE_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_CUSTOMER_SCORE_FAIL,
            };
        }

        case GET_CUSTOMER_SCORETRANSACTIONS: {
            return {
                ...state,
                isTransactionsLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_CUSTOMER_SCORETRANSACTIONS,
            };
        }
        case GET_CUSTOMER_SCORETRANSACTIONS_SUCCESS: {
            return {
                ...state,
                errorCode: ErrorStatusCode.noError,
                isTransactionsLoading: false,
                performedAction: AllReduxActions.GET_CUSTOMER_SCORETRANSACTIONS_SUCCESS,
                scoreTransactions: action.payload.data?.transactions,
            };
        }
        case GET_CUSTOMER_SCORETRANSACTIONS_FAIL: {
            return {
                ...state,
                isTransactionsLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_CUSTOMER_SCORETRANSACTIONS_FAIL,
            };
        }

        case CLEAR_CUSTOMER_SCORE_TRANSACTIONS: {
            return {
                ...state,
                scoreTransactions: [],
                performedAction: AllReduxActions.CLEAR_CUSTOMER_SCORE_TRANSACTIONS,
            };
        }
        case CLEAR_ALL_CUSTOMER_SCORE: {
            return {
                ...state,
            };
        }
        default:
            return state;
    }
};

// Action Creators
export const actionCreators = {
    getCustomerScore: (): GetCustomerScoreAction =>
        <GetCustomerScoreAction>{
            type: GET_CUSTOMER_SCORE,
            payload: {
                request: {
                    method: "GET",
                    url: "/GetCustomerScore",
                },
            },
        },

    getCustomerScoreTransactions: (): GetCustomerScoreTransactionsAction =>
        <GetCustomerScoreTransactionsAction>{
            type: GET_CUSTOMER_SCORETRANSACTIONS,
            payload: {
                request: {
                    method: "GET",
                    url: `/GetCustomerScoreTransactions`,
                },
            },
        },

    clearLoadingState: (): ClearLoadingStateAction =>
        <ClearLoadingStateAction>{
            type: CLEAR_CUSTOMER_SCORE_LOADING_STATE,
        },

    clearAllTransactions: (): ClearTransactionsStateAction =>
        <ClearTransactionsStateAction>{
            type: CLEAR_CUSTOMER_SCORE_TRANSACTIONS,
        },

    clearAll: (): ClearAllAction =>
        <ClearAllAction>{
            type: CLEAR_ALL_CUSTOMER_SCORE,
        },
};
export type CustomerScoreActions = typeof actionCreators;
