import DashboardProductTile, { ProductStatus, TilePlacement } from "components/Shared/DashboardProductTile";
import React, { FunctionComponent, ReactElement } from "react";
import { ArticleMultiplierState } from "store/ArticleMultipliers";
import { Article, PromotionAdvantage } from "types";
import { getImagePath } from "utils/ImagePath";

interface ProductTileProps {
    articleMultipliers: ArticleMultiplierState;
}

const DashboardProductTiles: FunctionComponent<ProductTileProps> = (props): ReactElement => {
    const emptyItems = [0, 1, 2];

    function getArticleStatus(article: Article) {
        if (article != null) {
            return ProductStatus.isActivated;
        }

        return ProductStatus.isIdle;
    }

    return (
        <div className="d-flex flex-wrap justify-content-center h-100 my-auto">
            {props.articleMultipliers.isLoading
                ? emptyItems.map((item, index) => (
                      <DashboardProductTile
                          key={index}
                          tilePlacement={TilePlacement.dashboardMultiplier}
                          productStatus={ProductStatus.isLoading}
                      />
                  ))
                : props.articleMultipliers.articles.map((article, index) => (
                      <DashboardProductTile
                          key={index}
                          tilePlacement={TilePlacement.dashboardMultiplier}
                          image={getImagePath(article)}
                          advantageAmount={props.articleMultipliers.values[index]}
                          advantage={PromotionAdvantage.Multiplier}
                          productStatus={getArticleStatus(article)}
                          isArticleLocked={props.articleMultipliers.areMultiplierLocked}
                      />
                  ))}
        </div>
    );
};

export default DashboardProductTiles;
