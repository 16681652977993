import Error from "@material-ui/icons/Error";
import React, { FunctionComponent, ReactElement } from "react";

export const ErrorTable: FunctionComponent = (): ReactElement => {
    return (
        <div className="col-7 col-xl-5 mt-5 px-3">
            <div className="row align-items-center font-subheading-regular font-dimmGray text-focus-in">
                <Error className="mr-3" />
                <div className="">Etwas ist schiefgelaufen</div>
            </div>
            <div className="row align-items-center">
                <p className="font-table-heading font-silver text-focus-in">
                    Ihre Aktion konnte leider nicht bearbeitet werden. Bitte sorgen Sie für eine stabile Internetverbindung und
                    versuchen Sie es erneut.
                </p>
            </div>
        </div>
    );
};
