import React, { FunctionComponent } from "react";
import MenuIcon from "@material-ui/icons/Menu";

import LogoDesktop from "../../assets/images/tegut_Logo_Claim.svg";

/**
 * Header navigation bar.
 * The navigation varies a lot between the mobile and desktop view.
 * Therefore two different navigation menues were created
 */

interface NavBarProps {
    login: () => void;
    logout: () => void;
    isAuthenticated: boolean;
}

const NavBar: FunctionComponent<NavBarProps> = props => {
    return (
        // {/*Navigation bar for screens which are larger than sm */}
        // { /* d-none d-sm-block: show this navigation bar if the screen is larger than sm */}
        <nav className="sticky-top navbar navbar-expand-lg navigationBar d-none d-lg-block">
            {/* to make the navbar over the whole page use container-fluid */}
            <div className="container-xl px-3">
                <a
                    href="https://www.tegut.com/"
                    className="navbar-brand"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="zur tegut… Startseite"
                >
                    <img
                        src={LogoDesktop}
                        width="125"
                        height="auto"
                        loading="lazy"
                        className="img-fluid"
                        alt="zur tegut… Startseite"
                    />
                </a>

                <button
                    className="navbar-toggler p-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-label="Menü"
                >
                    <MenuIcon fontSize="large" />
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className=" navbar-nav ml-auto">
                        {/* Login/ Logout button */}
                        <li className="nav-item">
                            {!props.isAuthenticated ? (
                                <button className="btn btn-outline-orange btn-navigationBar" onClick={() => props.login()}>
                                    Login
                                </button>
                            ) : (
                                <button className="btn btn-outline-orange btn-navigationBar" onClick={() => props.logout()}>
                                    Logout
                                </button>
                            )}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
