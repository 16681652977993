import React, { FunctionComponent } from "react";

export enum TebonusArrowType {
    ROUND,
    SHARP,
}

export enum TebonusArrowSize {
    XX_SMALL,
    EXTRA_SMALL,
    SMALL,
    MEDIUM,
    LARGE,
}

interface ArrowSizeInfo {
    width: number;
    height: number;
}

const sizes: Record<TebonusArrowSize, ArrowSizeInfo> = {
    [TebonusArrowSize.XX_SMALL]: { width: 6.3233, height: 13.0197 },
    [TebonusArrowSize.EXTRA_SMALL]: { width: 9.3233, height: 24.0197 },
    [TebonusArrowSize.SMALL]: { width: 13.3833, height: 28.0167 },
    [TebonusArrowSize.MEDIUM]: { width: 21.57, height: 51.227 },
    [TebonusArrowSize.LARGE]: { width: 27.438, height: 65.624 },
};

interface ArrowProps {
    size?: TebonusArrowSize;
    color?: string;
}

export const TebonusArrowLeftRound: FunctionComponent<ArrowProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={sizes[props.size]?.width ?? sizes[TebonusArrowSize.SMALL].width}
            height={sizes[props.size]?.height ?? sizes[TebonusArrowSize.SMALL].height}
            viewBox="0 0 27.438 65.624"
        >
            <g id="Pfeil_links" transform="translate(5 6.885)">
                <path
                    id="Pfeil_links-2"
                    data-name="Pfeil_links"
                    d="M1084.5,7487.906l-15.553,25.052,15.553,26.854"
                    transform="translate(-1068.948 -7487.906)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="10"
                />
            </g>
        </svg>
    );
};

export const TebonusArrowRightRound: FunctionComponent<ArrowProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={sizes[props.size]?.width ?? sizes[TebonusArrowSize.SMALL].width}
            height={sizes[props.size]?.height ?? sizes[TebonusArrowSize.SMALL].height}
            viewBox="0 0 27.438 65.624"
        >
            <g id="Pfeil_links" transform="translate(6.885 6.885)">
                <path
                    id="Pfeil_links-2"
                    data-name="Pfeil_links"
                    d="M1068.948,7487.906l15.553,25.052-15.553,26.854"
                    transform="translate(-1068.948 -7487.906)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="10"
                />
            </g>
        </svg>
    );
};

export const TebonusArrowLeftSharp: FunctionComponent<ArrowProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={sizes[props.size]?.width ?? sizes[TebonusArrowSize.EXTRA_SMALL].width}
            height={sizes[props.size]?.height ?? sizes[TebonusArrowSize.EXTRA_SMALL].height}
            viewBox="0 0 11.374 19.551"
        >
            <path
                id="angle-left_zurück"
                d="M21.833,137.1l8.995-8.857a.917.917,0,0,1,1.3,0l.542.542a.917.917,0,0,1,0,1.3l-7.811,7.666,7.8,7.666a.917.917,0,0,1,0,1.3l-.542.542a.917.917,0,0,1-1.3,0L21.825,138.4A.924.924,0,0,1,21.833,137.1Z"
                transform="translate(-21.562 -127.975)"
            />
        </svg>
    );
};
export const TebonusArrowRightSharp: FunctionComponent<ArrowProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={sizes[props.size]?.width ?? sizes[TebonusArrowSize.EXTRA_SMALL].width}
            height={sizes[props.size]?.height ?? sizes[TebonusArrowSize.EXTRA_SMALL].height}
            viewBox="0 0 11.374 19.551"
        >
            <path
                id="angle-left_zurück"
                d="M32.666,137.1l-8.995-8.857a.917.917,0,0,0-1.3,0l-.542.542a.917.917,0,0,0,0,1.3l7.811,7.666-7.8,7.666a.917.917,0,0,0,0,1.3l.542.542a.917.917,0,0,0,1.3,0l8.995-8.857A.924.924,0,0,0,32.666,137.1Z"
                transform="translate(-21.562 -127.975)"
            />
        </svg>
    );
};
