import FilterTile from "components/FilterTile";
import React, { FunctionComponent, ReactElement } from "react";
import { SearchProps } from "types";

interface SelectedFilterProps {
    selectedBrands: SearchProps[];
    selectedCategories: SearchProps[];
    removeBrandFilter: (brand: SearchProps) => void;
    removeCategoryFilter: (category: SearchProps) => void;
}

const SelectedFilters: FunctionComponent<SelectedFilterProps> = (props): ReactElement => {
    return (
        <div className="d-flex flex-wrap justify-content-center my-1">
            {props.selectedBrands.map((brand, index) => (
                <FilterTile key={index} labelName={brand.name} removeFunction={() => props.removeBrandFilter(brand)} />
            ))}
            {props.selectedCategories.map((category, index) => (
                <FilterTile key={index} labelName={category.name} removeFunction={() => props.removeCategoryFilter(category)} />
            ))}
        </div>
    );
};

export default SelectedFilters;
