import LandingPage from "pages/LandingPage";
import React, { FunctionComponent, ReactElement } from "react";
import { Route } from "react-router-dom";

interface ProtectedRouteProps {
    component: FunctionComponent | React.ComponentType;
    isAuthenticated: boolean;
    path: string;
    exact?: boolean;
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
    isAuthenticated,
    component: Component,
    path,
    exact,
}): ReactElement => {
    return <Route path={path} exact={exact} render={() => (isAuthenticated === true ? <Component /> : <LandingPage />)} />;
};

export default ProtectedRoute;
