import React, { FunctionComponent, ReactElement } from "react";
import { Link } from "react-router-dom";
import { calculateTimeUntilNewChoice } from "utils/DateTimeUtils";
import * as navLinks from "../../../navigation";

interface DashboardPromotionsFooterProps {
    arePromotionsLocked: boolean;
    nextChangeDate: string;
}

export const DashboardPromotionsFooter: FunctionComponent<DashboardPromotionsFooterProps> = (props): ReactElement => {
    const TEGUT_PROMOTIONS_NAME = "Aktionsprodukte";

    const promoLinkItem = navLinks.linksSubMenu_MyTebonus.find(p => p.title === TEGUT_PROMOTIONS_NAME);

    function getFooterText() {
        if (props.arePromotionsLocked) {
            const daysUntilNextMonth = calculateTimeUntilNewChoice(props.nextChangeDate);

            return `Neue Auswahl
                ${daysUntilNextMonth > 1 ? ` in ${daysUntilNextMonth} Tagen` : "morgen"}
                möglich`;
        }

        return "0 von 7 ausgewählt";
    }

    function getSelectButtonText() {
        return props.arePromotionsLocked ? "Produkte ansehen" : "Produkte auswählen";
    }

    return (
        <div className="d-flex flex-column mt-auto align-items-center">
            <div className="d-flex justify-content-center dashboard-tile-footer pb-2">{getFooterText()}</div>
            <Link to={promoLinkItem.htmlLink} tabIndex={-1}>
                <button
                    tabIndex={0}
                    className={props.arePromotionsLocked ? "btn btn-outline-orange btn-select" : "btn btn-orange btn-select"}
                >
                    {getSelectButtonText()}
                </button>
            </Link>
        </div>
    );
};
