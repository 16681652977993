import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import React, { FunctionComponent, ReactElement } from "react";

interface ConnectionErrorModalProps {
    retryButtonFunction: () => void;
    cancelButtonFunction: () => void;
}

const ConnectionErrorModal: FunctionComponent<ConnectionErrorModalProps> = (props): ReactElement => {
    return (
        <ModalConfirmInfo
            key="optinerror"
            titleText="Etwas ist schiefgelaufen"
            bodyText="Ihre Aktion konnte leider nicht bearbeitet werden. Bitte sorgen Sie für eine stabile Internetverbindung."
            button1Text="Erneut versuchen"
            button1function={props.retryButtonFunction}
            button2Text="Abbrechen"
            button2function={props.cancelButtonFunction}
        />
    );
};

export default ConnectionErrorModal;
