import React, { FunctionComponent, ReactElement } from "react";
import { useSelector } from "react-redux";
import { PromotionState } from "store/Promotions";
import { DashboardWeeklyHitsHeader } from "components/WeeklyHits/Dashboard/DashboardWeeklyHitsHeader";
import { WeeklyHitsCarousel } from "components/WeeklyHits/Dashboard/WeeklyHitsCarousel";
import * as NavLinks from "../../navigation";
import { AppState } from "../../store/configureStore";

const WeeklyHits: FunctionComponent = (): ReactElement => {
    const weeklyHits: PromotionState = useSelector((state: AppState) => state.promotionReducer);
    const tegutWeeklyHitsName = "Wochen-Hits";
    const WEEKLY_HITS_LINK = NavLinks.linksSubMenu_MyTebonus.find(p => p.title === tegutWeeklyHitsName).htmlLink;

    return (
        <div className="container-lg">
            <div className="row align-items-center">
                <DashboardWeeklyHitsHeader weekPromotions={weeklyHits.weekPromotions} weeklyHitsLink={WEEKLY_HITS_LINK} />
                <WeeklyHitsCarousel
                    isLoading={weeklyHits.isLoading}
                    weekPromotions={weeklyHits.weekPromotions}
                    weeklyHitsLink={WEEKLY_HITS_LINK}
                />
            </div>
        </div>
    );
};

export default WeeklyHits;
