import LoadingIndicator from "components/LoadingIndicator";
import ProductTile, { ProductStatus } from "components/Shared/ProductTile";
import React, { FunctionComponent, ReactElement, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { actionCreators } from "store";
import { Article, LocationState, PromotionAdvantage } from "types";
import { logAnalyticsSelectProduct } from "utils/FirebaseAnalytics";
import { getImagePath } from "utils/ImagePath";
import { useAppDispatch } from "utils/hooks";

interface SearchResultItemsProps {
    isLoading: boolean;
    searchResults: Article[];
    currentMultiplierIndex: number;
    searchResultCounter: number;
}

const SearchResultItems: FunctionComponent<SearchResultItemsProps> = (props): ReactElement => {
    const PAGE_SIZE = 25;
    const location = useLocation<LocationState>();
    const history = useHistory();
    const [reloadCounter, setReloadCounter] = useState<number>(1);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (props.searchResultCounter === 0) {
            setReloadCounter(1);
        }
    }, [props.searchResultCounter]);

    function areMoreArticlesAvailable(): boolean {
        return props.searchResultCounter >= PAGE_SIZE * reloadCounter;
    }

    function loadMoreArticles(): void {
        if (areMoreArticlesAvailable()) {
            setReloadCounter(prevCounter => prevCounter + 1);
            dispatch(actionCreators.actionCreatorArticleSearch.loadNextPage());
        }
    }

    const scrollRef = useRef<HTMLDivElement>();
    const onScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            if (scrollTop + clientHeight >= scrollHeight) {
                loadMoreArticles();
            }
        }
    };

    /**
     * Save the selected article in the session storage and go back to the favourite products page
     * @param article
     */
    function onArticleSelected(article: Article): void {
        logAnalyticsSelectProduct({
            [`produkt_liebling_${location.state.index + 1}`]: article.name,
            produkt_punkte: `×${location.state.advantageAmount}`,
        });

        sessionStorage.setItem(`${location.state?.index}`, JSON.stringify(article));
        history.push("/meintebonus/Lieblingsprodukte");
    }

    return (
        <div className="scrollScoreContainer-md overflow-y-md table-scroll-bar" ref={scrollRef} onScroll={onScroll}>
            <div className="d-flex justify-content-center flex-grow flex-wrap">
                {props.searchResults.map((searchResult, index) => (
                    <ProductTile
                        id={searchResult.tan}
                        onSelectButton={() => onArticleSelected(searchResult)}
                        key={index}
                        productStatus={ProductStatus.isIdle}
                        image={getImagePath(searchResult)}
                        promotionTitle={searchResult.name}
                        advantageAmount={props.currentMultiplierIndex}
                        advantage={PromotionAdvantage.Multiplier}
                        showSelectionBadge={true}
                    />
                ))}
                {props.isLoading ? (
                    <LoadingIndicator />
                ) : (
                    <div
                        className={`w-100 py-3 justify-content-center ${
                            areMoreArticlesAvailable() ? "d-flex" : "d-none bg-dark"
                        } `}
                    >
                        <button className="font-orange btn-orange-text" onClick={() => loadMoreArticles()}>
                            Mehr Produkte laden
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchResultItems;
