import { AxiosRequestError } from "../types";

export default class RequestStatus {
    public static getErrorStatusCode = (action: AxiosRequestError): number => {
        if (action.status !== undefined && action.status !== null) {
            return action.status;
        }

        return 0;
    };

    public static isCancelled = (action: AxiosRequestError): boolean => {
        const error = action.message || action.data;
        return error && Object(error).cancelled;
    };
}
