import React, { FunctionComponent, ReactElement } from "react";
import { PromotionState } from "store/Promotions";
import { Colors } from "styles";
import { calculateTimeUntilNewChoice } from "utils/DateTimeUtils";
import { getMultplierPromotionCollisionsMessage } from "utils/MultiplierPromotionUtils";

interface PromotionsFooterProps {
    arePromotionsLocked: boolean;
    selectedPromotionsLength: number;
    promotions: PromotionState;
    onSaveButtonClicked: () => void;
}

export const PromotionsFooter: FunctionComponent<PromotionsFooterProps> = (props): ReactElement => {
    function getCollisionsWarningMsg(): string {
        const { monthPromotions } = props.promotions;
        const collidedPromotions = monthPromotions.filter(id =>
            props.promotions.collidedPromotionIdentifiers.includes(id.tegutPromotionIdentifier)
        );

        const collidedPromotionTitles = collidedPromotions.map(p => p.promotionTitle);

        const firstWarningMsgSentence =
            collidedPromotionTitles.length === 1
                ? `Sie haben bereits "${collidedPromotionTitles}" als Lieblingsprodukt ausgewählt.`
                : "Sie haben mehrere Produkte bereits als Lieblingsprodukt ausgewählt. ";

        return getMultplierPromotionCollisionsMessage(firstWarningMsgSentence);
    }

    function getSelectButtonText(): string {
        if (props.arePromotionsLocked) {
            return "Produkte gespeichert";
        }
        return "Auswahl speichern";
    }

    function getFooterText(): string {
        if (props.arePromotionsLocked) {
            const daysUntilNextMonth = calculateTimeUntilNewChoice(props.promotions.nextChangeDate);

            return `Neue Auswahl ${daysUntilNextMonth > 1 ? `in ${daysUntilNextMonth} Tagen` : "morgen"} möglich`;
        }

        return ` ${props.selectedPromotionsLength} von 7 Produkten ausgewählt`;
    }

    function getFooterTextStyle(): string {
        return props.arePromotionsLocked ? Colors.orange : Colors.black;
    }

    return (
        <>
            <div className="d-flex justify-content-center align-items-center mt-4">
                <div
                    className="text-justify text-center font-unitText-Bold font-footer"
                    style={{ color: getFooterTextStyle() }}
                >
                    {getFooterText()}
                </div>
            </div>
            {props.promotions.areMultiplierCollisionsFound && (
                <p className="font-unitText-Regular text-warning text-center">{getCollisionsWarningMsg()}</p>
            )}
            <div className="d-flex justify-content-center mt-3">
                <a href="#tile_content">
                    <button
                        className="btn btn-orange btn-select"
                        onClick={props.onSaveButtonClicked}
                        disabled={
                            props.arePromotionsLocked ||
                            !props.selectedPromotionsLength ||
                            props.promotions.monthPromotionsLoading
                        }
                    >
                        {getSelectButtonText()}
                    </button>
                </a>
            </div>
        </>
    );
};
