import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import React, { FunctionComponent, ReactElement } from "react";
import { CommonButtonTitles, logAnalyticsButton } from "utils/FirebaseAnalytics";

interface SaveSelectionModalProps {
    numberSelectedProducts: number;
    onSaveSelectionButton: Function;
    onReturnSelectionButton: Function;
}

export const SaveSelectionModal: FunctionComponent<SaveSelectionModalProps> = (props): ReactElement => {
    const MAX_ITEMS_TO_SELECT = 7;

    function getTitleText(): string {
        return props.numberSelectedProducts >= MAX_ITEMS_TO_SELECT
            ? "Möchten Sie Ihre Auswahl speichern?"
            : `Sie können noch ${MAX_ITEMS_TO_SELECT - props.numberSelectedProducts} Aktionsprodukte auswählen`;
    }

    return (
        <ModalConfirmInfo
            titleText={getTitleText()}
            bodyText="Behalten Sie die aktuelle Auswahl bei, können in diesem Monat keine weiteren Produkte ausgewählt werden."
            button1Text="Auswahl speichern"
            button1function={() => {
                logAnalyticsButton({ button_title: CommonButtonTitles.selection.saveSelection });
                props.onSaveSelectionButton();
            }}
            button2Text="Weitere Produkte auswählen"
            button2function={() => {
                logAnalyticsButton({ button_title: CommonButtonTitles.selection.changeSelection });
                props.onReturnSelectionButton();
            }}
        />
    );
};
