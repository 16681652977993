import React, { FunctionComponent, ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { Colors } from "styles";
import DropDownMenu from "components/Shared/DropDownMenu";
import { AppState } from "store/configureStore";
import { ArticleCategory, SearchProps } from "types";
import { TebonusFilterIcon } from "components/_graphics";
import { CommonButtonTitles, logAnalyticsButton } from "utils/FirebaseAnalytics";

interface ArticleSearchFilterProps {
    searchTerm: string;
    selectedBrands: SearchProps[];
    setSelectedBrands: (SearchProps) => void;
    selectedCategories: SearchProps[];
    setSelectedCategories: (SearchProps) => void;
    allCategories: SearchProps[];
    setAllCategories: (SearchProps) => void;
    allBrands: SearchProps[];
    isFilterModalOpen: boolean;
    setIsFilterModalOpen: (value: boolean) => void;
}

export const ArticleSearchFilter: FunctionComponent<ArticleSearchFilterProps> = props => {
    const articleCategoriesReducer = useSelector((state: AppState) => state.articleCategoriesReducer);
    useEffect(() => {
        getCategoryNamesFirstLevel(articleCategoriesReducer.categories);
    }, []);

    /**
     * Show the icon and the drop down menus for the brand and categorie filters
     * @returns
     */
    const showDropDownFilters = (): ReactElement => {
        return (
            <div className="d-flex w-100 align-items-center justify-content-center mt-3 mb-2">
                <div className="mx-2 d-flex align-items-center justify-content-center">
                    <TebonusFilterIcon htmlColor={Colors.orange} />
                </div>
                <div className="mx-2 d-flex">
                    {/* Dropdown menu for brands */}
                    <DropDownMenu
                        title="Marke"
                        options={props.allBrands}
                        selectedItems={props.selectedBrands}
                        setSelectedItems={props.setSelectedBrands}
                        onOpen={() => logAnalyticsButton({ button_title: CommonButtonTitles.multiplier.openBrandsFilter })}
                        onClose={() => logAnalyticsButton({ button_title: CommonButtonTitles.multiplier.resetBrandsFilter })}
                        width={175}
                        height={40}
                    />
                </div>

                {/* Dropdown menu for categories */}
                <div className="mx-2 d-flex">
                    <DropDownMenu
                        title="Warengruppe"
                        options={props.allCategories}
                        selectedItems={props.selectedCategories}
                        setSelectedItems={props.setSelectedCategories}
                        onOpen={() => logAnalyticsButton({ button_title: CommonButtonTitles.multiplier.openCategoriesFilter })}
                        onClose={() =>
                            logAnalyticsButton({ button_title: CommonButtonTitles.multiplier.resetCategoriesFilter })
                        }
                        width={175}
                        height={40}
                    />
                </div>
            </div>
        );
    };

    /**
     * Get all categories from the first level
     * @param categories
     */
    const getCategoryNamesFirstLevel = (categories: ArticleCategory[]) => {
        const allCategories: SearchProps[] = [];
        categories.map(category => allCategories.push({ id: category.tegutCategoryId, name: category.categoryName }));
        props.setAllCategories(allCategories);
    };

    return (
        <>
            {/* Desktop version */}
            <div className="d-none d-md-block">{showDropDownFilters()}</div>

            {/* Mobile version */}
            <div className="d-md-none d-flex w-100 align-items-center justify-content-center">
                <button
                    className="btn font-orange font-unitText-Bold d-flex align-items-center justify-content-center p-1"
                    onClick={() => props.setIsFilterModalOpen(!props.isFilterModalOpen)}
                >
                    <div className="mr-2">
                        <TebonusFilterIcon htmlColor={Colors.orange} />
                    </div>
                    <div>Suchergebnisse filtern</div>
                </button>
            </div>
        </>
    );
};

export default ArticleSearchFilter;
